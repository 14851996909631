import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../src/Pages/SideMenu";
import Header from "../../src/Pages/Header";
import Footer from "../../src/Pages/Footer";
import Logout from "../../src/Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../src/Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { DownloadRMAcceptorHistory, Rmhistory, workPermitsHistory } from "../Utilities/URLCONSTANTS";
import moment from "moment";
import ModalImage from "react-modal-image";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import { CustomDatePickerInput } from '../Utilities/utils'
import DatePicker from "react-datepicker";

const RMhistory = (category, index) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [estDate, setEstDate] = useState();
  const [corePermit, setCorePermit] = useState("");
  const [assignWork, setAssignWork] = useState();
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [rMComplaints, setRMComplaints] = useState([]);
  const [rmDailyWork, setRmDailyWork] = useState([]);
  const [newRequest, setNewRequest] = useState([]);
  const [equipmentMonitoring, setEquipmentMonitoring] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState();

  // Filtering 
  const [statusFilter, setStatusFilter] = useState(""); // State to track selected filter


  const [status, setStatus] = useState();
  const [workPermitHistory, setWorkPermitHistory] = useState([])
  const [showTable, setShowTable] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dailyWorklogShift, setDailyWorklogShift] = useState([])
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  const handleRadioChange = (value) => {
    setCorePermit(value);
  };

  const handleAprove = () => {
    setBoxVisible(true);
  };

  const closeBox = () => {
    setBoxVisible(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);

    //  window.location.reload();
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const handleDateChange = (e) => {
    setEstDate(e.target.value);
  };


  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const handleImageClickBefore = (imageUrl) => {
    if (imageUrl) {
      // Open the image URL in a new tab
      window.open(imageUrl, "_blank", "noopener,noreferrer");
    } else {
      console.error("Invalid image URL");
    }
  };

  console.log(equipmentMonitoring, "equipmentMonitoring");

  const fetchData = async () => {
    const getApi = Rmhistory;
    const headers = await GetApiHeaders();

    try {
      setIsLoading(true);
      const response = await axios.post(getApi, {}, { headers });
      console.log("res:", response);
      // setData(response.data.response.RMComplaints )
      if (response.data != undefined && response.data != null) {
        if (
          response.data.response != undefined &&
          response.data.response != null
        ) {
          if (response.data.response.RMComplaints) {
            // console.log("rmhistory", response.data.response.RMComplaints);
            setRMComplaints(response.data.response.RMComplaints);
          }
          if (response.data.response.dailyWorkLog) {
            // console.log("dailyWork", response.data.response.dailyWorkLog);
            setRmDailyWork(response.data.response.dailyWorkLog);
          }
          if (response.data.response.newRequest) {
            console.log("newRequest", response.data.response.newRequest);
            setNewRequest(response.data.response.newRequest);
          }
          if (response.data.response.equipmentMonitoring) {
            // console.log("equipmentsssss", response.data.response.equipmentMonitoring);
            setEquipmentMonitoring(response.data.response.equipmentMonitoring);
          }
          if (response.data.response.equipmentMonitoring) {
            // console.log("dailyWorkLogShift", response.data.response.dailyWorkLogShift);
            setDailyWorklogShift(response.data.response.dailyWorkLogShift);
          }
          setIsLoading(false);
        } else {
          setRMComplaints([]);
          setRmDailyWork([]);
          setNewRequest([]);
          setEquipmentMonitoring([]);
          setDailyWorklogShift([])
        }
      } else {
        setRMComplaints([]);
        setRmDailyWork([]);
        setNewRequest([]);
        setEquipmentMonitoring([]);
        setDailyWorklogShift([])
      }
    } catch (err) {
      console.error("error", err);
    }
  };
  useEffect(() => {
    fetchData();
    console.log("selectedHistory", selectedHistory);
  }, [selectedHistory]);

  const resetDate = () => {
    setStartDate('')
    setEndDate('')
  }
  const historyOptions = [
    "Work Permit",
    "R&M Complaints",
    "New Requests",
    "Preventive Maintenance",
    "Daily WorkLog",
    "Daily WorkLog Shift"
  ];

  const handleSubmit = async (event) => {
    const apiUrl = workPermitsHistory;
    const headers = await GetApiHeaders();

    const postData = {

      "startDate": startDate,
      "endDate": endDate
    }
    try {
      setIsLoading(true)
      const response = await axios.post(apiUrl, postData, { headers })
      console.log('res Work Permit', response);
      setWorkPermitHistory(response.data.response.workPermitsHistory)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  }


  //DownloadHistory

  const handleDownload = async () => {
    const downloadApi = DownloadRMAcceptorHistory;
    try {
      setIsLoading(true);
      const headers = await GetApiHeaders();
      const requestBody = {
        requestType: selectedHistory,
        startDate: startDate,
        endDate: endDate
      };
      const response = await axios.post(downloadApi, requestBody, { headers });
      // Check for response structure
      if (response.data && response.data.response) {
        const responseKeys = Object.keys(response.data.response);
        if (responseKeys.length > 0) {
          const fileKey = responseKeys[0];
          const fileUrl = response.data.response[fileKey];
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute(
            "download",
            `${selectedHistory.replace(/ /g, "_")}_report.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("No downloadable file found in response.");
        }
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const filteredComplaints = statusFilter
  //   ? rMComplaints.filter((item) => item.status === statusFilter)
  //   : rMComplaints;

  // const filteredData = (data, statusFilter) => {
  //   return data.filter(
  //     (item) =>
  //       !statusFilter || item.status === statusFilter
  //   );
  // };

  // const filteredComplaints = filteredData(rMComplaints, statusFilter);
  // const filteredNewRequests = filteredData(newRequest, statusFilter);
  // const filteredWorkPermits = filteredData(workPermitHistory, statusFilter);


  const normalizeStatus = (status) => {
    if (status === "Approve") return "Approved";
    if (status === "Reject") return "Rejected";
    return status;
  };

  const filteredData = (data, statusFilter) => {
    return data.filter((item) => {
      const normalizedStatus = normalizeStatus(item.status);

      // If statusFilter is empty, return all items; otherwise filter by normalized status
      if (!statusFilter) {
        return true;
      }

      return normalizedStatus === statusFilter;
    });
  };

  // Usage for each dataset
  const filteredComplaints = filteredData(rMComplaints, statusFilter);
  const filteredNewRequests = filteredData(newRequest, statusFilter);
  const filteredWorkPermits = filteredData(workPermitHistory, statusFilter);





  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 ">
                    <div style={{
                      background: "#f8f9fa"
                    }}>
                      <h5 className="Roles_text">History</h5>
                      <div className="border-0 d-flex justify-content-between">
                        <select
                          className="selector border border-info"
                          id="dropdown"
                          type="dropdown"
                          value={selectedHistory}
                          onChange={(e) => setSelectedHistory(e.target.value)}
                        >
                          <option>Select History</option>
                          {historyOptions.map((item, itemIndex) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>


                      {/* Dropdown for filtering */}

                      <div className="d-flex justify-content-end align-items-center">
                        <select
                          id="statusFilter"
                          value={statusFilter}
                          onChange={(e) => setStatusFilter(e.target.value)}
                          style={{
                            marginRight: "15px",
                            width: "200px",
                            height: "40px",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "0 10px",
                          }}
                        >
                          <option value="">All</option>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                          <option value="InProgress">InProgress</option>
                          <option value="Completed">Completed</option>
                          <option value="Hold">Hold</option>
                          <option value="Postpone">Postpone</option>
                          <option value="Suspend">Suspend</option>

                        </select>

                        <button
                          className="btn btn-primary"
                          onClick={handleDownload}
                          disabled={isLoading}
                        >
                          {isLoading ? "Downloading..." : "Download"}
                        </button>
                      </div>

                      <div className="mcrc_hrline"></div>
                      {
                        selectedHistory === "Work Permit" ? (
                          <div className='card-body' style={{ padding: "20px 20px" }}>
                            <form>
                              <div className='row'>
                                <div className='col-sm-4 col-12'>
                                  <div class="mb-3">
                                    <label for="startdate" class="form-label farm-labels">Start Date<span style={{ color: "red" }}> *</span></label>
                                    <DatePicker className='form-control w-100 bg-white'
                                      placeholderText="Select Start Date"
                                      selected={startDate}
                                      onChange={(date) => { setStartDate(date) }}
                                      dateFormat="dd/MM/yyyy"
                                      customInput={<CustomDatePickerInput />} />
                                  </div>
                                </div>
                                <div className='col-sm-4 col-12'>
                                  <div class="mb-3">
                                    <label class="form-label  farm-labels" for="enddate">To End Date<span style={{ color: "red" }}> *</span>  </label>
                                    <DatePicker className='form-control w-100'
                                      placeholderText="Select End Date"
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      minDate={startDate}
                                      customInput={<CustomDatePickerInput />}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='mt-2 d-flex w-full justify-content-center gap-4'>
                                <Button className="btn btn-primary" style={{ paddingLeft: '2rem', paddingRight: '2rem' }} onClick={handleSubmit}>submit</Button>
                                <Button className="btn btn-danger" style={{ paddingLeft: '2rem', paddingRight: '2rem' }} onClick={resetDate}>Clear</Button>
                              </div>
                            </form>
                          </div>
                        ) : ''
                      }
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center ">History</Th>
                              </Tr>
                            </Thead>
                            <Tbody>

                              {selectedHistory === "Work Permit" &&
                                filteredWorkPermits.map((item, index) => {
                                  var newDateFormat = moment(item.date).format("DD-MMM-YYYY hh:mm:ss A");
                                  var requiredDate = moment(item.date).format("DD-MMM-YYYY ");
                                  var requestedDate = moment(item.requestedDate).format("DD-MMM-YYYY  hh:mm:ss A");
                                  var accepterSubmittedDate = moment(item.accepterSubmittedDate).format("DD-MMM-YYYY ");

                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-3 mb-2"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div key={index}
                                                style={{ fontSize: 14, width: "100%", }} className="ProductName d-flex"
                                              >
                                                <span className="" style={{ width: "32%" }}  >
                                                  <b className="">   {" "}  Requested Date&Time &nbsp;
                                                    &nbsp;:&nbsp;{" "} </b>{" "}
                                                  <span>{requestedDate} </span>
                                                </span>

                                                <span className="" style={{ width: "19%" }}  >
                                                  <b>  {" "}  Work Permit ID &nbsp;  &nbsp;:&nbsp;{" "}  </b>{" "}
                                                  <span>
                                                    {item.requestId}
                                                  </span>
                                                </span>
                                                <span className="" style={{ width: "30%" }} >
                                                  <b className="">
                                                    {" "}
                                                    Requester Name &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.primaryRequesterName}{" "}
                                                  </span>
                                                </span>
                                                <span className="" style={{ width: "20%" }}  >
                                                  <b>  {" "}  Status &nbsp; &nbsp;: &nbsp;{" "} </b>
                                                  <span>
                                                    {" "}
                                                    {item.status === "Reject" ? (
                                                      <span style={{ color: "#FF0000", fontWeight: 500, }} >
                                                        Rejected
                                                      </span>
                                                    ) : item.status === "Approve" ? (
                                                      <span
                                                        style={{ color: "#00CB0E", fontWeight: 500, }} >
                                                        Approved
                                                      </span>
                                                    ) : item.status === "Closed" ? (
                                                      <span style={{ color: "#F87F02", fontWeight: 500, }}  >
                                                        Closed
                                                      </span>
                                                    ) : item.status === "Completed" ? (
                                                      <span style={{ color: "#00CB0E", fontWeight: 500, }}  >
                                                        Completed
                                                      </span>
                                                    ) : item.status === "Postpone" ? (
                                                      <span style={{ color: "blue", fontWeight: 500, }}  >
                                                        Postpone
                                                      </span>
                                                    ) : item.status === "InProgress" ? (
                                                      <span style={{ color: "#F87F02", fontWeight: 500, }}  >
                                                        InProgress
                                                      </span>
                                                    ) : item.status === "Suspend" ? (
                                                      <span style={{ color: "#F87F02", fontWeight: 500, }}  >
                                                        Suspend
                                                      </span>
                                                    )

                                                      : item.status}
                                                  </span>{" "}
                                                  &nbsp; &nbsp;
                                                </span>
                                              </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                              <div style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"   >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Requester Mobile No
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.primaryMobileNumber}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Permit Type
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.permitName}</span>
                                                </span>
                                              </div>

                                              <div
                                                style={{ fontSize: 14, width: "100%" }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >

                                                {(item.status === "Approve" || item.status === "Reject") && (
                                                  <>
                                                    <span style={{ width: "33.4%" }}>
                                                      <span style={{ width: "45%", display: "inline-block", fontWeight: 600 }}>
                                                        {item.status === "Approve" ? "Approved By" : "Rejected By"}
                                                      </span>
                                                      <span>:&nbsp;</span>
                                                      <span style={{ width: "55%", display: "inline-block" }}>
                                                        {item.approvedOrRejectedBy}
                                                      </span>
                                                    </span>

                                                    <span style={{ width: "33.4%" }}>
                                                      <span style={{ width: "45%", display: "inline-block", fontWeight: 600 }}>
                                                        {item.status === "Approve"
                                                          ? "Approved Date&Time"
                                                          : "Rejected Date&Time"}
                                                      </span>
                                                      <span>:&nbsp;</span>
                                                      <span style={{ width: "55%", display: "inline-block" }}>
                                                        {newDateFormat}
                                                      </span>
                                                    </span>

                                                    {item.status === "Reject" && (
                                                      <div className="d-flex" style={{ gap: "120px" }}>
                                                        <div style={{ width: "25%", display: "flex", alignItems: "center" }}>
                                                          <span style={{ marginRight: "8px", fontWeight: "normal", fontWeight: 600 }}>Rejected Remarks :</span>
                                                          <span>{item.rejectedRemarks || "N/A"}</span>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              </div>

                                              {item.status === "Suspend" && (
                                                <div
                                                  style={{ fontSize: 14, width: "100%", }}
                                                  className="ProductNameForRandM d-flex mt-3"
                                                >
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Suspend By
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.suspendBy}</span>
                                                  </span>
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Suspend Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.suspendRemarks}</span>
                                                  </span>
                                                </div>
                                              )}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                              {selectedHistory === "R&M Complaints" &&
                                filteredComplaints.map((item, index) => {
                                  var newDateFormat = moment(item.requestedDate).format("DD-MMM-YYYY hh:mm:ss A");
                                  var requiredDate = moment(item.requiredDate).format("DD-MMM-YYYY ");
                                  var estimatedCompletionDate = moment(item.estimatedCompletionDate).format("DD-MMM-YYYY ");
                                  var accepterSubmittedDate = moment(item.accepterSubmittedDate).format("DD-MMM-YYYY ");
                                  var startDate = moment(item.startDate).format("DD-MMM-YYYY ");
                                  var endDate = moment(item.endDate).format("DD-MMM-YYYY ");

                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-3 mb-2"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >
                                                <span
                                                  className=""
                                                  style={{ width: "33.5%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requested Date & Time &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>{newDateFormat} </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "16.5%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Request ID &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.serverRecordId}
                                                  </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "31%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requester Name &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.requesterName}{" "}
                                                  </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Status &nbsp; &nbsp;: &nbsp;{" "}
                                                  </b>
                                                  <span>
                                                    {" "}
                                                    {item.status ===
                                                      "Rejected" ? (
                                                      <span
                                                        style={{
                                                          color: "#FF0000",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Rejected
                                                      </span>
                                                    ) : item.status ===
                                                      "Approved" ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Approved
                                                      </span>
                                                    ) : item.status ===
                                                      "InProgress" ? (
                                                      <span
                                                        style={{
                                                          color: "#F87F02",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        InProgress
                                                      </span>
                                                    ) : item.status ===
                                                      "Completed" ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Completed
                                                      </span>
                                                    ) : item.status ===
                                                      "Hold" ? (
                                                      <span
                                                        style={{
                                                          color: "SlateBlue",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Hold
                                                      </span>
                                                    ) : item.status ===
                                                      "Postpone" ? (
                                                      <span
                                                        style={{
                                                          color: "blue",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Postpone
                                                      </span>
                                                    )
                                                      : (
                                                        ""
                                                      )}
                                                  </span>{" "}
                                                  &nbsp; &nbsp;
                                                </span>
                                              </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"
                                              >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Function
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.function}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Type Of Work
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.typeOfWork}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.location}</span>
                                                </span>
                                              </div>

                                              <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"  >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Sub Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.subLocation}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Required Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{requiredDate}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Acceptor Name
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.acceptorName}</span>
                                                </span>
                                                {/* ) : (
                                                  ""
                                                )}  */}
                                              </div>
                                              {
                                                item.corePermitRequired && item.assignWorkToName && estimatedCompletionDate !== '' ?
                                                  (
                                                    <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"  >
                                                      {item.status === "Rejected" ? "" : (
                                                        <span style={{ width: '33.4%' }}>
                                                          <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                            CorePermit Required
                                                          </span>
                                                          <span >  :&nbsp;
                                                          </span>
                                                          <span style={{ width: '55%', display: 'inline-block' }}>{item.corePermitRequired}</span>
                                                        </span>
                                                      )}
                                                      {item.status === "Rejected" ? "" : (
                                                        <span style={{ width: '33.4%' }}>
                                                          <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                            {/* Assign Work To */}
                                                            Technician Name
                                                          </span>
                                                          <span >  :&nbsp;
                                                          </span>
                                                          <span style={{ width: '55%', display: 'inline-block' }}>{item.assignWorkToName}</span>
                                                        </span>

                                                      )}
                                                      {item.status === "Rejected" ? (""
                                                      ) : (
                                                        <span style={{ width: '33.4%' }}>
                                                          <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                            Estimated Completion
                                                          </span>
                                                          <span >  :&nbsp;
                                                          </span>
                                                          <span style={{ width: '55%', display: 'inline-block' }}>{estimatedCompletionDate}</span>
                                                        </span>
                                                      )}
                                                    </div>
                                                  ) : ''
                                              }

                                              <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"  >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Accepted Date & Time
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{accepterSubmittedDate}</span>
                                                </span>
                                                {
                                                  item.noOfCorePermits !== '' ? (
                                                    <span style={{ width: '33.4%' }}>
                                                      <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                        No of Core Permits
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '55%', display: 'inline-block' }}>{item.noOfCorePermits}</span>
                                                    </span>
                                                  ) : ''
                                                }


                                                {item.startDate !== "" ? (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      start Date
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{startDate}</span>
                                                  </span>) : (""
                                                )}
                                              </div>
                                              {
                                                item.endDate && item.startTime && item.endTime !== "" ?
                                                  (<div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"  >
                                                    {item.endDate !== "" ? (
                                                      <span style={{ width: '33.4%' }}>
                                                        <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                          End Date
                                                        </span>
                                                        <span >  :&nbsp;
                                                        </span>
                                                        <span style={{ width: '55%', display: 'inline-block' }}>{endDate}</span>
                                                      </span>
                                                    ) : (""
                                                    )}
                                                    {item.startTime !== "" ? (
                                                      <span style={{ width: '33.4%' }}>
                                                        <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                          start Time
                                                        </span>
                                                        <span >  :&nbsp;
                                                        </span>
                                                        <span style={{ width: '55%', display: 'inline-block' }}>{item.startTime}</span>
                                                      </span>) : (""
                                                    )}
                                                    {item.endTime !== "" ? (
                                                      <span style={{ width: '33.4%' }}>
                                                        <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                          End Time
                                                        </span>
                                                        <span >  :&nbsp;
                                                        </span>
                                                        <span style={{ width: '55%', display: 'inline-block' }}>{item.endTime}</span>
                                                      </span>) : (""
                                                    )}
                                                  </div>) : ""
                                              }


                                              <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"  >
                                                {item.requesterRemarks !== "" ? (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Requester Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces", }}>{item.requesterRemarks}</span>
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                                {item.accepterRemarks !== "" ? (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Acceptor Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces", }}>{item.accepterRemarks}</span>
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                                {item.technicianRemarks !==
                                                  "" ? (
                                                  <span style={{ width: '33.4%' }}  >
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Technician Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces", }}>{item.technicianRemarks}</span>
                                                  </span>) : (""
                                                )}

                                              </div>
                                              <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-2"  >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    {/* Assigned Work To */}
                                                    Technician Role
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  {/* <span style={{ width: '55%', display: 'inline-block', whiteSpace: "break-spaces", }}>{item.assignWorkTo.map((items) => items.assignWorkToName)}</span> */}
                                                  <span>{item.technicianShiftName}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images(Before)
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%' }}> {item.image.map(
                                                    (imageUrl, imgIndex) => (
                                                      <span key={imgIndex}
                                                        style={{ marginRight: "10px", display: "inline-block", border: "1px solid", }}  >
                                                        <span
                                                          style={{ width: "60%", }}  >
                                                          <img
                                                            key={imgIndex}
                                                            src={imageUrl}
                                                            alt={`Image ${imgIndex}`}
                                                            style={{ width: "30px", height: "40px", cursor: "pointer", }}
                                                            onClick={() => handleImageClick(imageUrl)}
                                                          />
                                                        </span>
                                                      </span>
                                                    )
                                                  )}</span>
                                                </span>


                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images(After)
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%' }}>
                                                    {item.workCompletionImages.map((imageObj, imgIndex) => (
                                                      <span
                                                        key={imgIndex}
                                                        style={{
                                                          marginRight: "10px",
                                                          display: "inline-block",
                                                          border: "1px solid",
                                                        }}
                                                      >
                                                        <span style={{ width: "60%" }}>
                                                          <img
                                                            src={imageObj.image}
                                                            alt={`Image ${imgIndex}`}
                                                            style={{
                                                              width: "30px",
                                                              height: "40px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => handleImageClickBefore(imageObj.image)}
                                                          />
                                                        </span>
                                                      </span>
                                                    ))}
                                                  </span>

                                                </span>


                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })}

                              {selectedHistory === "New Requests" &&
                                filteredNewRequests.map((item, index) => {
                                  var newDateFormat = moment(
                                    item.requestedDate
                                  ).format("DD-MMM-YYYY hh:mm:ss A");
                                  var accepterSubmittedDate = moment(
                                    item.accepterSubmittedDate
                                  ).format("DD-MMM-YYYY ");
                                  var estimatedCompletionDate = moment(
                                    item.estimatedCompletionDate
                                  ).format("DD-MMM-YYYY ");
                                  var requiredDate = moment(item.requiredDate).format("DD-MMM-YYYY ");
                                  var startDate = moment(item.startDate).format("DD-MMM-YYYY ");
                                  var endDate = moment(item.endDate).format("DD-MMM-YYYY ");

                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-3 mb-2"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >
                                                <span
                                                  className=""
                                                  style={{ width: "33.5%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requested Date & Time
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>{newDateFormat} </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "16.5%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Request ID &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {" "}
                                                    {item.serverRecordId}{" "}
                                                  </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "31%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requester Name &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.requesterName}{" "}
                                                  </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Status &nbsp; &nbsp;: &nbsp;{" "}
                                                  </b>
                                                  <span>
                                                    {" "}
                                                    {item.status ===
                                                      "Rejected" ? (
                                                      <span
                                                        style={{
                                                          color: "#FF0000",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Rejected
                                                      </span>
                                                    ) : item.status ===
                                                      "Approved" ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Approved
                                                      </span>
                                                    ) : item.status ===
                                                      "InProgress" ? (
                                                      <span
                                                        style={{
                                                          color: "#F87F02",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        InProgress
                                                      </span>
                                                    ) : item.status ===
                                                      "Completed" ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Completed
                                                      </span>
                                                    ) : item.status ===
                                                      "Hold" ? (
                                                      <span
                                                        style={{
                                                          color: "SlateBlue",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Hold
                                                      </span>
                                                    ) : item.status ===
                                                      "Postpone" ? (
                                                      <span
                                                        style={{
                                                          color: "blue",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        Postpone
                                                      </span>
                                                    )
                                                      : (
                                                        ""
                                                      )
                                                    }
                                                  </span>{" "}
                                                  &nbsp; &nbsp;
                                                </span>

                                              </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"  >

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Function
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.function}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Type Of Work
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.typeOfWork}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.location}</span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"
                                              >
                                                <span className="mt-3" style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Sub Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.subLocation}</span>
                                                </span>
                                                <span className="mt-3" style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Required Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{requiredDate}</span>
                                                </span>
                                                <span className="mt-3" style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Acceptor Name
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.acceptorName}</span>
                                                </span>

                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"
                                              >
                                                {item.status === "Approved" ? (
                                                  <span className="mt-3" style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      CorePermit Required
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.corePermitRequired}</span>
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                                {item.status === "Approved" ? (
                                                  <span className="mt-3" style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }}>
                                                      Technician Role
                                                    </span>
                                                    <span>:&nbsp;</span>
                                                    <span>{item.technicianShiftName}</span>
                                                  </span>
                                                ) : (
                                                  ""
                                                )}

                                                {item.status === "Approved" ? (
                                                  <span className="mt-3" style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Estimated Completion
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{estimatedCompletionDate}</span>
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"
                                              >
                                                <span className="mt-3" style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Acceptor Date & Time
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{accepterSubmittedDate}</span>
                                                </span>
                                                {
                                                  item.startDate !== '' ? (
                                                    <span className=" mt-3" style={{ width: '33.4%', }}>
                                                      <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                        Start Date
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '55%', display: 'inline-block' }}>{startDate}</span>
                                                    </span>
                                                  ) : ''
                                                }

                                                {
                                                  item.endDate !== '' ? (
                                                    <span className=" mt-3" style={{ width: '33.4%', }}>
                                                      <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                        End Date
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '55%', display: 'inline-block' }}>{endDate}</span>
                                                    </span>
                                                  ) : ''
                                                }

                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"
                                              >
                                                {
                                                  item.startTime !== '' ? (
                                                    <span className=" mt-3" style={{ width: '33.4%', }}>
                                                      <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                        Start Time
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '55%', display: 'inline-block' }}>{item.startTime}</span>
                                                    </span>
                                                  ) : ''
                                                }

                                                {
                                                  item.endTime !== '' ? (
                                                    <span className=" mt-3" style={{ width: '33.4%', }}>
                                                      <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                        End Time
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '55%', display: 'inline-block' }}>{item.endTime}</span>
                                                    </span>
                                                  ) : ''
                                                }
                                                {
                                                  item.noOfCorePermits !== '' ? (
                                                    <span className=" mt-3" style={{ width: '33.4%', }}>
                                                      <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                        No of Core permits
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '55%', display: 'inline-block' }}>{item.noOfCorePermits}</span>
                                                    </span>
                                                  ) : ''
                                                }
                                              </div>

                                              <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex" >
                                                {item.requesterRemarks !==
                                                  "" ? (
                                                  <span className=" mt-3" style={{ width: '33.4%', }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Requester Remarks
                                                    </span>
                                                    <span  >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requesterRemarks}</span>
                                                  </span>

                                                ) : (
                                                  ""
                                                )}
                                                {item.accepterRemarks !== "" ? (
                                                  <span className=" mt-3" style={{ width: '33.4%', }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Acceptor Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.accepterRemarks}</span>
                                                  </span>

                                                ) : (
                                                  ""
                                                )}

                                                {item.technicianRemarks !==
                                                  "" ? (
                                                  <span className=" mt-3" style={{ width: '33.4%', }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Technician Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.technicianRemarks}</span>
                                                  </span>

                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })}

                              {selectedHistory === "Preventive Maintenance" &&
                                equipmentMonitoring.map((item, index) => {
                                  var newDateFormat = moment(item.requestedDate).format("DD-MMM-YYYY hh:mm:ss A");
                                  var startDate = moment(item.startDate).format("DD-MMM-YYYY ");
                                  var endDate = moment(item.endDate).format("DD-MMM-YYYY ");
                                  var date = moment(item.date).format("DD-MMM-YYYY ");
                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-3 mb-2"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >
                                                <span
                                                  className=""
                                                  style={{ width: "33%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requested Date &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>{newDateFormat} </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "17%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Request ID &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {" "}
                                                    {item.serverRecordId}{" "}
                                                  </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "31%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requester Name &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.createdByEmpName}{" "}
                                                  </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Status &nbsp; &nbsp;: &nbsp;{" "}
                                                  </b>
                                                  <span
                                                    style={{
                                                      color: "#00CB0E",
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {" "}
                                                    {item.status}
                                                  </span>{" "}
                                                  &nbsp; &nbsp;
                                                </span>
                                              </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex"
                                              >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Requester Mobile No
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.createdByEmpMobileNumber}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.location}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Sub Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.subLocation}</span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Sub Site
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.subSite}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Site
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.site}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{date}</span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Start Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{startDate}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    End Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{endDate}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Start Time
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.startTime}</span>
                                                </span>

                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    End Time
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.endTime}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Equipment
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.equipment}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Equipment Condition
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.abnormality}</span>
                                                </span>

                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >{
                                                  item.abnormalityYes !== '' ? (
                                                    <span style={{ width: '33.4%' }}>
                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                        Condition resion
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '60%', display: 'inline-block' }}>{item.abnormalityYes}</span>
                                                    </span>
                                                  ) : ''
                                                }

                                                {item.noOfCorePermits !== "" ? (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                      Core Permit No
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '60%', display: 'inline-block' }}>{item.noOfCorePermits}</span>
                                                  </span>) : (""
                                                )}
                                                {item.seviceProviderName !==
                                                  "" ? (
                                                  <span style={{ width: '33.4%' }} >
                                                    <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                      Service Provide Name
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '60%', display: 'inline-block' }}>{item.seviceProviderName}</span>
                                                  </span>) : (""
                                                )}
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                {item.remarks !== "" ? (
                                                  <span style={{ width: '33.4%' }} className="mt-2">
                                                    <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                      Remarks
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.remarks}</span>
                                                  </span>) : (""
                                                )}

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Technician Role
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.technicianShiftName}</span>
                                                </span>


                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%' }}> {item.uploadCheckList.map(
                                                    (imageUrl, imgIndex) => (
                                                      <span key={imgIndex}
                                                        style={{
                                                          marginRight: "10px",
                                                          display:
                                                            "inline-block",
                                                          border: "1px solid",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        >
                                                          <img
                                                            key={imgIndex}
                                                            src={imageUrl}
                                                            alt={`Image ${imgIndex}`}
                                                            style={{
                                                              width: "30px",
                                                              height: "40px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleImageClick(
                                                                imageUrl
                                                              )
                                                            }
                                                          />
                                                        </span>
                                                      </span>
                                                    )
                                                  )}</span>
                                                </span>


                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })}

                              {selectedHistory === "Daily WorkLog" &&
                                rmDailyWork.map((item, index) => {
                                  var newDateFormat = moment(
                                    item.requestedDate
                                  ).format("DD-MMM-YYYY hh:mm:ss A");
                                  var submittedDate = moment(
                                    item.submittedDate
                                  ).format("DD-MMM-YYYY hh:mm:ss A");
                                  var Date = moment(item.date).format("DD-MMM-YYYY");
                                  // var startDate = moment(item.startDate).format(  "DD-MMM-YYYY"  );
                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-3 mb-2"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >
                                                <span
                                                  className=""
                                                  style={{ width: "17%" }}
                                                >
                                                  <b>
                                                    {" "}
                                                    Request ID &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.serverRecordId}
                                                  </span>
                                                </span>
                                                <span
                                                  className=""
                                                  style={{ width: "33%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Requested Date &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>{newDateFormat} </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "31%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Assigned By &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.assignedByEmpName}{" "}
                                                  </span>
                                                </span>
                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b>
                                                    Status &nbsp; &nbsp;: &nbsp;
                                                  </b>
                                                  <span>
                                                    {" "}
                                                    {item.status ===
                                                      "Rejected" ? (
                                                      <span
                                                        style={{
                                                          color: "#FF0000",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Rejected
                                                      </span>
                                                    ) : item.status ===
                                                      "Completed" ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Completed
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Approved
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  &nbsp; &nbsp;
                                                </span>
                                              </div>
                                            </Accordion.Header>

                                            <Accordion.Body>

                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex "
                                              >
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    General Works
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.generalWorks}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Core Permit Required
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.corePermitRequired}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.location}</span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Sub Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.subLocation}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Equipment
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.equipment}</span>
                                                </span>

                                                {/* <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Equipment Condition
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block', whiteSpace: "break-spaces" }}>{item.equipmentCondition}</span>
                                                </span> */}

                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{Date}</span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    {/* Assign Work To */}
                                                    Technician Role
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  {/* <span style={{ width: '58%', display: 'inline-block' }}>{item.assignWorkToName}</span> */}
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.technicianShiftName}</span>

                                                </span>
                                                {
                                                  item.equipmentConditionNotOkay !== '' ? (
                                                    <span style={{ width: '33.4%' }} className="mt-2">
                                                      <span style={{ width: '42%', display: 'inline-block', fontWeight: 600 }} >
                                                        Reason
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '58%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.equipmentConditionNotOkay}</span>
                                                    </span>
                                                  ) : ''
                                                }

                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Submitted Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block', whiteSpace: "break-spaces" }}>{submittedDate}</span>
                                                </span>

                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Remarks
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.remarks}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%' }}> {item.uploadCheckList.map(
                                                    (imageUrl, imgIndex) => (
                                                      <span key={imgIndex}
                                                        style={{
                                                          marginRight: "10px",
                                                          display:
                                                            "inline-block",
                                                          border: "1px solid",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        >
                                                          <img
                                                            key={imgIndex}
                                                            src={imageUrl}
                                                            alt={`Image ${imgIndex}`}
                                                            style={{
                                                              width: "30px",
                                                              height: "40px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleImageClick(
                                                                imageUrl
                                                              )
                                                            }
                                                          />
                                                        </span>
                                                      </span>
                                                    )
                                                  )}</span>
                                                </span>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })}


                              {selectedHistory === "Daily WorkLog Shift" &&
                                dailyWorklogShift.map((item, index) => {

                                  var submittedDate = moment(item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A");

                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-3 mb-2"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >

                                                <span
                                                  className=""
                                                  style={{ width: "33%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Submited Date &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>{submittedDate} </span>
                                                </span>

                                                <span
                                                  className=""
                                                  style={{ width: "31%" }}
                                                >
                                                  <b className="">
                                                    {" "}
                                                    Assigned To &nbsp;
                                                    &nbsp;:&nbsp;{" "}
                                                  </b>{" "}
                                                  <span>
                                                    {item.assignWorkToName}{" "}
                                                  </span>
                                                </span>
                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b>
                                                    Mobile No &nbsp; &nbsp;: &nbsp;
                                                  </b>
                                                  <span>
                                                    {" "}
                                                    {item.assignWorkToMobileNumber}
                                                  </span>{" "}
                                                  &nbsp; &nbsp;
                                                </span>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>

                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    {/* Daily Shift Name */}
                                                    Equipments
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block' }}>{item.dailyShiftName}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Equipment Condition
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-block', whiteSpace: "break-spaces" }}>{item.equipmentCondition}</span>
                                                </span>
                                                {
                                                  item.equipmentConditionNotOkay !== '' ? (
                                                    <span style={{ width: '33.4%' }} className="mt-2">
                                                      <span style={{ width: '42%', display: 'inline-block', fontWeight: 600 }} >
                                                        Reason
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '58%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.equipmentConditionNotOkay}</span>
                                                    </span>
                                                  ) : ''
                                                }

                                              </div>
                                              <div
                                                style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3"
                                              >
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Selected Shift Name
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.selectedShiftName}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }} className="mt-2">
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Remarks
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.remarks}</span>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%' }}> {item.uploadCheckList.map(
                                                    (imageUrl, imgIndex) => (
                                                      <span key={imgIndex}
                                                        style={{
                                                          marginRight: "10px",
                                                          display:
                                                            "inline-block",
                                                          border: "1px solid",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        >
                                                          <img
                                                            key={imgIndex}
                                                            src={imageUrl}
                                                            alt={`Image ${imgIndex}`}
                                                            style={{
                                                              width: "30px",
                                                              height: "40px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleImageClick(
                                                                imageUrl
                                                              )
                                                            }
                                                          />
                                                        </span>
                                                      </span>
                                                    )
                                                  )}</span>
                                                </span>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                {isLoading && (
                  <Loader_Bulk
                    loading={isLoading}
                    message={"Fetching Data. Please Wait..!"}
                    loderimage={loaderImage}
                  />
                )}
              </div>
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default RMhistory;
