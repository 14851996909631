import React, { useState, useEffect } from "react";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllPermitsByCorePermitID } from "../Utilities/URLCONSTANTS";
import { useSearchParams } from "react-router-dom";
import moment from "moment/moment";

//Header images
import Factory from "../images/Factory-header-corepermit.png";
import CortevaImage from "../images/corteva-logo.png";
import HouseAndLighthouse from "../images/HouseAndLighthouse.png";
import Labtubeheadercorepermit from "../images/Labtubeheadercorepermit.png";
import shakinghandsHeadersCorepermit from "../images/shakinghandsHeadersCorepermit.png";
import ImageheaderCorepermit from "../images/ImageheaderCorepermit.png";
import DroneHeaderCorepermit from "../images/DroneHeaderCorepermit.png";

import cortevalogo from "../images/cortevalogo-white.png";
import Loader from "../../src/Utilities/Loader";

const ExcavationPermit_Web = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [searchParams, setSearchParams] = useSearchParams();
  // const IdFromURL = searchParams.get('corePermitID');
  const IdFromURLwithEDit = searchParams.get("corePermitID");
  var IdFromURL;
  var additional_permit_id;
  if (IdFromURLwithEDit == null) {
  } else {
    IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
    additional_permit_id = IdFromURLwithEDit.toString().split("/")[1];
  }

  const [partOne, setPartOne] = useState();
  //CORE Permit #:
  const [initialsOne, setInitialsOne] = useState("");
  const [initialsTwo, setInitialsTwo] = useState("");
  const [initialsThree, setInitialsThree] = useState("");

  const [dateInputOne, setDateInputOne] = useState("");

  const [permitsOne, setPermitsOne] = useState("");
  const [permitsTwo, setPermitsTwo] = useState("");

  //1. Emergency
  const [excaEmergency, setexcaEmergency] = useState();
  const [al, setal] = useState("");

  //2. PART 1
  const [excaWork, setexcaWork] = useState();
  const [excaUnder, setexcaUnder] = useState();
  const [excaReason, setexcaReason] = useState();
  const [excaLocation, setexcaLocation] = useState();
  const [excaStartDate, setexcaStartDate] = useState();
  const [excaPermit, setexcaPermit] = useState();
  const [excaCompetent, setexcaCompetent] = useState();
  const [excaDate, setexcaDate] = useState();
  const [excaPhone, setexcaPhone] = useState();
  const [excaNumber, setexcaNumber] = useState();
  //3. PART 2
  //Basic--- swathi
  const [title1yes, setTitle1yes] = useState();
  const [title1no, setTitle1no] = useState();
  const [excaHaveAllYes, setexcaHaveAllYes] = useState();
  const [excaHaveAllNo, setexcaHaveAllNo] = useState();
  //Indicate
  const [indicateList0, setindicateList0] = useState();
  const [indicateList1, setindicateList1] = useState();
  const [indicateList2, setindicateList2] = useState();
  const [indicateList3, setindicateList3] = useState();
  const [indicateList4, setindicateList4] = useState();
  const [indicateListInput4, setindicateListInput4] = useState();
  //Explain
  const [explainValue, setexplainValue] = useState();
  //Utilities Present
  const [utilitiesList0, setutilitiesList0] = useState();
  const [utilitiesList1, setutilitiesList1] = useState();
  const [utilitiesList2, setutilitiesList2] = useState();
  const [utilitiesList3, setutilitiesList3] = useState();
  const [utilitiesList4, setutilitiesList4] = useState();
  const [utilitiesList5, setutilitiesList5] = useState();
  const [utilitiesList6, setutilitiesList6] = useState();
  const [utilitiesList7, setutilitiesList7] = useState();
  const [utilitiesList8, setutilitiesList8] = useState();
  //can,does
  const [canYes, setcanYes] = useState();
  const [canNo, setcanNo] = useState();
  const [doesYes, setdoesYes] = useState();
  const [doesNo, setdoesNo] = useState();
  const [wpVal, setwpVal] = useState();
  //is air
  const [isAirYes, setisAirYes] = useState();
  const [isAirNo, setisAirNo] = useState();
  //Special Precautions
  const [sp0, setsp0] = useState();
  const [sp1, setsp1] = useState();
  const [sp2, setsp2] = useState();
  const [sp3, setsp3] = useState();
  const [sp4, setsp4] = useState();
  const [sp5, setsp5] = useState();
  const [sp6, setsp6] = useState();
  const [sp7, setsp7] = useState();
  const [sp8, setsp8] = useState();
  const [spInput8, setspInput8] = useState();
  const [sp9, setsp9] = useState();
  const [spInput9, setspInput9] = useState();
  //Has a Professional Registered Engineer approved all protection systems for excavations deeper than 20ft(6m)?
  const [hpdYes, sethpdYes] = useState();
  const [hpdNo, sethpdNo] = useState();
  const [hpdNa, sethpdNa] = useState();
  //Comments
  const [spComments, setspComments] = useState();
  //Has a Professional Registered Engineer approve all protection systems for excavations that will reduce support of existing foundations regardless of depth?
  const [hprYes, sethprYes] = useState();
  const [hprNo, sethprNo] = useState();
  const [hprNa, sethprNa] = useState();
  //Excavation
  const [excaApprovalP1, setexcaApprovalP1] = useState();
  const [excaApprovalPH1, setexcaApprovalPH1] = useState();
  const [excaApprovalP2, setexcaApprovalP2] = useState();
  const [excaApprovalPH2, setexcaApprovalPH2] = useState();
  const [excaApprovalP3, setexcaApprovalP3] = useState();
  const [excaApprovalPH3, setexcaApprovalPH3] = useState();
  const [excaApprovalP4, setexcaApprovalP4] = useState();
  const [excaApprovalPH4, setexcaApprovalPH4] = useState();
  //Part3
  //Soll Classification
  const [sollStable, setsollStable] = useState();
  const [sollA, setsollA] = useState();
  const [sollB, setsollB] = useState();
  const [sollC, setsollC] = useState();
  //Is the excavation close to utilities,buildings,footings,pillings,or sources of vibraton?
  const [isTEYes, setisTEYes] = useState();
  const [isTENo, setisTENo] = useState();
  const [isTENa, setisTENa] = useState();
  //Have the owners of utility,service,and transmission piping been contracted?
  const [isHTOYes, setisHTOYes] = useState();
  const [isHTONo, setisHTONo] = useState();
  const [isHTONa, setisHTONa] = useState();
  //Has detetction equipment been used to loacate all underground and/or above ground inteferences?
  const [isHDEYes, setisHDEYes] = useState();
  const [isHDENo, setisHDENo] = useState();
  const [isHDENa, setisHDENa] = useState();
  // Has a check been made for previously disturbed ground?
  const [isHCBYes, setisHCBYes] = useState();
  const [isHCBNo, setisHCBNo] = useState();
  const [isHCBNa, setisHCBNa] = useState();

  //Has the adeqacy and availability of all equipment been checked,including personal protective quipment,shoring material,signs,barricades,and machinery?
  const [isTAAYes, setisTAAYes] = useState();
  const [isTAANo, setisTAANo] = useState();
  const [isTAANa, setisTAANa] = useState();

  //Has a check been made for other obstructions(e.g.,footing concrete encasements)?
  const [isHACYes, setisHACYes] = useState();
  const [isHACNo, setisHACNo] = useState();
  const [isHACNa, setisHACNa] = useState();
  //If other obstructions have been found,list them
  const [obstructions, setobstructions] = useState();
  const [allowable, setallowable] = useState();
  const [describe, setdescribe] = useState();
  const [sod, setsod] = useState();
  const [sow, setsow] = useState();
  const [soh, setsoh] = useState();
  //Note
  const [noteInput, setNoteInput] = useState();
  const [part3Date, setpart3Date] = useState();
  const [part3InputVal, setpart3InputVal] = useState();
  //List
  const [listYes0, setlistYes0] = useState();
  const [listNo0, setlistNo0] = useState();
  const [listNa0, setlistNa0] = useState();

  const [listYes1, setlistYes1] = useState();
  const [listNo1, setlistNo1] = useState();
  const [listNa1, setlistNa1] = useState();

  const [listYes2, setlistYes2] = useState();
  const [listNo2, setlistNo2] = useState();
  const [listNa2, setlistNa2] = useState();

  const [listYes3, setlistYes3] = useState();
  const [listNo3, setlistNo3] = useState();
  const [listNa3, setlistNa3] = useState();

  const [listYes4, setlistYes4] = useState();
  const [listNo4, setlistNo4] = useState();
  const [listNa4, setlistNa4] = useState();

  const [listYes5, setlistYes5] = useState();
  const [listNo5, setlistNo5] = useState();
  const [listNa5, setlistNa5] = useState();

  const [listYes6, setlistYes6] = useState();
  const [listNo6, setlistNo6] = useState();
  const [listNa6, setlistNa6] = useState();
  //If the depth of the excavation is 5feet(1.5 meters)or more,check the applicabl OSHA appendix below:
  const [depthB, setdepthB] = useState();
  const [depthC, setdepthC] = useState();
  const [depthD, setdepthD] = useState();
  const [depthE, setdepthE] = useState();
  const [depthF, setdepthF] = useState();

  useEffect(() => {
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
  }, []);
  const getFormData = async (id, additional_permit_id) => {
    console.log(id);
    try {
      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });
      setIsLoading(false);
      console.log("response data:", response.data);

      if (response.data && response.data.response) {
        const elevated = response?.data?.response?.excavation_permit || [];
        // alert(JSON.stringify( elevated));
        const dataLast =
          elevated.find((item) => item.permitID === additional_permit_id)
            ?.permitObject || null;
        //  alert(JSON.stringify(dataLast));
        if (dataLast) {
          console.log("dataLast123", dataLast);
          console.log(response.serverRecordId);
          //1. Emergency
          // alert(JSON.stringify(dataLast));

          setInitialsOne(dataLast?.permitInitials.initialsOne);
          setInitialsTwo(dataLast?.permitInitials.initialsTwo);
          setInitialsThree(dataLast?.permitInitials.initialsThree);

          // setDateInputOne(
          //   dataLast?.permitInitials.dateInputOne
          //     ? moment(dataLast.permitInitials.dateInputOne).format(
          //         "DD-MMM-YYYY"
          //       )
          //     : ""
          // );

          const rawDateInputOne = dataLast?.permitInitials.dateInputOne;
          const formattedDateInputOne = moment(rawDateInputOne, "DD-MM-YYYY", true).isValid()
            ? moment(rawDateInputOne, "DD-MM-YYYY").format("DD-MMM-YYYY")
            : rawDateInputOne;

          setDateInputOne(formattedDateInputOne);


          setPermitsOne(dataLast?.permitInitials.permitsOne);
          setPermitsTwo(dataLast?.permitInitials.permitsTwo);

          setexcaEmergency(dataLast?.emergency.phoneNumber);
          setal(dataLast?.emergency.assemblyLocation);
          //2. Part -1
          setexcaWork(dataLast?.partOne.list["0"].value);
          setexcaUnder(dataLast?.partOne.list["1"].value);
          setexcaReason(dataLast?.partOne.list["2"].value);
          setexcaLocation(dataLast?.partOne.list["3"].value);

          // setexcaStartDate(
          //   moment(dataLast?.partOne.list["4"].value).format("DD-MMM-YYYY")
          // );
          setexcaStartDate(
            dataLast?.partOne.list["4"].value
              ? moment(dataLast?.partOne.list["4"].value).format("DD-MMM-YYYY")
              : ""
          );

          // setexcaPermit(
          //   moment(dataLast?.partOne.list["5"].value).format("DD-MMM-YYYY")
          // );
          setexcaPermit(
            dataLast?.partOne.list["5"].value
              ? moment(dataLast?.partOne.list["5"].value).format("DD-MMM-YYYY")
              : ""
          );

          setexcaCompetent(dataLast?.partOne.list["6"].value);

          // setexcaDate(
          //   moment(dataLast?.partOne.list["7"].value).format("DD-MMM-YYYY")
          // );

          setexcaDate(
            dataLast?.partOne.list["7"].value
              ? moment(dataLast?.partOne.list["7"].value).format("DD-MMM-YYYY")
              : ""
          );
          setexcaPhone(dataLast?.partOne.list["8"].value);
          setexcaNumber(dataLast?.partOne.list["9"].value);
          //3. Part-2

          setexcaHaveAllYes(dataLast?.partTwo.haveAll.cbYes == "true");
          setexcaHaveAllNo(dataLast?.partTwo.haveAll.cbNo == "true");

          //Basic---swathi
          if (dataLast?.partTwo.basic["0"].title1yes == "true") {
            setTitle1yes("checked");
          }
          if (dataLast?.partTwo.basic["0"].title1no == "true") {
            setTitle1no("checked");
          }
          //Indicate
          if (dataLast?.partTwo.indicate["0"].list["0"].checked == true) {
            setindicateList0("checked");
          }
          if (dataLast?.partTwo.indicate["0"].list["1"].checked == true) {
            setindicateList1("checked");
          }
          if (dataLast?.partTwo.indicate["0"].list["2"].checked == true) {
            setindicateList2("checked");
          }
          if (dataLast?.partTwo.indicate["0"].list["3"].checked == true) {
            setindicateList3("checked");
          }
          if (dataLast?.partTwo.indicate["0"].list["4"].checked == true) {
            setindicateList4("checked");
          }
          setindicateListInput4(
            dataLast?.partTwo.indicate["0"].list["4"].description
          );
          //Explain
          setexplainValue(dataLast?.partTwo.explain.cbValue);
          //Utilities Present
          if (dataLast?.partTwo.utilities.list["0"].isChecked == "true") {
            setutilitiesList0("checked");
          }
          if (dataLast?.partTwo.utilities.list["1"].isChecked == "true") {
            setutilitiesList1("checked");
          }
          if (dataLast?.partTwo.utilities.list["2"].isChecked == "true") {
            setutilitiesList2("checked");
          }
          if (dataLast?.partTwo.utilities.list["3"].isChecked == "true") {
            setutilitiesList3("checked");
          }
          if (dataLast?.partTwo.utilities.list["4"].isChecked == "true") {
            setutilitiesList4("checked");
          }
          if (dataLast?.partTwo.utilities.list["5"].isChecked == "true") {
            setutilitiesList5("checked");
          }
          if (dataLast?.partTwo.utilities.list["6"].isChecked == "true") {
            setutilitiesList6("checked");
          }
          if (dataLast?.partTwo.utilities.list["7"].isChecked == "true") {
            setutilitiesList7("checked");
          }
          if (dataLast?.partTwo.utilities.list["8"].isChecked == "true") {
            setutilitiesList8("checked");
          }
          //can,does
          if (dataLast?.partTwo.canThese.cbYes == "true") {
            setcanYes("checked");
          }
          if (dataLast?.partTwo.canThese.cbNo == "true") {
            setcanNo("checked");
          }
          if (dataLast?.partTwo.doesThis.cbYes == "true") {
            setdoesYes("checked");
          }
          if (dataLast?.partTwo.doesThis.cbNo == "true") {
            setdoesNo("checked");
          }
          setwpVal(dataLast?.partTwo.whatPrecautuions.cbValue);
          //is air
          if (dataLast?.partTwo.isAir.cbYes == "true") {
            setisAirYes("checked");
          }
          if (dataLast?.partTwo.isAir.cbNo == "true") {
            setisAirNo("checked");
          }

          //Special Precautions
          if (dataLast?.partTwo.special["0"].list["0"].checked == true) {
            setsp0("checked");
          }
          if (dataLast?.partTwo.special["0"].list["1"].checked == true) {
            setsp1("checked");
          }
          if (dataLast?.partTwo.special["0"].list["2"].checked == true) {
            setsp2("checked");
          }
          if (dataLast?.partTwo.special["0"].list["3"].checked == true) {
            setsp3("checked");
          }
          if (dataLast?.partTwo.special["0"].list["4"].checked == true) {
            setsp4("checked");
          }
          if (dataLast?.partTwo.special["0"].list["5"].checked == true) {
            setsp5("checked");
          }
          if (dataLast?.partTwo.special["0"].list["6"].checked == true) {
            setsp6("checked");
          }
          if (dataLast?.partTwo.special["0"].list["7"].checked == true) {
            setsp7("checked");
          }
          if (dataLast?.partTwo.special["0"].list["8"].checked == true) {
            setsp8("checked");
          }
          if (dataLast?.partTwo.special["0"].list["9"].checked == true) {
            setsp9("checked");
          }
          setspInput8(dataLast?.partTwo.special["0"].list["8"].description);
          setspInput9(dataLast?.partTwo.special["0"].list["9"].description);
          //Has a Professional Registered Engineer approved all protection systems for excavations deeper than 20ft(6m)?
          if (
            dataLast?.partTwo.special["0"].hasProfessionalDeeper.cbYes == "true"
          ) {
            sethpdYes("checked");
          }
          if (
            dataLast?.partTwo.special["0"].hasProfessionalDeeper.cbNo == "true"
          ) {
            sethpdNo("checked");
          }
          if (
            dataLast?.partTwo.special["0"].hasProfessionalDeeper.cbNA == "true"
          ) {
            sethpdNa("checked");
          }
          ////Comments

          setspComments(
            dataLast?.partTwo.special["0"].hasProfessionalDeeper.description
          );

          //Has a Professional Registered Engineer approve all protection systems for excavations that will reduce support of existing foundations regardless of depth?
          if (
            dataLast?.partTwo.special["0"].hasProfessionalReduce.cbYes == "true"
          ) {
            sethprYes("checked");
          }
          if (
            dataLast?.partTwo.special["0"].hasProfessionalReduce.cbNo == "true"
          ) {
            sethprNo("checked");
          }
          if (
            dataLast?.partTwo.special["0"].hasProfessionalReduce.cbNA == "true"
          ) {
            sethprNa("checked");
          }

          //Excavation
          setexcaApprovalP1(
            dataLast?.partTwo.special["0"].escavationApproval.list["0"]
              .description
          );
          setexcaApprovalPH1(
            dataLast?.partTwo.special["0"].escavationApproval.list["0"]
              .phoneNumber
          );

          setexcaApprovalP2(
            dataLast?.partTwo.special["0"].escavationApproval.list["1"]
              .description
          );
          setexcaApprovalPH2(
            dataLast?.partTwo.special["0"].escavationApproval.list["1"]
              .phoneNumber
          );

          setexcaApprovalP3(
            dataLast?.partTwo.special["0"].escavationApproval.list["2"]
              .description
          );
          setexcaApprovalPH3(
            dataLast?.partTwo.special["0"].escavationApproval.list["2"]
              .phoneNumber
          );

          setexcaApprovalP4(
            dataLast?.partTwo.special["0"].escavationApproval.list["3"]
              .description
          );
          setexcaApprovalPH4(
            dataLast?.partTwo.special["0"].escavationApproval.list["3"]
              .phoneNumber
          );
          //Part3
          //Soll Classification
          if (dataLast?.partThree.soilClasification.StableRock == "true") {
            setsollStable("checked");
          }
          if (dataLast?.partThree.soilClasification.typeA == "true") {
            setsollA("checked");
          }
          if (dataLast?.partThree.soilClasification.typeB == "true") {
            setsollB("checked");
          }
          if (dataLast?.partThree.soilClasification.typeC == "true") {
            setsollC("checked");
          }
          //Is the excavation close to utilities,buildings,footings,pillings,or sources of vibraton?

          if (dataLast?.partThree.isTheescavation.cbYes == "true") {
            setisTEYes("checked");
          }
          if (dataLast?.partThree.isTheescavation.cbNo == "true") {
            setisTENo("checked");
          }
          if (dataLast?.partThree.isTheescavation.cbNA == "true") {
            setisTENa("checked");
          }
          //Have the owners of utility,service,and transmission piping been contracted?
          if (dataLast?.partThree.haveTheOwners.cbYes == "true") {
            setisHTOYes("checked");
          }
          if (dataLast?.partThree.haveTheOwners.cbNo == "true") {
            setisHTONo("checked");
          }
          if (dataLast?.partThree.haveTheOwners.cbNA == "true") {
            setisHTONa("checked");
          }
          //Has detetction equipment been used to loacate all underground and/or above ground inteferences?
          if (dataLast?.partThree.hasDetection.cbYes == "true") {
            setisHDEYes("checked");
          }
          if (dataLast?.partThree.hasDetection.cbNo == "true") {
            setisHDENo("checked");
          }
          if (dataLast?.partThree.hasDetection.cbNA == "true") {
            setisHDENa("checked");
          }

          // Has a check been made for previously disturbed ground?
          if (dataLast?.partThree.hasACheck.cbYes == "true") {
            setisHCBYes("checked");
          }
          if (dataLast?.partThree.hasACheck.cbNo == "true") {
            setisHCBNo("checked");
          }
          if (dataLast?.partThree.hasACheck.cbNA == "true") {
            setisHCBNa("checked");
          }

          //Has the adeqacy and availability of all equipment been checked,including personal protective quipment,shoring material,signs,barricades,and machinery?
          if (dataLast?.partThree.adeqacy.cbYes == "true") {
            setisTAAYes("checked");
          }
          if (dataLast?.partThree.adeqacy.cbNo == "true") {
            setisTAANo("checked");
          }
          if (dataLast?.partThree.adeqacy.cbNA == "true") {
            setisTAANa("checked");
          }
          //Has a check been made for other obstructions(e.g.,footing concrete encasements)?
          if (dataLast?.partThree.checkBeen.cbYes == "true") {
            setisHACYes("checked");
          }
          if (dataLast?.partThree.checkBeen.cbNo == "true") {
            setisHACNo("checked");
          }
          if (dataLast?.partThree.checkBeen.cbNA == "true") {
            setisHACNa("checked");
          }
          //If other obstructions have been found,list them
          setobstructions(dataLast?.partThree.obstructions.description);
          setallowable(dataLast?.partThree.allowable.description);
          setdescribe(dataLast?.partThree.describe.description);
          setsod(dataLast?.partThree.depthval);
          setsow(dataLast?.partThree.widthval);
          setsoh(dataLast?.partThree.heightval);
          //Note
          setNoteInput(dataLast?.partThree.escavationval);

          setpart3Date(
            dataLast?.partThree.date
              ? moment(dataLast?.partThree.date).format("DD-MMM-YYYY")
              : ""
          );

          setpart3InputVal(dataLast?.partThree.inputValue);
          //Have ground conditons been checked,particularly after rainfall?
          //List 0
          if (dataLast?.partThree.list["0"].cbYes == "true") {
            setlistYes0("checked");
          }
          if (dataLast?.partThree.list["0"].cbNo == "true") {
            setlistNo0("checked");
          }
          if (dataLast?.partThree.list["0"].cbNA == "true") {
            setlistNa0("checked");
          }
          //1
          if (dataLast?.partThree.list["1"].cbYes == "true") {
            setlistYes1("checked");
          }
          if (dataLast?.partThree.list["1"].cbNo == "true") {
            setlistNo1("checked");
          }
          if (dataLast?.partThree.list["1"].cbNA == "true") {
            setlistNa1("checked");
          }
          //2
          if (dataLast?.partThree.list["2"].cbYes == "true") {
            setlistYes2("checked");
          }
          if (dataLast?.partThree.list["2"].cbNo == "true") {
            setlistNo2("checked");
          }
          if (dataLast?.partThree.list["2"].cbNA == "true") {
            setlistNa2("checked");
          }
          //3
          if (dataLast?.partThree.list["3"].cbYes == "true") {
            setlistYes3("checked");
          }
          if (dataLast?.partThree.list["3"].cbNo == "true") {
            setlistNo3("checked");
          }
          if (dataLast?.partThree.list["3"].cbNA == "true") {
            setlistNa3("checked");
          }
          //4
          if (dataLast?.partThree.list["4"].cbYes == "true") {
            setlistYes4("checked");
          }
          if (dataLast?.partThree.list["4"].cbNo == "true") {
            setlistNo4("checked");
          }
          if (dataLast?.partThree.list["4"].cbNA == "true") {
            setlistNa4("checked");
          }
          //5
          if (dataLast?.partThree.list["5"].cbYes == "true") {
            setlistYes5("checked");
          }
          if (dataLast?.partThree.list["5"].cbNo == "true") {
            setlistNo5("checked");
          }
          if (dataLast?.partThree.list["5"].cbNA == "true") {
            setlistNa5("checked");
          }
          //6
          if (dataLast?.partThree.list["6"].cbYes == "true") {
            setlistYes6("checked");
          }
          if (dataLast?.partThree.list["6"].cbNo == "true") {
            setlistNo6("checked");
          }
          if (dataLast?.partThree.list["6"].cbNA == "true") {
            setlistNa6("checked");
          }
          //If the depth of the excavation is 5feet(1.5 meters)or more,check the applicabl OSHA appendix below:
          if (
            dataLast?.partThree.depthOfExcavation.list["0"].cbChecked == "true"
          ) {
            setdepthB("checked");
          }
          if (
            dataLast?.partThree.depthOfExcavation.list["1"].cbChecked == "true"
          ) {
            setdepthC("checked");
          }
          if (
            dataLast?.partThree.depthOfExcavation.list["2"].cbChecked == "true"
          ) {
            setdepthD("checked");
          }
          if (
            dataLast?.partThree.depthOfExcavation.list["3"].cbChecked == "true"
          ) {
            setdepthE("checked");
          }
          if (
            dataLast?.partThree.depthOfExcavation.list["4"].cbChecked == "true"
          ) {
            setdepthF("checked");
          }
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    // console.log("partOne:: ", partOne);
  }, [partOne]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <body id="page-top">
      <div style={{ textAlign: "right" }}>
        <button onClick={handlePrint} className="btn btn-primary print-hidden ">
          Print
        </button>
      </div>
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/*Part 1 */}
            <div>
              <div className="container">
                <div>
                  <div>
                    <div>
                      <div className="row">
                        <div
                          className="d-flex flex-row"
                          style={{
                            margin: "0px",
                            padding: "2px",
                            width: "100%",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div
                              className="d-flex flex-row"
                              style={{
                                backgroundColor: "#E6E8E9",
                                color: "#fff",
                              }}
                            >
                              <div
                                className="d-flex flex-row"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  width: "60%",
                                }}
                              >
                                <div>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "35px",
                                      color: "#000000",
                                      padding: "0px 20px",
                                      letterSpacing: "5px",
                                      margin: "2px",
                                    }}
                                  >
                                    EXCAVATION PERMIT
                                  </p>
                                </div>
                              </div>
                              <div style={{ width: "40%" }}>
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    margin: "5px",
                                    padding: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#000",
                                      fontSize: "12px",
                                      margin: "0px 10px",
                                      fontWeight: "bolder",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: "0px 5px",
                                        margin: "0px 10px 0px 0px",
                                      }}
                                    >
                                      Excavation
                                      <br /> Permit #:
                                    </p>
                                  </div>
                                  <div>
                                    <div className="row">
                                      <div className="mr-2">
                                        <input
                                          type="text"
                                          className="header-txtbx"
                                          value={initialsOne}
                                        />
                                        <input
                                          type="text"
                                          className="header-txtbx"
                                          value={initialsTwo}
                                        />
                                        <input
                                          type="text"
                                          className="header-txtbx"
                                          value={initialsThree}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label className="col-12 col-form-label header-lbl">
                                        Initials
                                      </label>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="row">
                                      <div className="d-flex flex-row">
                                        <div className="mr-2">
                                          <input
                                            type=""
                                            className="microsite-txtbxxdate"
                                            value={dateInputOne}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label className="col-12 col-form-label header-lbl">
                                        Date
                                      </label>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="row">
                                      <div>
                                        <input
                                          type="text"
                                          className="header-txtbx"
                                          value={permitsOne}
                                        />
                                        <input
                                          type="text"
                                          className="header-txtbx"
                                          value={permitsTwo}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label className="col-12 col-form-label header-lbl">
                                        #permits
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div
                          className="d-flex flex-row"
                          style={{ margin: "0px", padding: "0px" }}
                        >
                          <div style={{ backgroundColor: "red", width: "50%" }}>
                            <div
                              className="d-flex flex-row"
                              style={{ padding: "5px" }}
                            >
                              <div>
                                <i className="phonerining m-1 p-1"></i>
                              </div>
                              <div>
                                <label
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    padding: "5px",
                                  }}
                                >
                                  Emergency
                                </label>
                              </div>
                              <div
                                className="d-flex flex-row"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  backgroundColor: "#ffffff",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <label
                                  class="col-3"
                                  style={{ fontSize: "9px" }}
                                >
                                  Phone <br />
                                  Number
                                </label>
                                <input
                                  type="text"
                                  value={excaEmergency}
                                  class="col-3"
                                  style={{
                                    border: "none",
                                    backgroundColor: "#DEE5FF",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                />
                                <label
                                  class="col-3"
                                  style={{ fontSize: "9px" }}
                                >
                                  Assembly <br />
                                  Location
                                </label>
                                <input
                                  type="text"
                                  value={al}
                                  class="col-3"
                                  style={{
                                    border: "none",
                                    backgroundColor: "#DEE5FF",
                                    padding: "0px",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              margin: "2px",
                              padding: "2px",
                              width: "50%",
                            }}
                          >
                            <span
                              className="header-icons-cp"
                              style={{ border: "1px solid #F4673C" }}
                            >
                              <img
                                src={Factory}
                                alt="img"
                                className="header-img-cp"
                              />
                            </span>
                            <span
                              className="header-icons-cp"
                              style={{ border: "1px solid #E0A350" }}
                            >
                              <img
                                src={HouseAndLighthouse}
                                alt="img"
                                className="header-img-cp"
                              />
                            </span>
                            <span
                              className="header-icons-cp"
                              style={{ border: "1px solid #34B85F" }}
                            >
                              <img
                                src={Labtubeheadercorepermit}
                                alt="img"
                                className="header-img-cp"
                              />
                            </span>
                            <span
                              className="header-icons-cp"
                              style={{ border: "1px solid #4275BA" }}
                            >
                              <img
                                src={shakinghandsHeadersCorepermit}
                                alt="img"
                                className="header-img-cp"
                              />
                            </span>
                            <span
                              className="header-icons-cp"
                              style={{ border: "1px solid #FFC85B" }}
                            >
                              <img
                                src={ImageheaderCorepermit}
                                alt="img"
                                className="header-img-cp"
                              />
                            </span>
                            <span
                              className="header-icons-cp"
                              style={{ border: "1px solid #793637" }}
                            >
                              <img
                                src={DroneHeaderCorepermit}
                                alt="img"
                                className="header-img-cp"
                              />
                            </span>
                            <span
                              style={{
                                display: "inline-block",
                                border: "none",
                                padding: "3px",
                                cursor: "pointer",
                                marginRight: "10px",
                                width: "35px",
                                height: "35px",
                                marginright: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <img
                                src={CortevaImage}
                                alt="img"
                                style={{ width: "120px", height: "23px" }}
                              />
                            </span>
                          </div>

                          <div
                            style={{
                              margin: "2px",
                              padding: "2px",
                              display: "none",
                            }}
                          >
                            <div>
                              <span
                                style={{
                                  display: "inline-block",
                                  border: "none",
                                  padding: "3px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  width: "35px",
                                  height: "35px",
                                  marginright: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <img
                                  src={CortevaImage}
                                  alt="img"
                                  style={{ width: "170px", height: "37px" }}
                                />
                              </span>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#000",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                PART 1{" "}
                                <span style={{ fontSize: "12px" }}>
                                  Excavation Competent Person completes this
                                  section.
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div>
                                {/* {partOne!=undefined && partOne.list.map((item, index) => (lbl-bg-color-ep */}
                                <div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-2 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Work Performed By:
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaWork}
                                      />
                                    </div>
                                    <label class="col-4 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Underground Reference Drawing Available:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder="Y/N(contact name if Y)"
                                        value={excaUnder}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Reason For Excavation:
                                    </label>
                                    <div className="col-9">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaReason}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Location of
                                      Excavation:(e.g.coordinates,column
                                      numbers,detailed description)
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaLocation}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-1 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Start date:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaStartDate}
                                      />
                                    </div>
                                    <label class="col-2 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Permit Expiration Date:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder=""
                                        value={excaPermit}
                                      />
                                    </div>
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Competent Person Name:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder=""
                                        value={excaCompetent}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-1 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Date:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaDate}
                                      />
                                    </div>
                                    <label class="col-2 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Phone:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder=""
                                        value={excaPhone}
                                      />
                                    </div>
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Number of Personnel entering Excavation:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder=""
                                        value={excaNumber}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* ))} */}
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#000",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                PART 2{" "}
                                <span style={{ fontSize: "12px" }}>
                                  Excavation Competent Person completes this
                                  section.
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div>
                                <div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-10 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Have all Sub-Surface obstacles been
                                      identified? (If "No is checked, a
                                      contingency plan shall be prepared.)
                                    </label>
                                    <div className="col-2 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={title1yes}
                                        />
                                        <label for="" class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={title1no}
                                        />
                                        <label for="" class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-12 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Please indicate which two forms of
                                      evidence will be used to show confidence
                                      that all underground obstacles were found:
                                    </label>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ padding: "5px" }}
                                  >
                                    <div className="col-sm-3 col-12">
                                      <input
                                        type="checkbox"
                                        checked={indicateList0}
                                      />
                                      <label for="">
                                        Underground Reference Drawing
                                      </label>
                                    </div>
                                    <div className="col-sm-3 col-12">
                                      <input
                                        type="checkbox"
                                        checked={indicateList1}
                                      />
                                      <label for="GPRCheckbox">
                                        Ground Penetrating Radar
                                      </label>
                                    </div>
                                    <div className="col-sm-2 col-12">
                                      <input
                                        type="checkbox"
                                        checked={indicateList2}
                                      />
                                      <label for="HECheckbox">
                                        Hydro Excavation
                                      </label>
                                    </div>
                                    <div className="col-sm-2 col-12">
                                      <input
                                        type="checkbox"
                                        checked={indicateList3}
                                      />
                                      <label for="UPCheckbox">
                                        Utility Probing
                                      </label>
                                    </div>
                                    <div className="col-sm-1 col-12">
                                      <input
                                        type="checkbox"
                                        checked={indicateList4}
                                      />
                                      <label for="OCheckbox">Other:</label>
                                      <input
                                        class="col-3 cb-txtbx-border-none"
                                        value={indicateListInput4}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-9 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Explain how the two methods where used to
                                      locate utilities or subsurface obstacles:
                                    </label>
                                    <div className="col-3">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={explainValue}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-10 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Have all utilities been identified and
                                      shared with the excavation crew?
                                      <br />
                                      <br />
                                      (If “No” is checked, the Excavation
                                      Competent Person shall share the results
                                      of the sub-surface investigation with the
                                      excavation crew.)
                                    </label>
                                    <div className="col-2 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        {/* <input type="checkbox" name="response" value="URD" /> */}
                                        <input
                                          type="checkbox"
                                          checked={excaHaveAllYes}
                                        />
                                        <label for="" class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        {/* <input type="checkbox" name="response" value="URD" /> */}
                                        <input
                                          type="checkbox"
                                          checked={excaHaveAllNo}
                                        />
                                        <label for="" class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-12 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Utilities Present in Excavation Area
                                    </label>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ padding: "5px" }}
                                  >
                                    <div className="col-sm-12 col-12">
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList0}
                                      />
                                      &nbsp;
                                      <label for="WaterCheckbox">
                                        Water
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList1}
                                      />
                                      &nbsp;
                                      <label for="ElectricalCheckbox">
                                        Electrical
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList2}
                                      />
                                      &nbsp;
                                      <label for="SCCheckbox">
                                        Steam / Condensate
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList3}
                                      />
                                      &nbsp;
                                      <label for="FLCheckbox">Fire Lines</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList4}
                                      />
                                      &nbsp;
                                      <label for="EQheckbox">Equipment</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList5}
                                      />
                                      &nbsp;
                                      <label for="SEheckbox">Sewer</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList6}
                                      />
                                      &nbsp;
                                      <label for="Gasheckbox">Gas</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList7}
                                      />
                                      &nbsp;
                                      <label for="PRheckbox">Process</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="checkbox"
                                        checked={utilitiesList8}
                                      />
                                      &nbsp;
                                      <label for="TDheckbox">
                                        Telecommunications / Data
                                      </label>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-10 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Can these utilities/services be
                                      de-energized and Isolated? (If "No is
                                      checked, a contingency plan shall be
                                      discussed.)
                                    </label>
                                    <div className="col-2 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={canYes}
                                        />
                                        <label for="" class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={canNo}
                                        />
                                        <label for="" class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-10 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Does this area have any Environmental
                                      concerns by previous activities?
                                    </label>
                                    <div className="col-2 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={doesYes}
                                        />
                                        <label for="" class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={doesNo}
                                        />
                                        <label for="" class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      What precautions/control measures are
                                      needed for the contaminated area?
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={wpVal}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-10 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Is air monitoring required for the
                                      excavation? (If “Yes” follow Corteva’s
                                      PRCS Procedure)
                                    </label>
                                    <div className="col-2 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isAirYes}
                                        />
                                        <label for="" class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isAirNo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-12 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      ‘Special Precautions and Safety
                                      Requirements (check those required on the
                                      excavation site):
                                    </label>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ padding: "5px" }}
                                  >
                                    <div className="col-sm-12 col-12">
                                      <input type="checkbox" checked={sp0} />
                                      &nbsp;
                                      <label for="WaterCheckbox">
                                        Electrical Observer
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input type="checkbox" checked={sp1} />
                                      &nbsp;
                                      <label for="ElectricalCheckbox">
                                        Grounding of tools
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input type="checkbox" checked={sp2} />
                                      &nbsp;
                                      <label for="SCCheckbox">
                                        Testing for Fume or Gas
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input type="checkbox" checked={sp3} />
                                      &nbsp;
                                      <label for="FLCheckbox">
                                        Standby Person
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ padding: "5px" }}
                                  >
                                    <div className="col-sm-12 col-12">
                                      <input type="checkbox" checked={sp4} />
                                      &nbsp;
                                      <label for="WaterCheckbox">
                                        Barricades
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input type="checkbox" checked={sp5} />
                                      &nbsp;
                                      <label for="ElectricalCheckbox">
                                        Explosion Testing
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input type="checkbox" checked={sp6} />
                                      &nbsp;
                                      <label for="SCCheckbox">
                                        Special Clothing
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <input type="checkbox" checked={sp7} />
                                      &nbsp;
                                      <label for="FLCheckbox">
                                        Competent Person (See Reverse Side)
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {/* <input type="checkbox" checked={sp9} /> */}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        class="col-3 cb-txtbx-border-none"
                                        value={spInput9}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-9 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has a Professional Registered Engineer
                                      approved all protection systems for
                                      excavations deeper than 20ft(6m)?
                                    </label>
                                    <div className="col-3 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={hpdYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={hpdNo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={hpdNa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Comments:
                                    </label>
                                    <div className="col-9">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={spComments}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-9 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has a Professional Registered Engineer
                                      approve all protection systems for
                                      excavations that will reduce support of
                                      existing foundations <br /> regardless of
                                      depth?
                                    </label>
                                    <div className="col-3 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={hprYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={hprNo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={hprNa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label
                                      class="col-12 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew"
                                      style={{
                                        backgroundColor: "#DF3A1C",
                                        color: "#fff",
                                      }}
                                    >
                                      Excavation Approval Signatures
                                    </label>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Excavation Competent Person
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaApprovalP1}
                                      />
                                    </div>
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Phone number:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        value={excaApprovalPH1}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Corteva Representative:
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaApprovalP2}
                                      />
                                    </div>
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Phone number:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder=""
                                        value={excaApprovalPH2}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Excavation Permit Issuer
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaApprovalP3}
                                      />
                                    </div>
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Phone number:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        placeholder=""
                                        value={excaApprovalPH3}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Environmental Resource
                                    </label>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={excaApprovalP4}
                                      />
                                    </div>
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Phone number:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        value={excaApprovalPH4}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#000",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                PART 3{" "}
                                <span style={{ fontSize: "12px" }}>
                                  Excavation Competent Person completes this
                                  section.
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div>
                                <div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-6 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Soll Classification*(check one):
                                    </label>
                                    <div className="col-6 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={sollStable}
                                        />
                                        <label class="form-check-label">
                                          Stable rock
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={sollA}
                                        />
                                        <label class="form-check-label">
                                          Type A
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={sollB}
                                        />
                                        <label class="form-check-label">
                                          Type B
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={sollC}
                                        />
                                        <label class="form-check-label">
                                          Type C
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Is the excavation close to
                                      utilities,buildings,footings,pillings,or
                                      sources of vibraton?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          Checked={isTEYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          Checked={isTENo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          Checked={isTENa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Have the owners of utility,service,and
                                      transmission piping been contracted?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHTOYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHTONo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHTONa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has detetction equipment been used to
                                      loacate all underground and/or above
                                      ground inteferences?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHDEYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHDENo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHDENa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has a check been made for previously
                                      disturbed ground?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHCBYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHCBNo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHCBNa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has the adeqacy and availability of all
                                      equipment been checked,including personal
                                      protective quipment,shoring
                                      material,signs,barricades,and machinery?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isTAAYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isTAANo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isTAANa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has a check been made for other
                                      obstructions(e.g.,footing concrete
                                      encasements)?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHACYes}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHACNo}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={isHACNa}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-5 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      If other obstructions have been found,list
                                      them.
                                    </label>
                                    <div className="col-7">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={obstructions}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-2 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Allowable slope:
                                    </label>
                                    <div className="col-10">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={allowable}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-5 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Describe in detail the risk control
                                      measure for the excavation.
                                    </label>
                                    <div className="col-7">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={describe}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      size of Excavation:
                                    </label>
                                    <label class="col-1 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Depth:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={sod}
                                      />
                                    </div>
                                    <label class="col-1 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      width:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        value={sow}
                                      />
                                    </div>
                                    <label class="col-1 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Height:
                                    </label>
                                    <div className="col-2">
                                      <input
                                        className="col-12"
                                        type="text"
                                        value={soh}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Have ground conditons been
                                      checked,particularly after rainfall?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes0}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo0}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa0}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Is air monitoring necessary to check for
                                      possible oxygen deficiency or gaseous
                                      conditions?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes1}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo1}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa1}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has the adequacy of shoring and/or sloping
                                      been checked?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes2}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo2}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa2}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Do vehicular and machinery operation
                                      patterns need to be changed?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes3}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo3}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa3}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Are water removal operations or equipment
                                      needed?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes4}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo4}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa4}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Has the adequacy of portable trench boxes
                                      or trench shields been checked?
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes5}
                                        />
                                        <label class="form-check-label">
                                          YES
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo5}
                                        />
                                        <label class="form-check-label">
                                          NO
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa5}
                                        />
                                        <label class="form-check-label">
                                          N/A
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-8 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      Entrance and exit facilities(one every
                                      25ft.(7.6m)for atrench 4ft.(1.2m)or
                                      deeper):
                                    </label>
                                    <div className="col-4 d-flex flex-row">
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listYes6}
                                        />
                                        <label class="form-check-label">
                                          Stairway
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNo6}
                                        />
                                        <label class="form-check-label">
                                          Ladder
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          checked={listNa6}
                                        />
                                        <label class="form-check-label">
                                          Ramp
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <label
                                      class="col-12 col-form-label lbl-bg-color-ep lbl-bg-color"
                                      style={{
                                        padding: "15px",
                                        margin: "0px",
                                        lineHeight: "1px",
                                        backgroundColor: "#C2C9C8",
                                      }}
                                    >
                                      If the depth of the excavation is
                                      5feet(1.5 meters)or more,check the
                                      applicabl OSHA appendix below:
                                    </label>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={depthB}
                                        />
                                        <label class="form-check-label">
                                          B-Slopping and Benching
                                        </label>
                                      </div>
                                      {/* <div className="col-2">
                <input class="col-12" />
            </div> */}
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={depthC}
                                        />
                                        <label class="form-check-label">
                                          C-Timber Shoring for trenches
                                        </label>
                                      </div>
                                      {/* <div className="col-2">
                <input class="col-12" />
            </div> */}
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={depthD}
                                        />
                                        <label class="form-check-label">
                                          D-Aluminium Hydraulic Shoring for
                                          Trenching
                                        </label>
                                      </div>
                                      {/* <div className="col-1">
                <input class="col-12" />
            </div> */}
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={depthE}
                                        />
                                        <label class="form-check-label">
                                          E-Alternative to Timber Shoring
                                        </label>
                                      </div>
                                      {/* <div className="col-2">
                <input class="col-12" />
            </div> */}
                                      <div class="col-8 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={depthF}
                                        />
                                        <label class="form-check-label">
                                          F-Selection of Protective Systms
                                        </label>
                                      </div>
                                      {/* <div className="col-2">
                <input class="col-12" />
            </div> */}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <label
                                      class="col-12 col-form-label lbl-bg-color-cew lbl-bg-color"
                                      style={{
                                        padding: "15px",
                                        margin: "0px",
                                        lineHeight: "1px",
                                        backgroundColor: "#566060",
                                        color: "#fff",
                                      }}
                                    >
                                      NOTE:Protective Systems for excavations
                                      deeper than 20ft(6meters)deep must be
                                      designedby a registered professional
                                      engineer.
                                    </label>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-7 col-form-label lbl-bg-color-cew lbl-bg-color-ep label-height-cew">
                                      The excavation requires no protective
                                      systems for the following reasons(list):.
                                    </label>
                                    <div className="col-5">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={noteInput}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <label
                                      class="col-4 col-form-label label-height-cew"
                                      style={{
                                        backgroundColor: "#DF3A1C",
                                        padding: "15px",
                                        color: "#fff",
                                      }}
                                    >
                                      competent person(please print your name):
                                    </label>
                                    <div className="col-3">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={part3InputVal}
                                        style={{ height: "40px" }}
                                      />
                                    </div>
                                    <label
                                      class="col-2 col-form-label label-height-cew"
                                      style={{
                                        backgroundColor: "#DF3A1C",
                                        padding: "15px",
                                        color: "#fff",
                                      }}
                                    >
                                      Date:
                                    </label>
                                    <div className="col-3">
                                      <input
                                        className="col-12"
                                        type="text"
                                        value={part3Date}
                                        style={{ height: "40px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #2373BE",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              className="d-flex justify-content-between  align-items-center"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#2373BE",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>v0.0</p>
                              <p style={{ margin: "5px" }}>
                                <img
                                  src={cortevalogo}
                                  alt="img"
                                  style={{ width: "190px", height: "40px" }}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Part 2 */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            {/* ... End of Footer...  */}
          </div>
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div>
      {isLoading && (
        <Loader
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default ExcavationPermit_Web;
