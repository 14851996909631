import React, { useState, useEffect, useRef } from "react";
//import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  coreGeneralCheck,
  GeneralInfoConst,
  HotWorkInfoConst,
  RequiredPreConstant,
  VerifyConst,
  AirMonitoringConst,
  FireWatchConst,
  SecondaryConst,
} from "../Utilities/hotWorkPermit2-JSON";
import GeneralInformation from "../components/hotPermitMobileView/GeneralInformation";
import HotWorkInformation from "../components/hotPermitMobileView/HotWorkInformation";
import RequiredPrecautions from "../components/hotPermitMobileView/RequiredPrecautions";
import VerifyPermitIssuer from "../components/hotPermitMobileView/VerifyPermitIssuer";
import AirMonitoring from "../components/hotPermitMobileView/AirMonitoring";
import FireWatch from "../components/hotPermitMobileView/FireWatch";
import SecondarySignPermitIssuer from "../components/hotPermitMobileView/SecondarySignPermitIssuer";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import {
  USER_PROFILE,
  retrieveData,
  COREPERMIT_MOBILE_NUMBER,
  checkForEmptyValue,
} from "../Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import Accordion from "../Accordation/Accordian";
import axios from "axios";
import CorePermitInputFields from "../components/hotPermitMobileView/corePermitInputFields";
//loader img
import Loader_Bulk from "../Utilities/Loader_Bulk";

//Approve
import Approved from "../images/Approved.png";

const HotWorkPermit2Main = (props) => {
  //Components

  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [genaralInformation, setgenaralInformation] =
    useState(GeneralInfoConst);
  const [requiredPrecautions, setRequiredPrecautions] =
    useState(RequiredPreConstant);
  const [verify, setVerify] = useState(VerifyConst);
  const [airMonitoring, setAirMonitoring] = useState(AirMonitoringConst);
  const [fireWatch, setFireWatch] = useState(FireWatchConst);
  const [secondarySign, setSecondarySign] = useState(SecondaryConst);
  const [hotWorkInformation, setHotWorkInformation] =
    useState(HotWorkInfoConst);
  const [activeIndex, setActiveIndex] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  //loader
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  //Reject
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [serverRecordId, setServerRecordId] = useState(null);
  const addtionalpermitstatus = searchParams.get("permitstatus");
  const [generalChecked, setGeneralChecked] = useState("");
  const [hazardChecked, setHazardChecked] = useState("");

  // ToggleValidation
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [generalinfoToggleOn, setGeneralinfoToggleOn] = useState(true);
  const [hotworkToggleOn, setHotworkToggleOn] = useState(true);
  const [requiredToggleOn, setRequiredToggleOn] = useState(true);
  const [verifyPermitToggleOn, setVerifyPermitToggleOn] = useState(true);
  const [airMonitoringToggleOn, setAirMonitoringToggleOn] = useState(true);
  const [fireWatchToggleOn, setFireWatchToggleOn] = useState(true);
  const [secoundoryToggleOn, setSecoundoryToggleOn] = useState(true);
  const [areasToggleOn, setAreasToggleOn] = useState(true);




  const toggleItem = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // api
  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  //Toggle
  const handleToggleItems = () => {
    setEmergencyToggleOn(!emergencyToggleOn)
  }

  const handleGeneralInfoToggleItems = () => {
    setGeneralinfoToggleOn(!generalinfoToggleOn);
  }
  const handleHotWorkToggleItems = () => {
    setHotworkToggleOn(!hotworkToggleOn)
  }

  const handleRequiredPrecautionsToggleItems = () => {
    setRequiredToggleOn(!requiredToggleOn)
  }

  const handleVerifyPermitToggleItems = () => {
    setVerifyPermitToggleOn(!verifyPermitToggleOn)
  }
  const handleAirMonitoringToggleItems = () => {
    setAirMonitoringToggleOn(!airMonitoringToggleOn)
  }

  const handleFireWatchToggleItems = () => {
    setFireWatchToggleOn(!fireWatchToggleOn)
  }
  const handleSecondorySignatureToggleItems = () => {
    setSecoundoryToggleOn(!secoundoryToggleOn)
  }


  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    const IdFromURLwithEDit = searchParams.get("corePermitId");
    var IdFromURL;
    var isEdit = "true";
    var additional_permit_id;
    if (IdFromURLwithEDit == null) {
    } else {
      IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
      isEdit = IdFromURLwithEDit.toString().split("/")[1];
      additional_permit_id = IdFromURLwithEDit.toString().split("/")[2];
    }

    setIsEdit(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id, additional_permit_id) => {
    try {
      // First, retrieve data from sessionStorage if needed
      const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
      // Make an API request using Axios to get permits by core permit ID
      const mobileNumber = sessionStorage.getItem("COREPERMIT_MOBILE_NUMBER");
      const name = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      const PermitID = sessionStorage.getItem("CorePermitM2_PERMITID");
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: mobileNumber,
        name: name,
        PermitID: addtionalpermitstatus == "Submit" ? PermitID : id, // id
      });
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);
      if (addtionalpermitstatus != "Submit") {
        if (response.data && response.data.response) {
          const elevated =
            response?.data?.response?.general_hot_Work_permit || [];
          // const dataLast = elevated[elevated.length - 1]?.permitObject || null
          const dataLast =
            elevated.find((item) => item.permitID === additional_permit_id)
              ?.permitObject || null;
          console.log("filteredArray checking", JSON.stringify(dataLast));

          if (dataLast) {
            console.log("dataLast", dataLast);
            console.log(response.serverRecordId);
            setGeneralChecked(dataLast.genaralArea);
            setHazardChecked(dataLast.hazardousArea);
            setPermitInitials(
              dataLast?.genaralInformation.permitInitials
                ? dataLast?.genaralInformation.permitInitials
                : permitInitials
            );
            setgenaralInformation(
              dataLast?.genaralInformation
                ? dataLast?.genaralInformation
                : genaralInformation
            );
            setRequiredPrecautions(
              dataLast?.genaralInformation.requiredPrecautions
                ? dataLast?.genaralInformation.requiredPrecautions
                : requiredPrecautions
            );
            setVerify(
              dataLast?.genaralInformation?.verify
                ? dataLast?.genaralInformation?.verify
                : verify
            );
            setAirMonitoring(
              dataLast?.genaralInformation?.airMonitoring
                ? dataLast?.genaralInformation?.airMonitoring
                : airMonitoring
            );
            setFireWatch(
              dataLast?.genaralInformation?.fireWatch
                ? dataLast?.genaralInformation?.fireWatch
                : fireWatch
            );
            setSecondarySign(
              dataLast?.genaralInformation?.secondarySign
                ? dataLast?.genaralInformation?.secondarySign
                : secondarySign
            );
            setHotWorkInformation(
              dataLast?.genaralInformation?.hotWorkInformation
                ? dataLast?.genaralInformation?.hotWorkInformation
                : hotWorkInformation
            );
            // setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
            //Toggle Values 
            setGeneralinfoToggleOn(
              dataLast && genaralInformation
                ? generalinfoToggleOn
                  ? dataLast?.genaralInformation?.generalinfoToggleOn
                  : generalinfoToggleOn
                : generalinfoToggleOn
            );

            setHotworkToggleOn(
              dataLast && genaralInformation
                ? hotworkToggleOn
                  ? dataLast?.genaralInformation?.hotworkToggleOn
                  : hotworkToggleOn
                : hotworkToggleOn
            );

            setRequiredToggleOn(
              dataLast && genaralInformation
                ? requiredToggleOn
                  ? dataLast?.genaralInformation?.requiredToggleOn
                  : requiredToggleOn
                : requiredToggleOn
            );

            setVerifyPermitToggleOn(
              dataLast && genaralInformation
                ? verifyPermitToggleOn
                  ? dataLast?.genaralInformation?.verifyPermitToggleOn
                  : verifyPermitToggleOn
                : verifyPermitToggleOn
            );
            setAirMonitoringToggleOn(
              dataLast && genaralInformation
                ? airMonitoringToggleOn
                  ? dataLast?.genaralInformation?.airMonitoringToggleOn
                  : airMonitoringToggleOn
                : airMonitoringToggleOn
            );

            setFireWatchToggleOn(
              dataLast && genaralInformation
                ? fireWatchToggleOn
                  ? dataLast?.genaralInformation?.fireWatchToggleOn
                  : fireWatchToggleOn
                : fireWatchToggleOn
            );

            setSecoundoryToggleOn(
              dataLast && genaralInformation
                ? secoundoryToggleOn
                  ? dataLast?.genaralInformation?.secoundoryToggleOn
                  : secoundoryToggleOn
                : secoundoryToggleOn
            );

            setServerRecordId(
              elevated.find((item) => item.permitID === additional_permit_id)
                ?.permitID || "0"
            );
          }
        } else {
          // Handle the case where the response does not contain the expected data
          console.log("Response data is missing or invalid.");
        }
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
  };


  const handleRejectOk = async (e) => {
    console.log(e, "handleRejectOk");
    const RejectData = {
      permitName: "cranePermit",
      corePermitID: "5",
      additionalPermitID: "7",
      status: "Rejecte",
      remarks: remarks,
    };
    const response = await axios.post(ApproveRejectPermit, RejectData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
    }
    setShowReject(false);
    console.log(status);
  };

  const resetForm = () => {
    setPermitInitials(corePermitInitialsConst);
    setgenaralInformation(GeneralInfoConst);
    setRequiredPrecautions(RequiredPreConstant);
    setVerify(VerifyConst);
    setAirMonitoring(AirMonitoringConst);
    setFireWatch(FireWatchConst);
    setSecondarySign(SecondaryConst);
    setHotWorkInformation(HotWorkInfoConst);
    setFinalSubmit(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // precautions
    const checkedRequiredPrecautionsyes = requiredPrecautions.list.filter(
      (item) => item.cbYes === "true"
    );
    const checkedRequiredPrecautionsNa = requiredPrecautions.list.filter(
      (item) => item.cbNA === "true"
    );

    const isRequiredPrecationsCheckedyes =
      checkedRequiredPrecautionsyes.length >= 1
        ? "true"
        : checkedRequiredPrecautionsyes.length === 0 &&
          checkedRequiredPrecautionsNa.length === 0
          ? ""
          : "false";

    const isRequiredPrecationsCheckedNa =
      checkedRequiredPrecautionsNa.length >= 1
        ? "true"
        : checkedRequiredPrecautionsyes.length === 0 &&
          checkedRequiredPrecautionsNa.length === 0
          ? ""
          : "false";

    // verify permit issuer
    const checkedVerifyyes = verify.list.filter(
      (item) => item.cbYes === "true"
    );
    const checkedVerifyNa = verify.list.filter((item) => item.cbNA === "true");

    const ispermitissuerCheckedyes =
      checkedVerifyyes.length >= 1
        ? "true"
        : checkedVerifyyes.length === 0 && checkedVerifyNa.length === 0
          ? ""
          : "false";

    const ispermitissuerCheckedNa =
      checkedVerifyNa.length >= 1
        ? "true"
        : checkedVerifyyes.length === 0 && checkedVerifyNa.length === 0
          ? ""
          : "false";

    const airMonitoringList = airMonitoring.list.filter(
      (item) => item.cbValue === "true"
    );
    const listChecked = airMonitoringList.length >= 1 ? "true" : "";

    const fireWatchList = fireWatch.fireWatch.filter(
      (item) => item.cbValue === "true"
    );
    const fireWatchListChecked = fireWatchList.length >= 1 ? "true" : "";

    var valuesToCheck = [];
    if (areasToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        areas: {
          genaralArea: generalChecked,
          hazardousArea: hazardChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (generalinfoToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        genaralInformation: {
          phoneNumber: genaralInformation.phoneNumber,
          assemblyLocation: genaralInformation.assemblyLocation,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (hotworkToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        hotWorkInformation: {
          hotWorkLocation: hotWorkInformation.hotWorkLocation,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (requiredToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        requiredPrecautions: {
          PrecautionscbYes: isRequiredPrecationsCheckedyes,
          PrecautionscbNA: isRequiredPrecationsCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (verifyPermitToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        verify: {
          verifypermitissuecbYes: ispermitissuerCheckedyes,
          verifypermitissuecbNA: ispermitissuerCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (airMonitoringToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        airMonitoring: {
          list: listChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (fireWatchToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        fireWatch: {
          fireWatch: fireWatchListChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (secoundoryToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        secondarySign: {
          name: secondarySign.name,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    console.log("valuesToCheck" + valuesToCheck);
    // debugger;
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {
        case "areas":
          // Handle case 1
          alert("Fill the Areas Data");
          allCasesValid = false;
          break;



        case "genaralInformation":
          if (generalinfoToggleOn) {
            alert("Fill the GenaralInformation Data");
            allCasesValid = false;
          }
          break;



        case "hotWorkInformation":
          if (hotworkToggleOn) {
            alert("Please Fill The HotWorkInformation Data");
            allCasesValid = false;
          }
          break;


        case "requiredPrecautions":
          if (requiredToggleOn) {
            alert("Please Fill The RequiredPrecautions Data");
            allCasesValid = false;
          }
          break;



        case "verify":
          if (verifyPermitToggleOn) {
            alert("Please Fill The verify Data");
            allCasesValid = false;
          }
          break;



        case "airMonitoring":
          if (airMonitoringToggleOn) {
            alert("Please Fill The AirMonitoring Data");
            allCasesValid = false;
          }
          break;





        case "fireWatch":
          if (fireWatchToggleOn) {
            alert("Please Fill The FireWatch Data");
            allCasesValid = false;
          }
          break;


        case "secondarySign":
          if (secoundoryToggleOn) {
            alert("Please Fill The SecondarySign Data");
            allCasesValid = false;
          }
          break;

      }
    }

    if (allCasesValid) {
      const mobileNumber = sessionStorage.getItem("COREPERMIT_MOBILE_NUMBER");
      const name = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      const id = sessionStorage.getItem("CorePermitM2_PERMITID");
      const userProfileData = {
        id: serverRecordId ? serverRecordId : "0",
        mobileNumber: mobileNumber,
        name: name,
        corePermitID: id,
        additionalPermitName: "general_hot_Work_permit",
      };
      const finalJson = {
        request: {
          genaralArea: generalChecked,
          hazardousArea: hazardChecked,
          genaralInformation: {
            ...genaralInformation,
            requiredPrecautions,
            verify,
            airMonitoring,
            fireWatch,
            hotWorkInformation,
            permitInitials,
            secondarySign,
            generalinfoToggleOn,
            hotworkToggleOn,
            requiredToggleOn,
            verifyPermitToggleOn,
            airMonitoringToggleOn,
            fireWatchToggleOn,
            secoundoryToggleOn,
          },
        },
        ...userProfileData,
      };
      try {
        setIsLoading(true);
        console.log(finalJson, "finalJson***");
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log(response.status, "sdmnhba");
        console.log("response", response);
        if (response.status == 200 && response) {
          console.log("success");
          setFinalSubmit(true);
          setShowApproved(true);
          setIsLoading(false);
          resetForm(); // Reset form fields
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}

        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          {finalSubmit ? (
            <>
              <h2>Form Submitted Successfully!!!</h2>
            </>
          ) : (
            <div id="content">
              {/* 
                        <Header /> */}
              <div
                className="row"
                style={{ backgroundColor: "blue", padding: "7px" }}
              >
                <div className="col-md-3 col-12">
                  <img
                    src={arrowIcon}
                    onClick={handleBackButtonClick}
                    alt="Back"
                    style={{
                      width: 40,
                      height: 30,
                      padding: 5,
                      verticalAlign: "middle",
                    }}
                  />
                  <span
                    style={{
                      fontSize: 20,
                      color: "white",
                      marginLeft: 10,
                      marginTop: 12,
                    }}
                  >
                    HotWork Permit
                  </span>
                </div>
              </div>
              <div>
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className={`d-flex justify-content-between  align-items-center  pl-0`}
                      style={{ backgroundColor: "#cccccc", color: "#000000" }}
                    >
                      <div className="d-flex flex-row">
                        <div style={{ backgroundColor: "#FFE143" }}>
                          <div
                            class="form-check form-check-inline d-flex align-items-center"
                            style={{
                              height: "0.5px",
                              backgroundColor: "#FBE517",
                              paddingRight: "11px",
                              marginLeft: "5px",
                            }}
                          // handleToggle={handleToggleItems}

                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="generalarea"
                              checked={generalChecked}
                              onChange={(e) => {
                                const generalCheckedValue = e.target.checked;
                                if (generalCheckedValue === false) {
                                  setGeneralChecked("");
                                  setHazardChecked("");
                                } else {
                                  setGeneralChecked(generalCheckedValue);
                                  setHazardChecked(false);
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              style={{ fontWeight: "bold" }}
                            >
                              GENERAL AREA
                            </label>
                          </div>
                        </div>
                        <div style={{ backgroundColor: "#ED8754" }}>
                          <div
                            class="form-check form-check-inline d-flex align-items-center"
                            style={{
                              height: "0.5px",
                              backgroundColor: "#ED8754",
                              paddingRight: "11px",
                              marginLeft: "5px",
                            }}
                          // handleToggle={handleToggleItems}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="hazardarea"
                              name="area"
                              checked={hazardChecked}
                              onChange={(e) => {
                                const hazardCheckedValue = e.target.checked;
                                if (hazardCheckedValue === false) {
                                  setHazardChecked("");
                                  setGeneralChecked("");
                                } else {
                                  setHazardChecked(hazardCheckedValue);
                                  setGeneralChecked(false);
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              style={{ fontWeight: "bold" }}
                            >
                              HAZARD AREA
                            </label>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div>
                    <form className="HotWorkPermit-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        setPermitInitials={setPermitInitials}
                        editable={false}
                      />

                      <Accordion
                        title="GENERAL INFORMATION"
                        bgColor="#34B85F"
                        handleToggle={handleGeneralInfoToggleItems}
                        isToggled={generalinfoToggleOn}
                        content={
                          <GeneralInformation
                            data={genaralInformation}
                            generalinfoToggleOn={generalinfoToggleOn}
                            setgenaralInformation={setgenaralInformation}
                            onDataChange={(data) => {
                              setgenaralInformation(data);
                            }}
                          />
                        }
                        bordercolor="#34B85F"
                        showToggle={true}
                      />
                      <Accordion
                        title="HOT WORK INFORMATION"
                        bgColor="#34B85F"
                        handleToggle={handleHotWorkToggleItems}
                        isToggled={hotworkToggleOn}
                        content={
                          <HotWorkInformation
                            data={hotWorkInformation}
                            hotworkToggleOn={hotworkToggleOn}
                            setHotWorkInformation={setHotWorkInformation}
                            onDataChange={(data) => {
                              setHotWorkInformation(data);
                            }}
                          />
                        }
                        bordercolor="#34B85F"
                        showToggle={true}
                      />
                      <Accordion
                        title="REQUIRED PRECAUTIONS"
                        bgColor="#184DA9"
                        handleToggle={handleRequiredPrecautionsToggleItems}
                        isToggled={requiredToggleOn}
                        content={
                          <RequiredPrecautions
                            data={requiredPrecautions}
                            requiredToggleOn={requiredToggleOn}
                            setRequiredPrecautions={setRequiredPrecautions}
                            onDataChange={(data) => {
                              setRequiredPrecautions(data);
                            }}
                          />
                        }
                        bordercolor="#184DA9"
                        showToggle={true}
                      />
                      <Accordion
                        title={
                          <span>
                            VERIFY:PERMIT ISSUER
                            <span className="required">
                              {" "}
                              *
                              <span
                                className="text-white"
                                style={{ fontSize: "12px" }}
                              >
                                {" "}
                                (Select Required Fields)
                              </span>
                            </span>
                          </span>
                        }
                        bgColor="#2C2E35"
                        handleToggle={handleVerifyPermitToggleItems}
                        isToggled={verifyPermitToggleOn}
                        content={
                          <VerifyPermitIssuer
                            data={verify}
                            verifyPermitToggleOn={verifyPermitToggleOn}
                            setVerify={setVerify}
                            onDataChange={(data) => {
                              setVerify(data);
                            }}
                          />
                        }
                        bordercolor="#2C2E35"
                        showToggle={true}

                      />

                      <Accordion
                        title="AIR MONITORING"
                        bgColor="#34B85F"
                        handleToggle={handleAirMonitoringToggleItems}
                        isToggled={airMonitoringToggleOn}
                        content={
                          <AirMonitoring
                            data={airMonitoring}
                            airMonitoringToggleOn={airMonitoringToggleOn}
                            setAirMonitoring={setAirMonitoring}
                            onDataChange={(data) => {
                              setAirMonitoring(data);
                            }}
                          />
                        }
                        bordercolor="#34B85F"
                        showToggle={true}

                      />
                      <Accordion
                        title="FIRE WATCH"
                        bgColor="#DF3A1C"
                        handleToggle={handleFireWatchToggleItems}
                        isToggled={fireWatchToggleOn}
                        content={
                          <FireWatch
                            data={fireWatch}
                            fireWatchToggleOn={!fireWatchToggleOn}
                            setFireWatch={setFireWatch}
                            onDataChange={(data) => {
                              setFireWatch(data);
                            }}
                          />
                        }
                        bordercolor="#DF3A1C"
                        showToggle={true}

                      />
                      <Accordion
                        title="SECONDARY SIGNATURE PERMIT ISSUER"
                        bgColor="#FF7E51"
                        handleToggle={handleSecondorySignatureToggleItems}
                        isToggled={secoundoryToggleOn}
                        content={
                          <SecondarySignPermitIssuer
                            data={secondarySign}
                            secoundoryToggleOn={secoundoryToggleOn}
                            setSecondarySign={setSecondarySign}
                            onDataChange={(data) => {
                              setSecondarySign(data);
                            }}
                          />
                        }
                        bordercolor="#FF7E51"
                        showToggle={true}

                      />

                      {/* </Accordion> */}

                      {/* <div style={{ marginTop: "20px" }}> */}
                      {isEdit.toString() == "true" && (
                        <button
                          type="button"
                          className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                          style={{
                            height: "50px",
                            backgroundColor: "#007AFF",
                            color: "white",
                            border: "none",
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}

                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Approved}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>HotWork Permit sent for Approval</h5>
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      handleBackButtonClick();
                                      setShowModal(false);
                                    }}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "15vh",
                              }}
                            >
                              <h3> Are you sure want to Reject?</h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Reason:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={handleRejectOk}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </form>
                    {/* </div>
                                            </div> */}
                    {/* </div>
                                    </div> */}
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          )}
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */}
        {/* <Logout /> */}
      </div>
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default HotWorkPermit2Main;
