import React, { useState, useEffect, useRef } from "react";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./Logout";
import Card from "react-bootstrap/Card";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserMenuControl, UserMenuControlSubmit, getMainAssets, getRoleMaster, getUserMaster, getroleAssetMapping, setroleAssetMapping } from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import axios from "axios";
import Loader from '../Utilities/Loader';
import { Button, Modal } from 'react-bootstrap';
import { t } from "i18next";
import ReactSelect from 'react-select';


const UserAssetMapping = ({ }) => {
  const [submitResponse, setsubmitResponse] = useState('');
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleData, setroleData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [maindropdowndata, setMaindropdowndata] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [mainAssert, setMainAssert] = useState('');
  const [userId, setUserId] = useState('');
  const [errorRole, setErrorRole] = useState('');
  const [errorMain, setErrorMain] = useState('');
  const [showMsg, setShowMsg] = useState('false');
  const [showsubmit, setShowsubmit] = useState(false);
  const [getUserData, setGetUserData] = useState([])

  const handleSelection = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleMainAssertChange = (e) => {
    setMainAssert(e.target.value);

  };

  const handleUserChange = (selectedOption) => {
    setUserId(selectedOption ? selectedOption.value : '');
  };

  const userOptions = getUserData
    ? getUserData
      .filter((item) => item.status === "1")
      .map((item) => ({ value: item.id, label: item.firstName }))
    : [];

  const handleresponse = () => {
    setShowResponsePopup(false);
    window.location.reload();
  }

  const handleRoleDopdowndata = async () => {
    const apiUrl = getRoleMaster;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    axios.get(apiUrl, { headers })
      .then((response) => {
        console.log("responserOLE", response);
        setroleData(response.data.response.roleMaster)

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  };

  const getUserMasterData = async () => {
    const apiUrl = getUserMaster;
    const headers = await GetApiHeaders();
    try {
      const response = await axios.get(apiUrl, { headers })
      setGetUserData(response.data.response.UserMaster)

    } catch (error) {

    }
  }



  const fetchMainDropdownData = async (roleId) => {

    const apiUrl = getMainAssets;
    const headers = await GetApiHeaders();
    setIsLoading(false);

    axios.get(apiUrl, { headers })
      .then((response) => {
        console.log("response", response);
        setMaindropdowndata(response.data.response.MainAssetMaster)
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }
  useEffect(() => {
    fetchMainDropdownData()
    getUserMasterData()
  }, []);

  useEffect(() => {
    fetchCardData();
  }, [userId, mainAssert])

  const fetchCardData = async () => {
    const apiUrl = UserMenuControl;
    const headers = await GetApiHeaders();
    try {
      if (userId !== '' && mainAssert !== '') {
        const dataToSend = {
          "id": parseInt(userId),
          "mainAssetId": mainAssert
        };
        setIsLoading(true);
        const response = await axios.post(apiUrl, dataToSend, { headers })
        console.log(response, "card data")
        setCardsData(response.data.response.roleAssetMapping)
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error)
    }

  }
  // const handleSubmit = async () => { 
  //   var array = []
  //   for (let index = 0; index < cardsData.length; index++) {
  //     for (let sIndex = 0; sIndex < cardsData[index].subAssets.length; sIndex++) {
  //       var obj = {
  //         assetId: cardsData[index].assetId,
  //         subAssetId: cardsData[index].subAssets[sIndex].subAssetId,
  //         isEditable: cardsData[index].subAssets[sIndex].editable,
  //         isViewStatus: cardsData[index].subAssets[sIndex].viewStatus
  //       }
  //       array.push(obj)
  //     }
  //   }
  //   var jsonData = {
  //     userId: parseInt(userId),
  //     mainAssetId: parseInt(mainAssert),
  //     assets: array
  //   }
  //   try {
  //     if (jsonData != null && jsonData != "") {
  //       const apiUrl = UserMenuControlSubmit;
  //       const headers = await GetApiHeaders(); 
  //       setIsLoading(true);
  //       const response = await axios.post(apiUrl, jsonData, { headers });
  //       setsubmitResponse(response.data.response);
  //       setShowResponsePopup(true);
  //       setIsLoading(false); 
  //       console.log('Response:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleSubmit = async () => {
    const array = [];
    for (let index = 0; index < cardsData.length; index++) {
      for (let sIndex = 0; sIndex < cardsData[index].subAssets.length; sIndex++) {
        const obj = {
          assetId: cardsData[index].assetId,
          subAssetId: cardsData[index].subAssets[sIndex].subAssetId,
          isEditable: cardsData[index].subAssets[sIndex].editable,
          isViewStatus: cardsData[index].subAssets[sIndex].viewStatus,
        };
        array.push(obj);
      }
    }

    const jsonData = {
      userId: parseInt(userId),
      mainAssetId: parseInt(mainAssert),
      assets: array,
    };

    try {
      if (jsonData) {
        const apiUrl = UserMenuControlSubmit;
        const headers = await GetApiHeaders();
        setIsLoading(true);
        const response = await axios.post(apiUrl, jsonData, { headers });
        console.log("Response:", response.data);

        if (response.data.statusCode === "200" && response.data.status === "Success") {
          setsubmitResponse(response.data.message || "Submission successful!");
        } else {
          setsubmitResponse("Something went wrong! Please try again.");
        }
        setShowResponsePopup(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setsubmitResponse("Error occurred during submission. Please try again later.");
      setShowResponsePopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (cardIndex, subAssetIndex, type) => {
    const updatedCardsData = [...cardsData];
    const subAsset = updatedCardsData[cardIndex].subAssets[subAssetIndex];
    console.log("subAsset::", JSON.stringify(subAsset));
    subAsset[type] = !subAsset[type];
    console.log("!subAsset::", JSON.stringify(subAsset));
    setCardsData(updatedCardsData);
    setShowsubmit(true);
  };

  const formatCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text ml-3">
                          {t('User_Asset_Mapping')}
                        </h6>
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-md-3 col-6'>
                        <label for="InputName" className="form-label text-sm font-weight-600 mt-1" >
                          {t('Main_Asset')}
                        </label>
                        <div className="input-group input-group w-100">
                          <select id="dropdown" type="dropdown" className="form-control " value={mainAssert} onChange={handleMainAssertChange}>
                            <option value=""> {t('Main_Asset_Master')}</option>
                            {maindropdowndata != undefined && maindropdowndata.map((item) => (
                              item.status === '1' ? (<option key={item.id} value={item.id}>{item.mainAssetName}</option>) : ''
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-md-3 col-6'>
                        <label htmlFor="InputName" className="form-label text-sm font-weight-600 mt-1">
                          {t('User_Master')}
                        </label>
                        <div className="input-group input-group w-100">
                          <ReactSelect
                            id="dropdown"
                            className="w-100"
                            options={userOptions}
                            value={userOptions.find(option => option.value === userId) || null}
                            onChange={handleUserChange}
                            placeholder={t('Select_User')}
                            isClearable
                            isSearchable
                          />
                        </div>
                      </div>
                    </div>



                    <div className="d-flex flex-row flex-wrap">
                      {(cardsData != undefined && cardsData.length > 0) && cardsData.map((card, cardIndex) => (
                        <div className="row mr-2 ml-2">
                          <div className="col-lg-6 col-md-6 p-2">
                            <div className="card mt-3" style={{
                              width: "33rem", height: "18rem", overflowY: "auto",
                            }}>
                              <div className="card-header"
                                style={{ backgroundColor: "#1580F3", color: "white", fontWeight: 500 }}>
                                {formatCamelCase(card.assetName)} {/*Dash board */}
                                <span className="edit mr-1">{t('Edit')}</span>
                                {/* <span className="view mr-2">View</span> */}
                              </div>
                              {card.subAssets.map((subAsset, subAssetIndex) => (
                                <div className="card-body">
                                  <div className="mt-2 ml-2">
                                    <div>
                                      <label className="label">{formatCamelCase(subAsset.subAssetName)}</label>
                                      <input type="checkbox" className="checkbox"
                                        checked={subAsset.editable}
                                        onChange={() =>
                                          handleCheckboxChange(cardIndex, subAssetIndex, "editable")
                                        } />
                                      {/* <input type="checkbox" className="checkbox"
                                        checked={subAsset.viewStatus}
                                        onChange={() =>
                                          handleCheckboxChange(cardIndex, subAssetIndex, "viewStatus")
                                        }
                                      /> */}
                                    </div>
                                  </div>

                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {showsubmit && <Button variant="primary" sstyle={{ display: 'flex', gap: '10px' }} onClick={handleSubmit}>{t('Submit')}</Button>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div>

              <Modal show={showResponsePopup} centered backdrop="static" keyboard={false} size="ml">
                <Modal.Header className="border-0 d-flex justify-content-center">
                  <Modal.Title>  <span className='modal_title'>{t('User_Asset_Mapping')}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pl-md-5 pr-md-5 pt-0' >

                  <div className='d-flex justify-content-center mb-3 font-weight-600'>

                    {showMsg ? (

                      <p style={{ color: 'green' }} >{submitResponse}</p>

                    ) : null}

                  </div>
                  <div className="d-flex justify-content-center">

                    <Button variant="primary" className="d-flex justify-content-center cancel_button" onClick={handleresponse} >
                      {t('Okay')}
                    </Button>&nbsp;&nbsp;
                  </div>
                </Modal.Body>
              </Modal>

            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      {/* {isLoading && <Loader loading={isLoading} message={"Fetching Data. Please Wait..!"} />} */}
      <Logout />

    </body>
  );
};

export default UserAssetMapping;
