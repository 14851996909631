import React, { useState, useEffect, useRef } from "react";
import { USER_PROFILE, retrieveData } from "../Utils";
import { Link, useNavigate } from "react-router-dom";
// import Logo from '../images/corteva-logo.png';
import Logo from "../images/MCRC_logo.png";
import ArrowIcon from "../images/arrowIcon.png";
import MCRCResearchOperations from "../images/MCRCResearchOperations.png";
import Menu from "../images/Menu.png";
import CentralLogo from "../images/CentralLogo.png";
import FarmLogo from "../images/FarmLogo.png";
import Tracking from "../images/CentralLogo.png";
import UserMaster from "../images/userMaster.png";
import FarmlogoSidemenu from "../images/FarmSidemenuLogo.png";
import MasterIcon from "../images/masterIcon.png";
//import reportsmenuicon from "../images/reportsmenuicon.jpg";
import reportsmenuicon from "../images/reportsmenuicon.png";
import SidemenuBg from "../images/sidemenuBg.png";
import ManPower from "../images/manpoverlogo.png";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { hover } from "@testing-library/user-event/dist/hover";
import { TextCenter } from "react-bootstrap-icons";

function SideMenu({ item }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isSubCollapsed, setIsSubCollapsed] = useState(true);
  const [isSubCollapsedItem, setIsCollapsedItem] = useState(true);
  const [selectedSubItem, setSelectedSubItem] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const roleTypeName = sessionStorage.getItem("roleTypeName");
  useEffect(() => {
    getSessionData();
    return () => { };
  }, []);

  const getSessionData = async () => {
    const dataFromStore = await retrieveData(USER_PROFILE);
    if (dataFromStore.roleTypeName == "Admin") {
      setIsAdmin(true);
    }
  };
  // for sidebar And Navbar
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleSubCollapse = () => {
    setIsSubCollapsed(!isSubCollapsed);
    // Implement logic to toggle the subchild collapse state here
  };
  const toggleSubCollapsedItem = () => {
    setSelectedSubItem(!isSubCollapsedItem);
  };

  let itemClicked = sessionStorage.getItem("itemClicked");
  console.log(itemClicked);

  const handleSubItemClick = (subItem) => {
    setSelectedSubItem(subItem);
  };
  const renderSubItem = (subItem, title) => {
    const isSelected = selectedSubItem === subItem;
    const subItemStyle = isSelected ? { backgroundColor: "yellow" } : {};

    return (
      <Link
        to={subItem}
        className={`nav-link collapsed ${isSelected ? "active-link" : ""}`}
        onClick={() => handleSubItemClick(subItem)}
        style={subItemStyle}
      >
        <div>
          <span className="arrow">{isSelected ? "→" : "→"}</span>
          {title}
        </div>
      </Link>
    );
  };
  const determinePath = (item) => {
    const dashboard = sessionStorage.getItem("itemClicked");
    if (dashboard === "centralStore") {
      return '/CentralStoreDashboard';
    } else if (dashboard === "rmTracking") {
      return '/RMDashboard';
    } else if (dashboard === "farmOperations") {
      return '/FarmOperationDashboard';
    }
    return '/defaultPath'; // Fallback path if none of the conditions match
  };

  const path = determinePath(item);
  return (
    <>
      <ul className={style} id="accordionSidebar">
        {/*  <!-- Sidebar - Brand --> */}
        <div
          className="sidebar-brand ml-3 mr-1 d-flex align-items-center justify-content-center"
          href="#"
        >
          <div className="sidebar-brand-icon rotate-n-15"></div>

          <div
            className="logos sidebar-brand-text mt-1 "
            style={{ backgroundColor: "white" }}
          >
            <img
              src={Logo}
              alt="MCRC_logo"
              width="215px"
              height="65px"
              quality={100}
              layout="responsive"
            />
          </div>
          <div>
            {/* <span className="iconss ml-3 d-none d-md-inline"> */}
            { }
            <img
              className="mcrcbtn"
              quality={100}
              layout="responsive"
              onClick={changeStyle}
              src={ArrowIcon}
            />
            {/* </span> */}
          </div>
        </div>

        {/*  <!-- Nav Item - Dashboard --> */}
        <li className="nav-item">
          <Link

            to={path}
            className="nav-link collapsed"
            aria-expanded="true"
          >
            <img
              src={Menu}
              alt="Central"
              width="16px"
              height="16px"
              style={{ marginRight: "5px", marginBottom: "2px" }}
            />
            <span
              className="dashboard"
              style={{
                fontSize: 16,
                marginTop: 4,
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              Dashboard
            </span>
            <Link />
          </Link>
        </li>
        {isAdmin ? (
          <>
            <>
              {itemClicked === "centralStore" ? (
                <>
                  <li className="centalStr nav-item">
                    <a
                      className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapsePage"
                      aria-expanded={isCollapsed ? "false" : "true"}
                      aria-controls="collapsePage"
                    >
                      <img
                        src={UserMaster}
                        alt="Tracking"
                        width="18px"
                        height="18px"
                        style={{ marginRight: "5px" }}
                      />

                      <span
                        style={{ fontSize: 16, marginTop: 4, fontWeight: 600 }}
                      >
                        User Management
                      </span>
                    </a>
                    <div
                      id="collapsePage"
                      className={`collapse ${isCollapsed ? "" : "show"}`}
                      aria-labelledby="headingPages"
                      data-parent="#accordionSidebar"
                    >
                      <div className="userMgt py-2 collapse-inner rounded">
                        <div>
                          <Link
                            to="/RoleMaster"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>Role Master
                            </div>
                          </Link>
                          <Link
                            to="/UserMaster"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>User Master
                            </div>
                          </Link>
                          <Link
                            to="/MainAssertMaster"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>Main Asset
                              Master
                            </div>
                          </Link>
                          <Link
                            to="/AssetMaster"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>Asset Master
                            </div>
                          </Link>
                          <Link
                            to="/SubAssetMaster"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>Sub Asset
                              Master
                            </div>
                          </Link>

                          <Link
                            to="/RoleAssetMapping"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>Role Asset
                              Mapping
                            </div>
                          </Link>
                          <Link
                            to="/UserMenuControl"
                            className="collapse-item"
                            style={{ color: "white" }}
                            aria-expanded="true"
                          >
                            {" "}
                            <div>
                              <span className="arrow">&rarr;</span>User Menu Control
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="centalStr nav-item">
                    <a
                      className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapsePages"
                      aria-expanded={isCollapsed ? "false" : "true"}
                      aria-controls="collapsePages"
                    // onClick={toggleCollapse}
                    >
                      <img
                        src={CentralLogo}
                        alt="Tracking"
                        width="17px"
                        height="17px"
                        style={{ marginRight: "5px", opacity: 3 }}
                      />
                      <span
                        style={{ fontSize: 16, marginTop: 4, fontWeight: 600 }}
                      >
                        Central Store
                      </span>
                    </a>
                    <div
                      id="collapsePages"
                      className={`collapse ${isCollapsed ? "" : "show"}`}
                      aria-labelledby="headingPages"
                      data-parent="#accordionSidebar"
                    >
                      <li className="centalStrAptr nav-item">
                        <a
                          className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                            }`}
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapsePagesss"
                          aria-expanded={isCollapsed ? "false" : "true"}
                          aria-controls="collapsePagesss"
                        >
                          <img
                            src={MasterIcon}
                            alt="Tracking"
                            width="15px"
                            height="15px"
                            style={{ marginRight: "5px" }}
                          />
                          <span
                            style={{
                              fontSize: 14,
                              marginTop: 4,
                              fontWeight: 600,
                            }}
                          >
                            Masters
                          </span>
                        </a>
                        <div
                          id="collapsePagesss"
                          className={`collapse ${isSubCollapsed ? "" : "show"}`}
                          aria-labelledby="headingSubPage"
                        //  data-parent="#collapsePages"
                        >
                          <div className="master py-2 collapse-inner rounded">
                            <div classname="active">
                              <Link
                                to="/UnitMeasureMaster"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow ">&rarr;</span>Unit
                                  Measure Master
                                </div>
                              </Link>
                              <Link
                                to="/CategoryMaster"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Category
                                  Master
                                </div>
                              </Link>

                              <Link
                                to="/ProductMaster"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Product
                                  Master
                                </div>
                              </Link>
                              <Link
                                to="/MainStoreMaster"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Main
                                  Store Master
                                </div>
                              </Link>
                              <Link
                                to="/storemaster"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Store
                                  Master
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="centalStrAptr nav-item">
                        <a
                          className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                            }`}
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapseSubPagesss"
                          aria-expanded={isCollapsed ? "false" : "true"}
                          aria-controls="collapseSubPagesss"
                        >
                          <img
                            src={Tracking}
                            alt="Tracking"
                            width="15px"
                            height="15px"
                            style={{ marginRight: "5px" }}
                          />
                          <span
                            style={{
                              fontSize: 14,
                              marginTop: 4,
                              fontWeight: 600,
                            }}
                          >
                            Acceptor
                          </span>
                        </a>
                        <div
                          id="collapseSubPagesss"
                          className={`collapse ${isSubCollapsed ? "" : "show"}`}
                          aria-labelledby="headingSubPage"
                        //  data-parent="#collapsePages"
                        >
                          <div className="master py-2 collapse-inner rounded">
                            <div>
                              <Link
                                to="/StockInWard"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>
                                  Stock-In-Ward
                                </div>
                              </Link>
                              <Link
                                to="/OpenStock"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Open
                                  Stock
                                </div>
                              </Link>
                              <Link
                                to="/RequestedOrderHistory"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Requests
                                </div>
                              </Link>

                              <Link
                                to="/ReturnOrderHistory"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Returns
                                </div>
                              </Link>
                              <Link
                                to="/ProductStatus"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>Product
                                  Status
                                </div>
                              </Link>
                              <Link
                                to="/history"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>History
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                      <div>
                        {/* Cs Reports */}
                        <li className="centalStrAptr nav-item">
                          <a
                            className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                              }`}
                            href="#"
                            data-toggle="collapse"
                            data-target="#collapseSubPagesReports"
                            aria-expanded={isCollapsed ? "false" : "true"}
                            aria-controls="collapseSubPagesReports"
                          >
                            <img
                              src={reportsmenuicon}
                              alt="reportsmenuicon"
                              width="15px"
                              height="15px"
                              style={{ marginRight: "5px" }}
                            />
                            <span
                              style={{
                                fontSize: 14,
                                marginTop: 4,
                                fontWeight: 600,
                              }}
                            >
                              Reports
                            </span>
                          </a>
                          <div
                            id="collapseSubPagesReports"
                            className={`collapse ${isSubCollapsed ? "" : "show"
                              }`}
                            aria-labelledby="headingSubPage"
                          //  data-parent="#collapsePages"
                          >
                            <div className="master py-2 collapse-inner rounded">
                              <div>
                                <Link
                                  to="/stockInwardReport"
                                  className="collapse-item"
                                  style={{ color: "white" }}
                                  aria-expanded="true"
                                >
                                  {" "}
                                  <div>
                                    <span className="arrow">&rarr;</span>
                                    Stock-In-ward
                                  </div>
                                </Link>
                                {/* <Link
                                to="/openStockReport"
                                className="collapse-item"
                                style={{ color: "white" }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span>
                              
                                  Open Stock 
                                </div>
                              </Link> */}
                                <Link
                                  to="/requestReport"
                                  className="collapse-item"
                                  style={{ color: "white" }}
                                  aria-expanded="true"
                                >
                                  {" "}
                                  <div>
                                    <span className="arrow">&rarr;</span>
                                    Request
                                  </div>
                                </Link>

                                <Link
                                  to="/returnReport"
                                  className="collapse-item"
                                  style={{ color: "white" }}
                                  aria-expanded="true"
                                >
                                  {" "}
                                  <div>
                                    <span className="arrow">&rarr;</span>
                                    Return
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      </div>
                    </div>
                  </li>
                </>
              ) : (
                ""
              )}
            </>

            <>
              {itemClicked === "rmTracking" ? (
                <li className="centalStr nav-item">
                  <a
                    className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                    href="#"
                    data-toggle="collapse"
                    data-target="#collapsePag"
                    aria-expanded={isCollapsed ? "false" : "true"}
                    aria-controls="collapsePag"
                  >
                    <img
                      src={UserMaster}
                      alt="Tracking"
                      width="22px"
                      height="20px"
                      style={{ marginRight: "5px", marginLeft: "-3px" }}
                    />

                    <span style={{ fontWeight: 500 }}>R & M Tracking</span>
                  </a>
                  <div
                    id="collapsePag"
                    className={`collapse ${isCollapsed ? "" : "show"}`}
                    aria-labelledby="headingPages"
                    data-parent="#accordionSidebar"
                  >
                    <div className="userMgt py-2 collapse-inner rounded">
                      <div>
                        <Link
                          to="/WorkPermit"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Work Permit
                          </div>
                        </Link>
                        <Link
                          to="/RepairMaintenace"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>R & M
                          </div>
                        </Link>
                        <Link
                          to="/NewRequest"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>New Requests
                          </div>
                        </Link>

                        <Link
                          to="/DailyWorkAllocation"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Daily Work
                            Allocation
                          </div>
                        </Link>

                        <Link
                          to="/EquipmentMonitoring"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Preventive
                            Maintenance
                          </div>
                        </Link>
                        <Link
                          to="/BulkAllocation"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Bulk Allocation
                          </div>
                        </Link>
                        <Link
                          to="/RMhistory"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>History
                          </div>
                        </Link>

                        <li className="centalStrAptr nav-item">
                        <a
                          className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                            }`}
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapsePagesMasterRM"
                          aria-expanded={isCollapsed ? "false" : "true"}
                          aria-controls="collapsePagesMasterRM"
                          style={{ marginTop: "11px", }}
                        >
                          <img
                            src={ManPower}
                            alt="Tracking"
                            width="17px"
                            height="18px"
                            style={{ marginRight: "5px", marginBottom: "6px" }}
                          />
                          <span style={{ fontSize: 14, marginTop: 4, fontWeight: 500, color: "white", }}>
                            Masters
                          </span>
                        </a>
                        <div
                          id="collapsePagesMasterRM"
                          className={`collapse ${isCollapsed ? "" : "show"}`}
                          aria-labelledby="headingPages"
                          // data-parent="#accordionSidebar"
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <div className="master py-2">
                            <Link to="/EquipmentLocation" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Equipment Location
                              </div>
                            </Link>
                            <Link to="/EquipmentSubLocation" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Equipment SubLocation
                              </div>
                            </Link>
                            <Link to="/Equipment" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Equipment
                              </div>
                            </Link>
                          
                          </div>
                        </div> 
                      </li>

                        {/* R&M Reports */}
                        <div>
                          <li>
                            <a
                              className={`nav-link px-0
                            
                              ${isCollapsed ? "collapsed" : ""}`}
                              href="#"
                              data-toggle="collapse"
                              data-target="#collapseSubPagerm"
                              aria-expanded={isCollapsed ? "false" : "true"}
                              aria-controls="collapseSubPagerm"
                              style={{ width: "100%" }}
                            >
                              <img
                                src={reportsmenuicon}
                                alt="reportsmenuicon"
                                width="15px"
                                height="15px"
                                style={{
                                  marginRight: "5px",

                                  width: "20px",
                                  height: "22px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: 14,
                                  marginTop: 4,
                                  fontWeight: 600,
                                  color: "white",
                                }}
                              >
                                Reports
                              </span>
                            </a>
                            <div
                              id="collapseSubPagerm"
                              className={`collapse ${isSubCollapsed ? "" : "show"
                                }`}
                              aria-labelledby="headingSubPage"
                            //  data-parent="#collapsePages"
                            >
                              <div className="master py-2 ">
                                <div>
                                  <Link
                                    to="/workPermitReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      Work Permit
                                    </div>
                                  </Link>
                                  <Link
                                    to="/rMReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      R&M
                                    </div>
                                  </Link>
                                  <Link
                                    to="/newRequestReport"
                                    //to="/NewRequest"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      New Request
                                    </div>
                                  </Link>

                                  <Link
                                    to="/DailyWorkAllocationReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      Daily Work Allocation
                                    </div>
                                  </Link>

                                  <Link
                                    to="/ShiftBasedReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      Shift Based
                                    </div>
                                  </Link>

                                  <Link
                                    to="/EquipmentMonitoringReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      {/* Equipment Monitoring */}
                                      Preventive Maintenance

                                    </div>
                                  </Link>

                                  <Link
                                    to="/BulkAllocationReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      BulkAllocation
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </>

            <>
              {itemClicked === "farmOperations" ? (
                <li className="centalStr nav-item">
                  <a
                    className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                    href="#"
                    data-toggle="collapse"
                    data-target="#collapsePagesFarm"
                    aria-expanded={isCollapsed ? "false" : "true"}
                    aria-controls="collapsePagesFarm"
                  // onClick={toggleCollapse}
                  >
                    <img
                      src={FarmlogoSidemenu}
                      alt="Tracking"
                      width="17px"
                      height="18px"
                      style={{ marginRight: "5px", marginBottom: "6px" }}
                    />
                    <span
                      style={{ fontSize: 16, marginTop: 4, fontWeight: 600 }}
                    >
                      Farm Operations
                    </span>
                  </a>
                  <div
                    id="collapsePagesFarm"
                    className={`collapse ${isCollapsed ? "" : "show"}`}
                    aria-labelledby="headingPages"
                    data-parent="#accordionSidebar"
                  >
                    <div className="centalStrAptr py-2 collapse-inner rounded">
                      <Link
                        to="/RequestFarmOperation"
                        className="collapse-item"
                        style={{ color: "white" }}
                        aria-expanded="true"
                      >
                        {" "}
                        <div>
                          <span className="arrow">&rarr;</span>Requests
                        </div>
                      </Link>
                      <Link
                        to="/UpdateActivity"
                        className="collapse-item"
                        style={{ color: "white" }}
                        aria-expanded="true"
                      >
                        {" "}
                        <div>
                          <span className="arrow">&rarr;</span>Update Activity
                        </div>
                      </Link>


                      <li className="centalStrAptr nav-item">
                        <a
                          className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                            }`}
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapsePagesMaster"
                          aria-expanded={isCollapsed ? "false" : "true"}
                          aria-controls="collapsePagesMaster"
                          style={{ marginTop: "11px", }}
                        >
                          <img
                            src={ManPower}
                            alt="Tracking"
                            width="17px"
                            height="18px"
                            style={{ marginRight: "5px", marginBottom: "6px" }}
                          />
                          <span style={{ fontSize: 14, marginTop: 4, fontWeight: 500, color: "white", }}>
                            Masters
                          </span>
                        </a>
                        <div
                          id="collapsePagesMaster"
                          className={`collapse ${isCollapsed ? "" : "show"}`}
                          aria-labelledby="headingPages"
                          // data-parent="#accordionSidebar"
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <div className="centalStrAptr py-2 collapse-inner rounded">
                            <Link to="/CropMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Crop
                              </div>
                            </Link>
                            <Link to="/SubCropMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> SubCrop
                              </div>
                            </Link>
                            <Link to="/SiteMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Main Site
                              </div>
                            </Link>
                            <Link to="/SubSiteMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> SubSite
                              </div>
                            </Link>
                            <Link to="/CropFunction" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Crop Function
                              </div>
                            </Link>
                            <Link to="/FunctionMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Function
                              </div>
                            </Link>
                            <Link to="/SubFunctionMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> SubFunction
                              </div>
                            </Link>
                            <Link to="/PlotLocationMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Plot Location
                              </div>
                            </Link>
                            <Link to="/PlotAcrageMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Plot Acreage 
                              </div>
                            </Link>
                            <Link to="/ChemicalMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Chemical
                              </div>
                            </Link>
                            <Link to="/FertilizerMasters" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span> Fertilizer
                              </div>
                            </Link>
                            <Link to="/MainActivity" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Category
                              </div>
                            </Link>
                            <Link to="/ActivityMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Sub Category
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div
                          id="collapsePagesMaster"
                          className={`collapse ${isSubCollapsed ? "" : "show"}`}
                          aria-labelledby="headingSubPage"
                          //  data-parent="#collapsePages"
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <div className="master py-2 collapse-inner rounded mt-0">
                            <li className="centalStrAptr nav-item">
                              <a
                                className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                                  }`}
                                href="#"
                                data-toggle="collapse"
                                data-target="#collapseSubMaters"
                                aria-expanded={isCollapsed ? "false" : "true"}
                                aria-controls="collapseSubMaters"
                                style={{ marginTop: "-28px", fontWeight: 600 }}  >
                                <span
                                  className="arrow mt-3"
                                  style={{ fontSize: 14, fontWeight: 600 }}
                                >
                                  &rarr;
                                </span>
                                Machine/System Masters
                              </a>
                              <div id="collapseSubMaters"
                                className={`collapse ${isSubCollapsed ? "" : "show"}`} aria-labelledby="headingSubPage"  >
                                <div className="master py-2 collapse-inner rounded">
                                  <div>
                                    {/* <Link to="/RMactMasters"  className="collapse-item"  style={{ color: "white" }}  aria-expanded="true"  >
                                    {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span> R&M Activity
                                      </div>
                                    </Link> */}
                                    <Link to="/DripSystemMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>Drip System
                                      </div>
                                    </Link>
                                    <Link to="/ImplementMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>Implement
                                      </div>
                                    </Link>
                                    <Link to="/DGmaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>DG Master
                                      </div>
                                    </Link>
                                    <Link to="/TractorMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>Tractor
                                      </div>
                                    </Link>
                                    <Link to="/BorewellMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>BoreWell
                                      </div>
                                    </Link>
                                    <Link to="/SprayerMaster" className="collapse-item" style={{ color: "white" }} aria-expanded="true"  >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>Sprayers
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        </div>
                      </li>




                      <li className="centalStrAptr nav-item">
                        <a
                          className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                            }`}
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapsePagesss"
                          aria-expanded={isCollapsed ? "false" : "true"}
                          aria-controls="collapsePagesss"
                          style={{ marginTop: "4px" }}
                        >
                          <img
                            src={MasterIcon}
                            alt="Tracking"
                            width="15px"
                            height="15px"
                            style={{ marginRight: "5px" }}
                          />
                          {/* <span className="arrow">&rarr;</span> */}
                          <span
                            style={{
                              fontSize: 14,
                              marginTop: 4,
                              fontWeight: 600,
                              color: "white",
                            }}
                          >
                            R&M Activity
                          </span>
                        </a>
                        <div
                          id="collapsePagesss"
                          className={`collapse ${isSubCollapsed ? "" : "show"}`}
                          aria-labelledby="headingSubPage"
                          //  data-parent="#collapsePages"
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <div className="master py-2 collapse-inner rounded mt-0">
                            <li className="centalStrAptr nav-item">
                              <a
                                className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                                  }`}
                                href="#"
                                data-toggle="collapse"
                                data-target="#collapseSubPagesss"
                                aria-expanded={isCollapsed ? "false" : "true"}
                                aria-controls="collapseSubPagesss"
                                style={{
                                  marginTop: "-28px",
                                }}
                              >
                                <span
                                  className="arrow mt-3"
                                  style={{ fontSize: 14, fontWeight: 600 }}
                                >
                                  &rarr;
                                </span>
                                Machines/Systems
                              </a>
                              <div
                                id="collapseSubPagesss"
                                className={`collapse ${isSubCollapsed ? "" : "show"
                                  }`}
                                aria-labelledby="headingSubPage"
                              //  data-parent="#collapsePages"
                              >
                                <div className="master py-2 collapse-inner rounded">
                                  <div>
                                    <Link
                                      to="/FarmOperationsDripSystem"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Drip Systems
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsImplements"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Implements
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsDG"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>DG
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsTractors"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Tractors
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsSprayServices"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Sparyers
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsBoreWell"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        BoreWells
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsGenaralMaintanance"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Genaral Maintanance
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <div className="aster collapse-inner rounded">
                              <Link
                                to="/FarmOperationHistory"
                                className="collapse-item"
                                style={{
                                  color: "white",

                                  marginTop: "-20px",
                                }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span> R & M
                                  History
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                      <div
                        className="aster collapse-inner rounded"
                        style={{ marginTop: "-18px" }}
                      >
                        <Link
                          to="/FarmOperationHistoryAll"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div style={{ height: "23px" }}>
                            <span className="arrow">&rarr;</span>History
                          </div>
                        </Link>

                        <Link
                          to="/FarmOperationsReports"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Reports
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </>
            <>
              <li className="centalStr nav-item">
                <a
                  className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapsePagesMan"
                  aria-expanded={isCollapsed ? "false" : "true"}
                  aria-controls="collapsePagesMan"
                // onClick={toggleCollapse}
                >
                  <img
                    src={ManPower}
                    alt="Tracking"
                    width="17px"
                    height="18px"
                    style={{ marginRight: "5px", marginBottom: "6px" }}
                  />
                  <span style={{ fontSize: 16, marginTop: 4, fontWeight: 600 }}>
                    Man Power
                  </span>
                </a>
                <div
                  id="collapsePagesMan"
                  className={`collapse ${isCollapsed ? "" : "show"}`}
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="centalStrAptr py-2 collapse-inner rounded">
                    <Link
                      to="/EmployeeList"
                      className="collapse-item"
                      style={{ color: "white" }}
                      aria-expanded="true"
                    >
                      {" "}
                      <div>
                        <span className="arrow">&rarr;</span>Employee List
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
            </>
          </>
        ) : (
          <>
            <>
              {itemClicked === "centralStore" ? (
                <li className="centalStr nav-item">
                  <a
                    className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                    href="#"
                    data-toggle="collapse"
                    data-target="#collapsePages"
                    aria-expanded={isCollapsed ? "false" : "true"}
                    aria-controls="collapsePages"
                  // onClick={toggleCollapse}
                  >
                    <img
                      src={CentralLogo}
                      alt="Tracking"
                      width="17px"
                      height="17px"
                      style={{ marginRight: "5px", opacity: 3 }}
                    />
                    <span
                      style={{ fontSize: 16, marginTop: 4, fontWeight: 600 }}
                    >
                      Central Store
                    </span>
                  </a>
                  <div
                    id="collapsePages"
                    className={`collapse ${isCollapsed ? "" : "show"}`}
                    aria-labelledby="headingPages"
                    data-parent="#accordionSidebar"
                  >
                    <li className="centalStrAptr nav-item">
                      <a
                        className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                          }`}
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapsePagesss"
                        aria-expanded={isCollapsed ? "false" : "true"}
                        aria-controls="collapsePagesss"
                      >
                        <img
                          src={MasterIcon}
                          alt="Tracking"
                          width="15px"
                          height="15px"
                          style={{ marginRight: "5px" }}
                        />
                        <span
                          style={{
                            fontSize: 14,
                            marginTop: 4,
                            fontWeight: 600,
                          }}
                        >
                          Masters
                        </span>
                      </a>
                      <div
                        id="collapsePagesss"
                        className={`collapse ${isSubCollapsed ? "" : "show"}`}
                        aria-labelledby="headingSubPage"
                      //  data-parent="#collapsePages"
                      >
                        <div className="master py-2 collapse-inner rounded">
                          <div classname="active">
                            <Link
                              to="/UnitMeasureMaster"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow ">&rarr;</span>Unit
                                Measure Master
                              </div>
                            </Link>
                            <Link
                              to="/CategoryMaster"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Category
                                Master
                              </div>
                            </Link>

                            <Link
                              to="/ProductMaster"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Product
                                Master
                              </div>
                            </Link>
                            <Link
                              to="/MainStoreMaster"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Main Store
                                Master
                              </div>
                            </Link>
                            <Link
                              to="/storemaster"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Store
                                Master
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="centalStrAptr nav-item">
                      <a
                        className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                          }`}
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapseSubPagesss"
                        aria-expanded={isCollapsed ? "false" : "true"}
                        aria-controls="collapseSubPagesss"
                      >
                        <img
                          src={Tracking}
                          alt="Tracking"
                          width="15px"
                          height="15px"
                          style={{ marginRight: "5px" }}
                        />
                        <span
                          style={{
                            fontSize: 14,
                            marginTop: 4,
                            fontWeight: 600,
                          }}
                        >
                          Acceptor
                        </span>
                      </a>
                      <div
                        id="collapseSubPagesss"
                        className={`collapse ${isSubCollapsed ? "" : "show"}`}
                        aria-labelledby="headingSubPage"
                      //  data-parent="#collapsePages"
                      >
                        <div className="master py-2 collapse-inner rounded">
                          <div>
                            <Link
                              to="/StockInWard"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>
                                Stock-In-Ward
                              </div>
                            </Link>
                            <Link
                              to="/OpenStock"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Open Stock
                              </div>
                            </Link>
                            <Link
                              to="/RequestedOrderHistory"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Requests
                              </div>
                            </Link>

                            <Link
                              to="/ReturnOrderHistory"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Returns
                              </div>
                            </Link>
                            <Link
                              to="/ProductStatus"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>Product
                                Status
                              </div>
                            </Link>
                            <Link
                              to="/history"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>History
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* Cs Reports */}
                    <li className="centalStrAptr nav-item">
                      <a
                        className={`nav-link  px-0 ${isCollapsed ? "collapsed" : ""
                          }`}
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapseSubPagesReports"
                        aria-expanded={isCollapsed ? "false" : "true"}
                        aria-controls="collapseSubPagesReports"
                      >
                        <img
                          src={reportsmenuicon}
                          alt="reportsmenuicon"
                          width="15px"
                          height="15px"
                          style={{ marginRight: " 5px" }}
                        />
                        <span
                          style={{
                            marginRight: "11px",
                            fontSize: 14,
                            marginTop: 4,
                            fontWeight: 600,
                          }}
                        >
                          Reports
                        </span>
                      </a>
                      <div
                        id="collapseSubPagesReports"
                        className={`collapse ${isSubCollapsed ? "" : "show"}`}
                        aria-labelledby="headingSubPage"
                      //  data-parent="#collapsePages"
                      >
                        <div className="master py-2 collapse-inner rounded">
                          <div>
                            <Link
                              to="/stockInwardReport"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>
                                Stock-In-ward
                              </div>
                            </Link>
                            {/* <Link
                              to="/openStockReport"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>

                                Open Stock
                              </div>
                            </Link> */}
                            <Link
                              to="/requestReport"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>
                                Request
                              </div>
                            </Link>

                            <Link
                              to="/returnReport"
                              className="collapse-item"
                              style={{ color: "white" }}
                              aria-expanded="true"
                            >
                              {" "}
                              <div>
                                <span className="arrow">&rarr;</span>
                                Return
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </li>
              ) : (
                ""
              )}
            </>
            <>
              {itemClicked === "rmTracking" ? (
                <li className="centalStr nav-item">
                  <a
                    className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                    href="#"
                    data-toggle="collapse"
                    data-target="#collapsePag"
                    aria-expanded={isCollapsed ? "false" : "true"}
                    aria-controls="collapsePag"
                  >
                    <img
                      src={UserMaster}
                      alt="Tracking"
                      width="17px"
                      height="17px"
                      style={{ marginRight: "5px", opacity: "3" }}
                    />

                    <span>R & M Tracking</span>
                  </a>

                  <div
                    id="collapsePag"
                    className={`collapse ${isCollapsed ? "" : "show"}`}
                    aria-labelledby="headingPages"
                    data-parent="#accordionSidebar"
                  >
                    <div className="userMgt py-2 collapse-inner rounded">
                      <div>
                        <Link
                          to="/WorkPermit"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Work Permit
                          </div>
                        </Link>
                        <Link
                          to="/RepairMaintenace"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>R & M
                          </div>
                        </Link>
                        <Link
                          to="/NewRequest"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>New Requests
                          </div>
                        </Link>

                        <Link
                          to="/DailyWorkAllocation"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Daily Work
                            Allocation
                          </div>
                        </Link>

                        <Link
                          to="/EquipmentMonitoring"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Preventive
                            Maintenance
                          </div>
                        </Link>
                        <Link
                          to="/BulkAllocation"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>BulkAllocation
                          </div>
                        </Link>
                        <Link
                          to="/RMhistory"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>History
                          </div>
                        </Link>
                        {/* R&M Reports */}
                        <div>
                          <li>
                            <a
                              className={`nav-link px-0
                            
                              ${isCollapsed ? "collapsed" : ""}`}
                              href="#"
                              data-toggle="collapse"
                              data-target="#collapseSubPagerm"
                              aria-expanded={isCollapsed ? "false" : "true"}
                              aria-controls="collapseSubPagerm"
                              style={{ width: "100%" }}
                            >
                              <img
                                src={reportsmenuicon}
                                alt="reportsmenuicon"
                                width="15px"
                                height="15px"
                                style={{
                                  marginRight: "5px",

                                  width: "20px",
                                  height: "22px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: 14,
                                  marginTop: 4,
                                  fontWeight: 600,
                                  color: "white",
                                }}
                              >
                                Reports
                              </span>
                            </a>
                            <div
                              id="collapseSubPagerm"
                              className={`collapse ${isSubCollapsed ? "" : "show"
                                }`}
                              aria-labelledby="headingSubPage"
                            //  data-parent="#collapsePages"
                            >
                              <div className="master py-2 ">
                                <div>
                                  <Link
                                    to="/workPermitReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      Work Permit
                                    </div>
                                  </Link>
                                  <Link
                                    to="/rMReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      R&M
                                    </div>
                                  </Link>
                                  <Link
                                    to="/newRequestReport"
                                    //to="/NewRequest"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      New Request
                                    </div>
                                  </Link>

                                  <Link
                                    to="/DailyWorkAllocationReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      Daily Work Allocation
                                    </div>
                                  </Link>

                                  <Link
                                    to="/ShiftBasedReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      Shift Based
                                    </div>
                                  </Link>

                                  <Link
                                    to="/EquipmentMonitoringReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      {/* Equipment Monitoring */}
                                      Preventive Maintenance
                                    </div>
                                  </Link>

                                  <Link
                                    to="/BulkAllocationReport"
                                    className="collapse-item"
                                    style={{ color: "white" }}
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <div>
                                      <span className="arrow">&rarr;</span>
                                      BulkAllocation
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </li>
              ) : (
                ""
              )}
            </>

            <>
              {itemClicked === "farmOperations" ? (
                <li className="centalStr nav-item">
                  <a
                    className={`nav-link ${isCollapsed ? "collapsed" : ""}`}
                    href="#"
                    data-toggle="collapse"
                    data-target="#collapsePagesFarm"
                    aria-expanded={isCollapsed ? "false" : "true"}
                    aria-controls="collapsePagesFarm"
                  // onClick={toggleCollapse}
                  >
                    <img
                      src={FarmlogoSidemenu}
                      alt="Tracking"
                      width="17px"
                      height="18px"
                      style={{ marginRight: "5px", marginBottom: "6px" }}
                    />
                    <span
                      style={{ fontSize: 16, marginTop: 4, fontWeight: 600 }}
                    >
                      Farm Operations
                    </span>
                  </a>
                  <div
                    id="collapsePagesFarm"
                    className={`collapse ${isCollapsed ? "" : "show"}`}
                    aria-labelledby="headingPages"
                    data-parent="#accordionSidebar"
                  >
                    <div className="centalStrAptr py-2 collapse-inner rounded">
                      <Link
                        to="/RequestFarmOperation"
                        className="collapse-item"
                        style={{ color: "white" }}
                        aria-expanded="true"
                      >
                        {" "}
                        <div>
                          <span className="arrow">&rarr;</span>Requests
                        </div>
                      </Link>
                      <Link
                        to="/UpdateActivity"
                        className="collapse-item"
                        style={{ color: "white" }}
                        aria-expanded="true"
                      >
                        {" "}
                        <div>
                          <span className="arrow">&rarr;</span>Update Activity
                        </div>
                      </Link>

                      <li className="centalStrAptr nav-item">
                        <a
                          className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                            }`}
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapsePagesss"
                          aria-expanded={isCollapsed ? "false" : "true"}
                          aria-controls="collapsePagesss"
                          style={{ marginTop: "11px" }}
                        >
                          <span className="arrow">&rarr;</span>
                          <span
                            style={{
                              fontSize: 14,
                              marginTop: 4,
                              fontWeight: 600,
                            }}
                          >
                            R&M Activity
                          </span>
                        </a>
                        <div
                          id="collapsePagesss"
                          className={`collapse ${isSubCollapsed ? "" : "show"}`}
                          aria-labelledby="headingSubPage"
                          //  data-parent="#collapsePages"
                          style={{ marginLeft: "0px", marginRight: "0px" }}
                        >
                          <div className="master py-2 collapse-inner rounded mt-0">
                            <li className="centalStrAptr nav-item">
                              <a
                                className={`nav-link px-0 ${isCollapsed ? "collapsed" : ""
                                  }`}
                                href="#"
                                data-toggle="collapse"
                                data-target="#collapseSubPagesss"
                                aria-expanded={isCollapsed ? "false" : "true"}
                                aria-controls="collapseSubPagesss"
                                style={{ marginTop: "-28px" }}
                              >
                                {/* <img
         src={Tracking}
         alt="Tracking"
         width="15px"
         height="15px"
         style={{ marginRight: "5px", }}
       /> */}
                                <span
                                  className="arrow mt-3"
                                  style={{ fontSize: 14, fontWeight: 600 }}
                                >
                                  &rarr;
                                </span>
                                Machines/Systems
                              </a>
                              <div
                                id="collapseSubPagesss"
                                className={`collapse ${isSubCollapsed ? "" : "show"
                                  }`}
                                aria-labelledby="headingSubPage"
                              //  data-parent="#collapsePages"
                              >
                                <div className="master py-2 collapse-inner rounded">
                                  <div>
                                    <Link
                                      to="/FarmOperationsDripSystem"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Drip Systems
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsImplements"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Implements
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsDG"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>DG
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsTractors"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Tractors
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsSprayServices"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Sparyers
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsBoreWell"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        BoreWells
                                      </div>
                                    </Link>
                                    <Link
                                      to="/FarmOperationsGenaralMaintanance"
                                      className="collapse-item"
                                      style={{ color: "white" }}
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <div>
                                        <span className="arrow">&rarr;</span>
                                        Genaral Maintanance
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <div className="aster collapse-inner rounded">
                              <Link
                                to="/FarmOperationHistory"
                                className="collapse-item"
                                style={{
                                  color: "white",
                                  marginTop: "-20px",
                                }}
                                aria-expanded="true"
                              >
                                {" "}
                                <div>
                                  <span className="arrow">&rarr;</span> R & M
                                  History
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                      <div
                        className="aster collapse-inner rounded"
                        style={{ marginTop: "-18px" }}
                      >
                        <Link
                          to="/FarmOperationHistoryAll"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div style={{ height: "23px" }}>
                            <span className="arrow">&rarr;</span>History
                          </div>
                        </Link>

                        <Link
                          to="/FarmOperationsReports"
                          className="collapse-item"
                          style={{ color: "white" }}
                          aria-expanded="true"
                        >
                          {" "}
                          <div>
                            <span className="arrow">&rarr;</span>Reports
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </>
          </>
        )}
      </ul>
    </>
  );
}

export default SideMenu;
