import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { USER_PROFILE } from "../Utils";

const Popup = () => {
  const [show, setShow] = useState(true);
  const [getLogin, setGetLogin] = useState([]);
  const nav = useNavigate();

  const handleClose = () => {
    setShow(false);
  };
  let itemClicked = sessionStorage.getItem("itemClicked");
 
  useEffect(() => {
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    console.log("USER_PROFILE from sessionStorage:", userProfileJSON); // Log the USER_PROFILE value
    const userDataObject = JSON.parse(userProfileJSON);
    setGetLogin(userDataObject);
  }, []);



  console.log("getLogin", getLogin);

  const handleProceed = (item) => {
    sessionStorage.setItem("itemClicked", item);
    if (item === "centralStore") {
      nav('/CentralStoreDashboard')
    }
    if (item === "rmTracking") {
      nav('/RMDashboard')
    }
    if (item === "farmOperations") {
      nav('/FarmOperationDashboard')
    }


  };
  const formatCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={true}
        size="lg"
        className="custom-modal modalBlur"
        onHide={handleClose}
        centered
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
      >
        <Modal.Header className="border-0 d-flex justify-content-center bg-color-black">
          <Modal.Title></Modal.Title>
          <Button
            variant="link"
            className="close btn btn-danger close_btn"
            onClick={() => nav(-1)}
          >
            {" "}
            X
          </Button>
        </Modal.Header>
        <Modal.Body className="pl-md-5 pr-md-5 pt-0">
          <div>
            <table className="table table-sm ">
              <thead>
                <tr>
                  <th scope="col text-center font-monospace">S.No</th>
                  <th className="col text-center font-monospace ">Name</th>
                  <th className="col text-center font-monospace">Action</th>
                </tr>
              </thead>
              <tbody>

                {
                  getLogin.userMenuControl &&
                  Object.keys(getLogin.userMenuControl)
                    .filter(key => {
                      return (
                        (getLogin.centralStore && key === "centralStore") ||
                        (getLogin.farmOperations && key === "farmOperations") ||
                        (getLogin.rmTracking && key === "rmTracking")
                      );
                    })
                    .map((item, roleIndex) => {
                      const label =
                        item === "centralStore"
                          ? "Central Store"
                          : item === "farmOperations"
                            ? "Farm Operations"
                            : item === "rmTracking"
                              ? "R&M Tracking"
                              : "";

                      return (
                        <tr key={roleIndex}>
                          <td>{roleIndex + 1}</td>
                          <td className="text-center">{label}</td>
                          <td>
                            <button
                              className="popupBtn btn btn-success btn-sm"
                              onClick={() => handleProceed(item)}
                            >
                              Proceed
                            </button>
                          </td>
                        </tr>
                      );
                    })
                }

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Popup;
