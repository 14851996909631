import React, { useState, useEffect, useRef } from "react";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./Logout";
import Card from "react-bootstrap/Card";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getMainAssets, getRoleMaster, getroleAssetMapping, setroleAssetMapping } from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import axios from "axios";
import Loader from '../Utilities/Loader';
import { Button, Modal } from 'react-bootstrap';


const RoleAssetMapping = ({ }) => {
  const [submitResponse, setsubmitResponse] = useState('');
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleData, setroleData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [maindropdowndata, setMaindropdowndata] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [mainAssert, setMainAssert] = useState('');
  const [roleId, setroleId] = useState('');
  const [errorRole, setErrorRole] = useState('');
  const [errorMain, setErrorMain] = useState('');
  const [showMsg, setShowMsg] = useState('false');
  const [showsubmit, setShowsubmit] = useState(false);

  const handleSelection = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleMainAssertChange = (e) => {
    // console.log("handleMainAssertChange:: ", e.target.value);
    setMainAssert(e.target.value);
    // setTimeout(() => {
      fetchCardData(e.target.value);
    // }, 500);
    // alert(e.target.value);

  };

  const handleRoleChange = (e) => {

    //const input = e.target.value;
    console.log(e.target.value);
    setroleId(e.target.value);

    // if (input.length > 0) {
    //   setErrorRole('')
    setMainAssert('');
    //   setCardsData([]);
    // }

    fetchMainDropdownData(e.target.value);

  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    window.location.reload();
  }

  const handleRoleDopdowndata = async () => {
    const apiUrl = getRoleMaster;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    axios.get(apiUrl, { headers })
      .then((response) => {
        console.log("responserOLE", response);
        setroleData(response.data.response.roleMaster)

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  };
  const fetchMainDropdownData = async (roleId) => {

    const apiUrl = getMainAssets;
    const headers = await GetApiHeaders();
    if (roleId != null && roleId != '') {
      setIsLoading(false);

      axios.get(apiUrl, { headers })
        .then((response) => {
          console.log("response", response);
          setMaindropdowndata(response.data.response.MainAssetMaster)
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching data:', error);
        });
    }
  }
  useEffect(() => {
    // fetchMainDropdownData();
    handleRoleDopdowndata();
    console.log("use Effect mainAssert", mainAssert);
  }, [mainAssert]);

  const fetchCardData = async (mainassertid) => {
    console.log("mainAssert", mainassertid);

    const apiUrl = getroleAssetMapping;
    const headers = await GetApiHeaders();
    if (roleId !== '' && mainassertid !== '') {
      const dataToSend = {
        "id": roleId,
        "mainAssetId": mainassertid
      };
      setIsLoading(true);
      axios.post(apiUrl, dataToSend, { headers })
        .then((response) => {
          console.log("response", response);
          setCardsData(response.data.response.roleAssetMapping)
          setIsLoading(false);
          setShowsubmit(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching data:', error);
        });
    }
  }

  //  const [cardsData, setCardsData] = useState([
  //    {
  //    "assetName":"requester",
  //    "subAssets":[
  //    {
  //    "subAssetName":"requestForm",
  //    "subAssetId":1,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"returnForm",
  //    "subAssetId":2,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"history",
  //    "subAssetId":3,
  //    "viewStatus":true,
  //    "editable":true
  //    }
  //    ]
  //    },
  //    {
  //    "assetId":2,
  //    "assetName":"acceptor",
  //    "subAssets":[
  //    {
  //    "subAssetName":"stockInWard",
  //    "subAssetId":4,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"openStock",
  //    "subAssetId":5,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"requests",
  //    "subAssetId":6,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"returns",
  //    "subAssetId":7,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"productStatus",
  //    "subAssetId":8,
  //    "viewStatus":true,
  //    "editable":true
  //    },
  //    {
  //    "subAssetName":"history",
  //    "subAssetId":9,
  //    "viewStatus":true,
  //    "editable":true
  //    }
  //    ]
  //    }
  //    ]);
  const handleSubmit = async () => {

    // const jsonData = {
    //   roleId: roleId,
    //   mainAssetId: mainAssert,
    //   assets: cardsData.map((card) => ({
    //      card.subAssets.map((subAsset) => ({
    //       assetId: card.assetId,
    //       subAssetId: subAsset.subAssetId,
    //       isEditable: subAsset.editable,
    //       isViewStatus: subAsset.viewStatus
    //     }))
    //   }))
    // };
    //   const jsonData = {

    //     roleId: jsonData.roleId,

    //     mainAssetId: jsonData.mainAssetId,

    //     assets: jsonData.assets.flatMap((card) =>
    //         card.subAssets.map((subAsset) => ({
    //             assetId: card.assetId,
    //             subAssetId: subAsset.subAssetId,
    //             isEditable: subAsset.editable,
    //             isViewStatus: subAsset.viewStatus

    //         }))

    //     )

    // };

    var array = []
    for (let index = 0; index < cardsData.length; index++) {
      for (let sIndex = 0; sIndex < cardsData[index].subAssets.length; sIndex++) {
        var obj = {
          assetId: cardsData[index].assetId,
          subAssetId: cardsData[index].subAssets[sIndex].subAssetId,
          isEditable: cardsData[index].subAssets[sIndex].editable,
          isViewStatus: cardsData[index].subAssets[sIndex].viewStatus
        }
        array.push(obj)
      }
    }
    var jsonData = {
      roleId: roleId,
      mainAssetId: mainAssert,
      assets: array
    }
    console.log("Submit Json", JSON.stringify(jsonData)); // You can send this JSON to your backend or use it as needed
    try {
      if (jsonData != null && jsonData != "") {

        const apiUrl = setroleAssetMapping;
        const headers = await GetApiHeaders();
        // const headers = {
        //   "Content-Type": "application/json",
        //   "userId": "1",
        //   "mobileNumber": "9704164746",
        // };
        // Make the POST request using Axios with headers
        setIsLoading(true);
        const response = await axios.post(apiUrl, jsonData, { headers });
        setsubmitResponse(response.data.response);
        setShowResponsePopup(true);
        setIsLoading(false);
        // Handle the response data as needed
        console.log('Response:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCheckboxChange = (cardIndex, subAssetIndex, type) => {
    const updatedCardsData = [...cardsData];
    const subAsset = updatedCardsData[cardIndex].subAssets[subAssetIndex];
    console.log("subAsset::", JSON.stringify(subAsset));
    subAsset[type] = !subAsset[type];
    console.log("!subAsset::", JSON.stringify(subAsset));
    setCardsData(updatedCardsData);
  };

  const formatCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text ml-3">
                          Role Asset Mapping
                        </h6>
                      </div>
                    </div>
                    <div className="dropdown-container mt-3">
                      <label className="role ml-3">
                        <b>Role&nbsp;&nbsp;&nbsp;:</b>&nbsp;&nbsp;&nbsp;
                      </label>
                      <select className="selector" id="dropdown" type="dropdown" value={roleId} onChange={handleRoleChange}>
                        <option value="Select Role">Select Role</option>

                        {roleData != undefined && roleData.map((item) => (
                          item.status === "1" ? (<option key={item.id} value={item.id}>{item.roleName}</option>) : null
                        ))}
                      </select>
                      <label className="mainAsset">
                        <b>Main Asset&nbsp;&nbsp;&nbsp;:</b>&nbsp;&nbsp;&nbsp;
                      </label>
                      <select className="selector" id="dropdown" type="dropdown" value={mainAssert} onChange={handleMainAssertChange}>
                        <option value=""> Main Assert Master</option>
                        {maindropdowndata != undefined && maindropdowndata.map((item) => (
                          item.status === "1" ? (<option key={item.id} value={item.id}>{item.mainAssetName}</option>) : null
                          // <option key={item.id} value={item.id}>{item.mainAssetName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex flex-row flex-wrap">
                      {(cardsData != undefined && cardsData.length > 0) && cardsData.map((card, cardIndex) => (
                        <div className="row mr-2 ml-2">
                          <div className="col-lg-6 col-md-6 p-2">
                            <div className="card mt-3" style={{
                              width: "33rem", height: "18rem", overflowY: "auto",
                            }}>
                              <div className="card-header"
                                style={{ backgroundColor: "#1580F3", color: "white", fontWeight: 500 }}>
                                {formatCamelCase(card.assetName)} {/*Dash board */}
                                <span className="edit mr-1">Edit</span>
                                <span className="view mr-2">View</span>
                              </div>
                              {card.subAssets.map((subAsset, subAssetIndex) => (
                                <div className="card-body">
                                  <div className="mt-2 ml-2">
                                    <div>
                                      <label className="label">{formatCamelCase(subAsset.subAssetName)}</label>
                                      <input type="checkbox" className="checkbox"
                                        checked={subAsset.editable}
                                        onChange={() =>
                                          handleCheckboxChange(cardIndex, subAssetIndex, "editable")
                                        } />
                                      <input type="checkbox" className="checkbox"
                                        checked={subAsset.viewStatus}
                                        onChange={() =>
                                          handleCheckboxChange(cardIndex, subAssetIndex, "viewStatus")
                                        }
                                      />
                                    </div>
                                  </div>

                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <br/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {showsubmit && <Button variant="primary" sstyle={{ display: 'flex', gap: '10px' }} onClick={handleSubmit}>Submit</Button>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div>

              <Modal show={showResponsePopup} centered backdrop="static" keyboard={false} size="ml">
                <Modal.Header className="border-0 d-flex justify-content-center">
                  <Modal.Title>  <span className='modal_title'>Role Asset Mapping</span></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pl-md-5 pr-md-5 pt-0' >

                  <div className='d-flex justify-content-center mb-3 font-weight-600'>

                    {showMsg ? (

                      <p style={{ color: 'green' }} >{submitResponse}</p>

                    ) : null}

                  </div>
                  <div className="d-flex justify-content-center">

                    <Button variant="primary" className="d-flex justify-content-center cancel_button" onClick={handleresponse} >
                      Okay
                    </Button>&nbsp;&nbsp;
                  </div>
                </Modal.Body>
              </Modal>

            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      {/* {isLoading && <Loader loading={isLoading} message={"Fetching Data. Please Wait..!"} />} */}
      <Logout />
    
    </body>
  );
};

export default RoleAssetMapping;
