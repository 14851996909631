import React, { useState, useEffect, useRef } from "react";
//import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
//import Accordion from "react-bootstrap/Accordion";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  coreGeneralCheck,
  GeneralInfoConst,
  HotWorkInfoConst,
  RequiredPreConstant,
  VerifyConst,
  AirMonitoringConst,
  FireWatchConst,
  SecondaryConst,
} from "../Utilities/hotWorkPermit2-JSON";
import GeneralInformation from "../components/hotPermitMobileView/GeneralInformation";
import HotWorkInformation from "../components/hotPermitMobileView/HotWorkInformation";
import RequiredPrecautions from "../components/hotPermitMobileView/RequiredPrecautions";
import VerifyPermitIssuer from "../components/hotPermitMobileView/VerifyPermitIssuer";
import AirMonitoring from "../components/hotPermitMobileView/AirMonitoring";
import FireWatch from "../components/hotPermitMobileView/FireWatch";
import CorePermitInputFields from "../components/hotPermitMobileView/corePermitInputFields";
import SecondarySignPermitIssuer from "../components/hotPermitMobileView/SecondarySignPermitIssuer";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  MOBILE_NUMBER,
  USER_PROFILE,
  retrieveData,
  ADDITIONAL_PERMITID,
  PERMIT_NAME,
  checkForEmptyValue,
} from "../Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import Accordion from "../Accordation/Accordian";
import axios from "axios";

//Approve
import Approved from "../images/Approved.png";
import Reject from "../images/Reject.png";
import Loader_MS from "../Utilities/Loader_MS";
import Loader from "../Utilities/Loader";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import { faTruckField } from "@fortawesome/free-solid-svg-icons";

const GeneralHotWork2Additional = (props) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  //Components
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [genaralInformation, setgenaralInformation] =
    useState(GeneralInfoConst);
  const [requiredPrecautions, setRequiredPrecautions] =
    useState(RequiredPreConstant);
  const [verify, setVerify] = useState(VerifyConst);
  const [airMonitoring, setAirMonitoring] = useState(AirMonitoringConst);
  const [fireWatch, setFireWatch] = useState(FireWatchConst);
  const [secondarySign, setSecondarySign] = useState(SecondaryConst);
  const [hotWorkInformation, setHotWorkInformation] =
    useState(HotWorkInfoConst);
  const [activeIndex, setActiveIndex] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  //Reject
  const [showNewModal, setShowNewModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [generalChecked, setGeneralChecked] = useState(false);
  const [hazardChecked, setHazardChecked] = useState(false);
  const navigate = useNavigate();
  const [serverRecordId, setServerRecordId] = useState(null);

  // ToggleValidation
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [generalinfoToggleOn, setGeneralinfoToggleOn] = useState(true);
  const [hotworkToggleOn, setHotworkToggleOn] = useState(true);
  const [requiredToggleOn, setRequiredToggleOn] = useState(true);
  const [verifyPermitToggleOn, setVerifyPermitToggleOn] = useState(true);
  const [airMonitoringToggleOn, setAirMonitoringToggleOn] = useState(true);
  const [fireWatchToggleOn, setFireWatchToggleOn] = useState(true);
  const [secoundoryToggleOn, setSecoundoryToggleOn] = useState(true);
  const [permitIntials, setPermitIntials] = useState(true);
  const [areasToggleOn, setAreasToggleOn] = useState(true);



  const toggleItem = (index) => {
    // debugger;
    setActiveIndex(index === activeIndex ? null : index);
  };

  const PermitUserIdfromURL = searchParams.get("acceptorUserId");
  const IdFromURL = searchParams.get("corePermitID");
  const PermitNamefromURL = searchParams.get("permitName");
  const AditionpermitIDFromURL = searchParams.get("additionalPermitID");
  const mobileNumberfromURL = searchParams.get("mb");
  const [permitStatus, setPermitStatus] = useState("");
  const [permitObject, setPermitObject] = useState(null);
  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    //const IdFromURLwithEDit = searchParams.get('corePermitId');
    var isEdit = "true";
    setIsEdit(isEdit);
    // alert(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id) => {
    try {
      // First, retrieve data from sessionStorage if needed
      const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);

      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);

      if (response.data && response.data.response) {
        const elevated =
          response?.data?.response?.general_hot_Work_permit || [];
        const dataLast = elevated[elevated.length - 1]?.permitObject || null;
        console.log("filteredArray checking", JSON.stringify(dataLast));
        if (
          response.data.response.general_hot_Work_permit != null &&
          response.data.response.general_hot_Work_permit.length > 0
        ) {
          //setIsExpanded(response.data.response.core_permit[0].permitStatus == 'Approve' ? true : false);
          //   setPermitStatus(response.data.response.general_hot_Work_permit[0].permitStatus);
          setPermitStatus(
            response.data.response.general_hot_Work_permit.find(
              (item) => item.permitID === AditionpermitIDFromURL
            )?.permitStatus
          );
        }
        if (dataLast) {
          setPermitObject(dataLast);
          console.log("dataLast", dataLast);
          console.log(response.serverRecordId);
          setGeneralChecked(dataLast.genaralArea);
          setHazardChecked(dataLast.hazardousArea);
          setPermitInitials(
            dataLast?.genaralInformation.permitInitials
              ? dataLast?.genaralInformation.permitInitials
              : permitInitials
          );
          setgenaralInformation(
            dataLast?.genaralInformation
              ? dataLast?.genaralInformation
              : genaralInformation
          );
          setRequiredPrecautions(
            dataLast?.genaralInformation.requiredPrecautions
              ? dataLast?.genaralInformation.requiredPrecautions
              : requiredPrecautions
          );
          setVerify(
            dataLast?.genaralInformation?.verify
              ? dataLast?.genaralInformation?.verify
              : verify
          );
          setAirMonitoring(
            dataLast?.genaralInformation?.airMonitoring
              ? dataLast?.genaralInformation?.airMonitoring
              : airMonitoring
          );
          setFireWatch(
            dataLast?.genaralInformation?.fireWatch
              ? dataLast?.genaralInformation?.fireWatch
              : fireWatch
          );
          setSecondarySign(
            dataLast?.genaralInformation?.secondarySign
              ? dataLast?.genaralInformation?.secondarySign
              : secondarySign
          );
          setHotWorkInformation(
            dataLast?.genaralInformation?.hotWorkInformation
              ? dataLast?.genaralInformation?.hotWorkInformation
              : hotWorkInformation
          );


          //Toggle Values 
          setGeneralinfoToggleOn(
            dataLast && genaralInformation
              ? generalinfoToggleOn
                ? dataLast?.genaralInformation?.generalinfoToggleOn
                : generalinfoToggleOn
              : generalinfoToggleOn
          );

          setHotworkToggleOn(
            dataLast && genaralInformation
              ? hotworkToggleOn
                ? dataLast?.genaralInformation?.hotworkToggleOn
                : hotworkToggleOn
              : hotworkToggleOn
          );

          setRequiredToggleOn(
            dataLast && genaralInformation
              ? requiredToggleOn
                ? dataLast?.genaralInformation?.requiredToggleOn
                : requiredToggleOn
              : requiredToggleOn
          );

          setVerifyPermitToggleOn(
            dataLast && genaralInformation
              ? verifyPermitToggleOn
                ? dataLast?.genaralInformation?.verifyPermitToggleOn
                : verifyPermitToggleOn
              : verifyPermitToggleOn
          );
          setAirMonitoringToggleOn(
            dataLast && genaralInformation
              ? airMonitoringToggleOn
                ? dataLast?.genaralInformation?.airMonitoringToggleOn
                : airMonitoringToggleOn
              : airMonitoringToggleOn
          );

          setFireWatchToggleOn(
            dataLast && genaralInformation
              ? fireWatchToggleOn
                ? dataLast?.genaralInformation?.fireWatchToggleOn
                : fireWatchToggleOn
              : fireWatchToggleOn
          );

          setSecoundoryToggleOn(
            dataLast && genaralInformation
              ? secoundoryToggleOn
                ? dataLast?.genaralInformation?.secoundoryToggleOn
                : secoundoryToggleOn
              : secoundoryToggleOn
          );
          setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleAprrovedOk = async (e) => {
    e.preventDefault();
    setStatus("");
    setIsLoading(false);
    setShowApproved(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleApproved = async (e) => {
    const checkedRequiredPrecautionsyes = requiredPrecautions.list.filter(
      (item) => item.cbYes === "true"
    );
    const checkedRequiredPrecautionsNa = requiredPrecautions.list.filter(
      (item) => item.cbNA === "true"
    );

    const isRequiredPrecationsCheckedyes =
      checkedRequiredPrecautionsyes.length >= 1
        ? "true"
        : checkedRequiredPrecautionsyes.length === 0 &&
          checkedRequiredPrecautionsNa.length === 0
          ? ""
          : "false";

    const isRequiredPrecationsCheckedNa =
      checkedRequiredPrecautionsNa.length >= 1
        ? "true"
        : checkedRequiredPrecautionsyes.length === 0 &&
          checkedRequiredPrecautionsNa.length === 0
          ? ""
          : "false";

    // verify permit issuer
    const checkedVerifyyes = verify.list.filter(
      (item) => item.cbYes === "true"
    );
    const checkedVerifyNa = verify.list.filter((item) => item.cbNA === "true");

    const ispermitissuerCheckedyes =
      checkedVerifyyes.length >= 1
        ? "true"
        : checkedVerifyyes.length === 0 && checkedVerifyNa.length === 0
          ? ""
          : "false";

    const ispermitissuerCheckedNa =
      checkedVerifyNa.length >= 1
        ? "true"
        : checkedVerifyyes.length === 0 && checkedVerifyNa.length === 0
          ? ""
          : "false";

    const airMonitoringList = airMonitoring.list.filter(
      (item) => item.cbValue === "true"
    );
    const listChecked = airMonitoringList.length >= 1 ? "true" : "";

    const fireWatchList = fireWatch.fireWatch.filter(
      (item) => item.cbValue === "true"
    );
    const fireWatchListChecked = fireWatchList.length >= 1 ? "true" : "";



    var valuesToCheck = [];

    if (permitInitials) {
      const updatedValues = [...valuesToCheck];
      updatedValues.push({
        permitInitials: {
          initialsOne: permitInitials.initialsOne || "",
          permitsOne: permitInitials.permitsOne || "",
        },
      });
      valuesToCheck = [...updatedValues];
    }

    if (areasToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        areas: {
          genaralArea: generalChecked,
          hazardousArea: hazardChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (generalinfoToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        genaralInformation: {
          phoneNumber: genaralInformation.phoneNumber,
          assemblyLocation: genaralInformation.assemblyLocation,
        },
      };
      valuesToCheck = [...updatedValues];
    }
    if (hotworkToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        hotWorkInformation: {
          hotWorkLocation: hotWorkInformation.hotWorkLocation,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (requiredToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        requiredPrecautions: {
          PrecautionscbYes: isRequiredPrecationsCheckedyes,
          PrecautionscbNA: isRequiredPrecationsCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (verifyPermitToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        verify: {
          verifypermitissuecbYes: ispermitissuerCheckedyes,
          verifypermitissuecbNA: ispermitissuerCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (airMonitoringToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        airMonitoring: {
          list: listChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (fireWatchToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        fireWatch: {
          fireWatch: fireWatchListChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (secoundoryToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        secondarySign: {
          name: secondarySign.name,
        },
      };
      valuesToCheck = [...updatedValues];
    }
    console.log("valuesToCheck" + valuesToCheck);
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {
        case "areas":
          alert("Fill the areas Data");
          allCasesValid = false;
          return;

        case "permitInitials":
          if (!permitInitials.initialsOne || !permitInitials.permitsOne) {
            alert("Please Enter Initials and Permits");
            allCasesValid = false;
          }
          break;

        case "genaralInformation":
          if (generalinfoToggleOn) {
            alert("Fill the GenaralInformation Data");
            allCasesValid = false;
          }
          break;

        case "hotWorkInformation":
          if (hotworkToggleOn) {
            alert("Please Fill The HotWorkInformation Data");
            allCasesValid = false;
          }
          break;

        case "requiredPrecautions":
          if (requiredToggleOn) {
            alert("Please Fill The RequiredPrecautions Data");
            allCasesValid = false;
          }
          break;

        case "verify":
          if (verifyPermitToggleOn) {
            alert("Please Fill The verify Data");
            allCasesValid = false;
          }
          break;

        case "airMonitoring":
          if (airMonitoringToggleOn) {
            alert("Please Fill The AirMonitoring Data");
            allCasesValid = false;
          }
          break;

        case "fireWatch":
          if (fireWatchToggleOn) {
            alert("Please Fill The FireWatch Data");
            allCasesValid = false;
          }
          break;

        case "secondarySign":
          if (secoundoryToggleOn) {
            alert("Please Fill The SecondarySign Data");
            allCasesValid = false;
          }
          break;
      }
    }
    if (e == "Submitted") {
      if (allCasesValid) {
        const userProfileData = {
          id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
          mobileNumber: mobileNumberfromURL,
          name: "",
          corePermitID: IdFromURL ? IdFromURL : 0,
          additionalPermitName: "general_hot_Work_permit",
        };
        const finalJson = {
          request: {
            // genaralArea: false,
            // hazardousArea: false,
            genaralArea: generalChecked,
            hazardousArea: hazardChecked,
            genaralInformation: {
              ...genaralInformation,
              requiredPrecautions,
              verify,
              airMonitoring,
              fireWatch,
              hotWorkInformation,
              permitInitials,
              secondarySign,
              generalinfoToggleOn,
              hotworkToggleOn,
              requiredToggleOn,
              verifyPermitToggleOn,
              airMonitoringToggleOn,
              fireWatchToggleOn,
              secoundoryToggleOn,
            },
          },
          ...userProfileData,
        };
        setIsLoading(true);
        console.log(finalJson, "finalJson***");
        const response1 = await axios.post(saveAdditionalPermit, finalJson);
        setIsLoading(false);
        if (response1.data.statusCode == 200) {
          const ApproveData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL,
            status: "Approve",
            deviceType: "IOS, Andriod",
            remarks: "",
          };
          setIsLoading(true);
          const response = await axios.post(ApproveRejectPermit, ApproveData);
          console.log("responce", response);

          setTimeout(() => {
            setIsLoading(false);
            setApiStatus(response.data.message);
            setShowApproved(true);
          }, 3000);
        }
      }
    } else if (e == "Approve") {
      if (allCasesValid) {
        handleSubmit();
      }
    }
  };



  const handleReject = (e) => {
    e.preventDefault();
    if (!permitInitials.initialsOne || !permitInitials.permitsOne.trim()) {
      alert("Please Enter The Intials and Permits Details");
      return;
    }
    setShowReject(true);
    setRemarks("");
    setIsLoading(false);
  };


  const handleConfirmationOk = () => {
    setShowReject(false);
    setShowConfirmationPopup(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };
  const handleNewModalOk = () => {
    setShowNewModal(true);

    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage("GoBack")
    // }
  };

  const handleCancelReject = () => {
    setShowReject(false);
  }

  const handleRejectOk = async (e) => {
    try {
      e.preventDefault();

      // Validate remarks input
      if (!remarks.trim()) {
        alert("Please Enter Remarks Before Proceeding.");
        return;
      }

      const userProfileData = {
        id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
        mobileNumber: mobileNumberfromURL,
        name: "",
        corePermitID: IdFromURL ? IdFromURL : 0,
        additionalPermitName: "general_hot_Work_permit",
      };

      const finalJson = {
        request: {
          genaralArea: generalChecked,
          hazardousArea: hazardChecked,
          genaralInformation: {
            ...genaralInformation,
            requiredPrecautions,
            verify,
            airMonitoring,
            fireWatch,
            hotWorkInformation,
            permitInitials,
            secondarySign,
            generalinfoToggleOn,
            hotworkToggleOn,
            requiredToggleOn,
            verifyPermitToggleOn,
            airMonitoringToggleOn,
            fireWatchToggleOn,
            secoundoryToggleOn,
          },
        },
        ...userProfileData,
      };

      setIsLoading(true);
      try {
        // Save the CorePermit data
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log("Core permit response:", response);

        if (response.data.statusCode === "200") {
          const RejectData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL || "0",
            status: "Reject",
            deviceType: "iOS, Android",
            remarks: remarks,
          };

          // Send rejection data
          const rejectResponse = await axios.post(ApproveRejectPermit, RejectData);
          console.log("Rejection response:", rejectResponse);

          if (rejectResponse.data.statusCode !== "200") {
            setShowConfirmationPopup(true);
            setApiStatus(rejectResponse.data.message);
          } else {
            setShowConfirmationPopup(true);
            setApiStatus("Rejection successful!");
          }
        }
      } catch (err) {
        console.error("Error in submission:", err);
      } finally {
        setIsLoading(false);
        setShowReject(false);
      }
    } catch (error) {
      console.error("Error in handleRejectOk:", error);
    }
  };



  const resetForm = () => {
    setgenaralInformation(GeneralInfoConst);
    setRequiredPrecautions(RequiredPreConstant);
    setVerify(VerifyConst);
    setAirMonitoring(AirMonitoringConst);
    setFireWatch(FireWatchConst);
    setSecondarySign(SecondaryConst);
    setHotWorkInformation(HotWorkInfoConst);
    setFinalSubmit(false);
  };

  const handleGeneralInfoToggleItems = () => {
    setGeneralinfoToggleOn(!generalinfoToggleOn);
  }
  const handleHotWorkToggleItems = () => {
    setHotworkToggleOn(!hotworkToggleOn)
  }
  const handleRequiredPrecautionsToggleItems = () => {
    setRequiredToggleOn(!requiredToggleOn)
  }

  const handleVerifyPermitToggleItems = () => {
    setVerifyPermitToggleOn(!verifyPermitToggleOn)
  }
  const handleAirMonitoringToggleItems = () => {
    setAirMonitoringToggleOn(!airMonitoringToggleOn)
  }

  const handleFireWatchToggleItems = () => {
    setFireWatchToggleOn(!fireWatchToggleOn)
  }
  const handleSecondorySignatureToggleItems = () => {
    setSecoundoryToggleOn(!secoundoryToggleOn)
  }


  const handleSubmit = async () => {
    // event.preventDefault();
    const userProfileData = {
      id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
      mobileNumber: mobileNumberfromURL,
      name: "",
      corePermitID: IdFromURL ? IdFromURL : 0,
      additionalPermitName: "general_hot_Work_permit",
    };
    const finalJson = {
      request: {
        // genaralArea: false,
        // hazardousArea: false,
        genaralArea: generalChecked,
        hazardousArea: hazardChecked,
        genaralInformation: {
          ...genaralInformation,
          requiredPrecautions,
          verify,
          airMonitoring,
          fireWatch,
          hotWorkInformation,
          permitInitials,
          secondarySign,
          generalinfoToggleOn,
          hotworkToggleOn,
          requiredToggleOn,
          verifyPermitToggleOn,
          airMonitoringToggleOn,
          fireWatchToggleOn,
          secoundoryToggleOn,
        },
      },
      ...userProfileData,
    };
    setIsLoading(true);
    console.log(finalJson, "finalJson***");
    const response = await axios.post(saveAdditionalPermit, finalJson);
    setIsLoading(false);
    if (response.data.statusCode == 200) {
      setApiStatus(response.data.message);
      setShowApproved(true);
    }
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}
        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          {finalSubmit ? (
            <>
              <h2>Form Submitted Successfully!!!</h2>
            </>
          ) : (
            <div id="content">
              {/* 
                        <Header /> */}
              <div
                className="row"
                style={{ backgroundColor: "blue", padding: "7px" }}
              ></div>
              <div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between"></div>
                  <div className="row">
                    <div className="d-flex flex-row">
                      <div style={{ backgroundColor: "#FFE143" }}>
                        <div
                          class="form-check form-check-inline d-flex align-items-center"
                          style={{
                            height: "0.5px",
                            backgroundColor: "#FBE517",
                            paddingRight: "11px",
                            marginLeft: "5px",
                          }}
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="generalarea"
                            checked={generalChecked}
                            onChange={(e) => {
                              const generalCheckedValue = e.target.checked;
                              // if (generalCheckedValue === false) {
                              //   setGeneralChecked("");
                              //   setHazardChecked("");
                              // } else {
                              //   setGeneralChecked(generalCheckedValue);
                              //   setHazardChecked(false);
                              // }
                              if (!generalCheckedValue) {
                                setHazardChecked(false);
                                setGeneralChecked(false);
                              } else {
                                setGeneralChecked(true);
                                setHazardChecked(false);
                              }
                            }}
                          />
                          <label
                            class="form-check-label"
                            style={{ fontWeight: "bold" }}
                          >
                            GENERAL AREA
                          </label>
                        </div>
                      </div>
                      <div style={{ backgroundColor: "#ED8754" }}>
                        <div
                          class="form-check form-check-inline d-flex align-items-center"
                          style={{
                            height: "0.5px",
                            backgroundColor: "#ED8754",
                            paddingRight: "11px",
                            marginLeft: "5px",
                          }}
                        >
                          {/* <input
                            class="form-check-input"
                            type="checkbox"
                            id="hazardarea"
                            name="area"
                            checked={hazardChecked}
                            onChange={(e) => {
                              const hazardCheckedValue = e.target.checked;
                              if (hazardCheckedValue === false) {
                                setHazardChecked("");
                                setGeneralChecked("");
                              } else {
                                setHazardChecked(hazardCheckedValue);
                                setGeneralChecked(false);
                              }
                            }}
                          /> */}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="hazardarea"
                            name="area"
                            checked={hazardChecked}
                            onChange={(e) => {
                              const hazardCheckedValue = e.target.checked;
                              if (!hazardCheckedValue) {
                                setHazardChecked(false);
                                setGeneralChecked(false);
                              } else {
                                setHazardChecked(true);
                                setGeneralChecked(false);
                              }
                            }}
                          />
                          <label
                            class="form-check-label"
                            style={{ fontWeight: "bold" }}
                          >
                            HAZARD AREA
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <form className="HotWorkPermit-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        setPermitInitials={setPermitInitials}
                        editable={true}
                        onDataChange={(data) => {
                          setPermitInitials(data);
                        }}
                      />

                      <Accordion
                        title="GENERAL INFORMATION"
                        bgColor="#34B85F"
                        handleToggle={handleGeneralInfoToggleItems}
                        isToggled={generalinfoToggleOn}
                        content={
                          <GeneralInformation
                            data={genaralInformation}
                            setgenaralInformation={setgenaralInformation}
                            generalinfoToggleOn={generalinfoToggleOn}
                            onDataChange={(data) => {
                              setgenaralInformation(data);
                            }}
                          />
                        }
                        bordercolor="#34B85F"
                        showToggle={true}

                      />
                      <Accordion
                        title="HOT WORK INFORMATION"
                        bgColor="#34B85F"
                        handleToggle={handleHotWorkToggleItems}
                        isToggled={hotworkToggleOn}
                        content={
                          <HotWorkInformation
                            data={hotWorkInformation}
                            hotworkToggleOn={hotworkToggleOn}
                            setHotWorkInformation={setHotWorkInformation}
                            onDataChange={(data) => {
                              setHotWorkInformation(data);
                            }}
                          />
                        }
                        bordercolor="#34B85F"
                        showToggle={true}
                      />
                      <Accordion
                        title="REQUIRED PRECAUTIONS"
                        bgColor="#184DA9"
                        handleToggle={handleRequiredPrecautionsToggleItems}
                        isToggled={requiredToggleOn}
                        content={
                          <RequiredPrecautions
                            data={requiredPrecautions}
                            requiredToggleOn={requiredToggleOn}
                            setRequiredPrecautions={setRequiredPrecautions}
                            onDataChange={(data) => {
                              setRequiredPrecautions(data);
                            }}
                          />
                        }
                        bordercolor="#184DA9"
                        showToggle={true}

                      />
                      <Accordion
                        title="VERIFY:PERMIT ISSUER"
                        bgColor="#2C2E35"
                        handleToggle={handleVerifyPermitToggleItems}
                        isToggled={verifyPermitToggleOn}
                        content={
                          <VerifyPermitIssuer
                            data={verify}
                            verifyPermitToggleOn={verifyPermitToggleOn}
                            setVerify={setVerify}
                            onDataChange={(data) => {
                              setVerify(data);
                            }}
                          />
                        }
                        bordercolor="#2C2E35"
                        showToggle={true}
                      />
                      <Accordion
                        title="AIR MONITORING"
                        bgColor="#34B85F"
                        handleToggle={handleAirMonitoringToggleItems}
                        isToggled={airMonitoringToggleOn}
                        content={
                          <AirMonitoring
                            data={airMonitoring}
                            airMonitoringToggleOn={airMonitoringToggleOn}
                            setAirMonitoring={setAirMonitoring}
                            onDataChange={(data) => {
                              setAirMonitoring(data);
                            }}
                          />
                        }
                        bordercolor="#34B85F"
                        showToggle={true}
                      />
                      <Accordion
                        title="FIRE WATCH"
                        bgColor="#DF3A1C"
                        handleToggle={handleFireWatchToggleItems}
                        isToggled={fireWatchToggleOn}
                        content={
                          <FireWatch
                            data={fireWatch}
                            fireWatchToggleOn={!fireWatchToggleOn}
                            setFireWatch={setFireWatch}
                            onDataChange={(data) => {
                              setFireWatch(data);
                            }}
                          />
                        }
                        bordercolor="#DF3A1C"
                        showToggle={true}
                      />
                      <Accordion
                        title="SECONDARY SIGNATURE PERMIT ISSUER"
                        bgColor="#FF7E51"
                        handleToggle={handleSecondorySignatureToggleItems}
                        isToggled={secoundoryToggleOn}
                        content={
                          <SecondarySignPermitIssuer
                            data={secondarySign}
                            secoundoryToggleOn={secoundoryToggleOn}
                            setSecondarySign={setSecondarySign}
                            onDataChange={(data) => {
                              setSecondarySign(data);
                            }}
                          />
                        }
                        bordercolor="#FF7E51"
                        showToggle={true}
                      />
                      <div style={{ marginTop: "20px" }}>
                        {permitStatus !== "" && (
                          <button
                            type="button"
                            onClick={() => {
                              handleApproved(permitStatus);
                            }}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#007AFF",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            {permitStatus == "Submitted" ? "Approve" : "Update"}
                          </button>
                        )}
                        {permitStatus == "Submitted" && (
                          <button
                            type="button"
                            onClick={handleReject}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#FC2727",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            Reject
                          </button>
                        )}
                      </div>
                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                  }}
                                >
                                  <img
                                    src={Approved}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                }}
                              >
                                <h5>{apiStatus}</h5>
                                {/* HotWork Permit approved successfully */}
                              </p>
                            </div>
                            {/* <span style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '13vh' }}>

                                                                    <img src={Approved} alt="save"  className="popupicons" />
                                                                </span>
                                                            </div>

                                                            <div className="form-group">
                                                                <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3vh' }}>

                                                                    <h4>HotWork Permit approved successfully</h4>
                                                                </p>
                                                            </div> */}

                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary"
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                style={{
                                  height: "43px",
                                  backgroundColor: "#007AFF",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={handleAprrovedOk}
                              >
                                Ok
                              </Button>
                            </div>
                            {/* <span>{apiStatus}</span> */}
                          </Modal.Body>
                          {/* <Modal.Footer style={{ border: '0' }}>
                                                            <div className="d-flex" style={{ width: '100%', justifyContent: 'center' }} >

                                                                <Button
                                                                    variant="primary"
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                    style={{ height: "43px", backgroundColor: '#007AFF', color: 'white', border: "none" }}
                                                                    onClick={handleAprrovedOk}
                                                                >
                                                                    Ok
                                                                </Button>
                                                            </div>
                                                            <span>{apiStatus}</span>
                                                        </Modal.Footer > */}
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >

                          <Modal.Header className="no-border">
                            <Button
                              variant="link"
                              className="close btn btn-danger close_btn"
                              onClick={handleCancelReject}
                            >
                              X
                            </Button>
                          </Modal.Header>

                          <Modal.Body>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                  }}
                                >
                                  <img
                                    src={Reject}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                }}
                              >
                                <h5>Are you sure want to Reject?</h5>
                              </p>
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">
                                <h6>Reason:</h6>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "8vh",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                  style={{
                                    height: "43px",
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={handleRejectOk}
                                  disabled={remarks.trim() === ""}
                                >
                                  Ok
                                </Button>
                              </p>
                            </div>

                            <Modal
                              show={showNewModal}
                              onHide={() => setShowNewModal(false)}
                              size="sm"
                              id="newModal"
                              className="ppopup"
                            >
                              <Modal.Body>
                                <div className="row">
                                  <div>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "15vh",
                                      }}
                                    >
                                      <img
                                        src={Approved}
                                        alt="save"
                                        className="popupicons"
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "3vh",
                                      }}
                                    >
                                      <h5>
                                        HotWorkPermit Rejected Successfully
                                      </h5>
                                    </p>
                                  </div>

                                </div>

                                <Button
                                  variant="primary"
                                  onClick={handleNewModalOk}
                                >
                                  Ok
                                </Button>
                              </Modal.Body>
                            </Modal>
                          </Modal.Body>
                        </Modal>
                        <Modal
                          show={showConfirmationPopup}
                          onHide={() => setShowConfirmationPopup(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <span
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "13vh",
                                }}
                              >
                                <img
                                  src={Reject}
                                  alt="save"
                                  className="popupicons"
                                />
                              </span>
                            </div>

                            <div className="form-group">
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                  color: "green",
                                }}
                              >
                                <h5>HotWorkPermit Rejected Successfully</h5>
                              </p>
                            </div>

                            {/* <Modal.Footer style={{ border: '0' }}> */}
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary"
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                style={{
                                  height: "43px",
                                  backgroundColor: "#007AFF",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={handleConfirmationOk}
                              >
                                Ok
                              </Button>
                            </div>

                            {/* </Modal.Footer > */}
                          </Modal.Body>
                        </Modal>
                      </div>
                    </form>
                    {/* </div>
                                            </div> */}
                    {/* </div>
                                    </div> */}
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          )}
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div>
      {/* {isLoading && <Loader_MS loading={isLoading} message={"Please Wait..!"} />} */}
      {/* {isLoading && <Loader loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage}/>} */}
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default GeneralHotWork2Additional;
