import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {   faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button, } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import { FarmOperation_getMasters,  getMasters,  PlotAcrageMasterAdd,  PlotAcrageMasterGet,   } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import ReactSelect from 'react-select';

export const PlotAcrageMaster = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [getPlotLocation, setGetPlotLocation] = useState([])
    const [name, setName] = useState()
    const [locationName, setLocationName] = useState()
    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg , setResponseMsg]= useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [locationError, setLocationError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)
    const [description, setDescription] = useState()
    const [getSubSiteData, setGetSubSiteData] = useState([]) 
    const [setectedId, setSelectedId] = useState()
    const [subSiteError, setSubSiteError] =useState('')

    const getData = async () => {
        const URL = PlotAcrageMasterGet;
        try {
            setLoader(true)
            const response = await axios.get(URL) 
            setData(response.data.response)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    }   
    const getMasterDataSubsite = async () => {
        const apiGetmaster = getMasters;
        const headers = await GetApiHeaders();
        try {
          const response = await axios.post(apiGetmaster, {}, { headers }); 
          setGetSubSiteData(response.data.response.storeLocation);
        } catch (error) {
          console.log(error);
        }
      };

    const PlotLocationDropdown = async () =>{
        const apiUrl = FarmOperation_getMasters;
        const headers = await GetApiHeaders();
        try { 
            const response = await axios.get(apiUrl, {headers}) 
            setGetSubSiteData(response.data.response.subSiteMaster);
            const allPlotLocation = response.data.response.plotLocationMaster
            const filteredPlotLocations = allPlotLocation.filter((item) => item.subSiteId === parseInt(setectedId) );
            console.log(filteredPlotLocations,"filteredPlotLocations")
            setGetPlotLocation(filteredPlotLocations)
            // => item.subSiteId === setectedId
        } catch (error) {
            console.log(error)
        }
    } 
    useEffect(() => {
        getData()
        getMasterDataSubsite()
    }, [])
console.log(getPlotLocation, "getPlotLocation");

    const headersData = [
        {
            field: "storeLocations.name",
            header: "Sub Site",
        },
        {
            field: "name",
            header: "Plote Acrage",
        },
        {
            field: "plotLocationMasterId.name",
            header: "Plot Location ",
        },
        {
            field: "createdBy.firstName",
            header: "Created By",
        },
        {
            field: "description",
            header: "Description",
        },
      
        {
            field: "active",
            header: "Status",
        },
    ];

    const onEditClicked = (data) => {  
        console.log(data,'data')
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.name)
        setSelectedId(data.storeLocations.id)
        setDescription(data.description)
        setLocationName(data.plotLocationMasterId.id) 
        setStatus(data.active)
        setError('')
        setLocationError('')
        setStatusError('')
        setSubSiteError('')
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('')
        setLocationName('')
        setStatus('')
        setDescription('')
        setSelectedId('')
        setError('')
        setLocationError('')
        setStatusError('')
        setSubSiteError('')
    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) =>{
        const Input = e.target.value
        const numericValue = Input.replace(/[^0-9]/g, '');
        setName(numericValue)
        if (Input) {
            setError('')
        }
    }

    const handleLocation = (selectedOption) =>{ 
        if (selectedOption) {
            setLocationName(selectedOption.value);  
            setLocationError('');  
        } else {
            setLocationName('');  
        }
    }
    const handleSubsite = (e) =>{
        const selectedId = e.target.value
        console.log(selectedId)
        setSelectedId(selectedId)
        if(selectedId){
            setSubSiteError('')
        }
    }
    useEffect(()=>{
        PlotLocationDropdown()
    },[setectedId])

    const handleStatus = (e) =>{
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }

    const handleDescription = (e) =>{
        const Input = e.target.value
        setDescription(Input)
        
    }

    const handleSubmitData = async () =>{ 
        const apiUrl = PlotAcrageMasterAdd;
        const reqData = { 
            id: saveEdit === "Submit" ? 0 : id,
            name: name,
            plotLocationId : parseInt(locationName), 
            storeLocationId:parseInt(setectedId),
            description : description,
            status  :  status 
         }
         const headers = await GetApiHeaders() 
         try {
            if (!status) {
                setStatusError("Select Status")
            }
            if (!locationName) {
                setLocationError('Select Location')
            }
            if (!name) {
                setError('Enter Plot Acreage')   
            }
            if (!setectedId) {
                setSubSiteError('Select SubSite')   
            }
            if (name !=='' && locationName !=='' && status !=='' && setectedId !=='') {
                setLoader(true)
                const response = await axios.post(apiUrl,reqData, {headers} )
             if (response.data.statusCode == 200 || response.data.status == 500) {
                 setShowModal(false)
                 setMsgPopup(true)
                 setResponseMsg(response.data.message);
                 setLoader(false)
             }
            }
         } catch (error) {
            console.log(error )
         }
    }
    const handleOk = () =>{
        setMsgPopup(false)
        getData()
    }

    const plotlocation = getPlotLocation
    ? getPlotLocation
          .filter((item) => item)
          .map((item) =>({ value: item.code, label: item.name }) )
    : [];

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">  Master</h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text"> Plot Acreage Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add Plot Acreage Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader = {loader}
                                            FileName={'Plot_Acrage'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'Plot Acreage Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>
                                                        
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1">
                                                               Sub Site <span style={{ color: "red" }}>*</span> 
                                                            </label>
                                                           
                                                            <select id="dropdown" type='dropdown' className="form-control" value={setectedId} onChange={handleSubsite}> 
                                                                <option value=''>Select Sub Site</option>
                                                                {
                                                                    getSubSiteData.map((item,index)=>
                                                                    <option key={item.code} value={item.code} >
                                                                        {item.name}
                                                                    </option> )
                                                                }
                                                            </select>
                                                             <span style={{ color: "red" }}> {subSiteError}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1">
                                                               Plot Location <span style={{ color: "red" }}>*</span> 
                                                            </label>
                                                            {/* <select  id="dropdown"  type="dropdown"  className="form-control" value={locationName}onChange={handleLocation}>
                                                                <option value="" disabled>Select Location Name</option> 
                                                                {
                                                                    getPlotLocation.map((item,index)=>
                                                                    <option key={item.code} value={item.code} >
                                                                        {item.name}
                                                                    </option> )
                                                                }
                                                            </select> */}
                                                                <ReactSelect
                                                                id="dropdown"
                                                                className="w-100"
                                                                options={plotlocation}
                                                                value={plotlocation.find((option) => option.value === locationName ) || null} // Correctly bind the selected value    option.value === mainFunctionId
                                                                onChange={handleLocation}
                                                                placeholder="Select Location"
                                                                isClearable
                                                                isSearchable
                                                            />
                                                             <span style={{ color: "red" }}> {locationError}</span>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                Plot Acreage <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter Plot Acreage 'value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12'  >
                                                                <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                                >Status <span style={{ color: "red" }}>*</span> </label>
                                                                <select  id="dropdown"  type="dropdown"  className="form-control p-2" value={status} onChange={handleStatus}>
                                                                    <option value="" disabled>Select Status</option> 
                                                                    <option value={true}>Active</option>
                                                                    <option value={false}>In-Active</option>
                                                                </select>
                                                                <span style={{ color: "red" }}> {statusError}</span>
                                                            </div>


                                                    </div>
                                                    <div className='row'>
                                                    <div className='col-md-6 col-12 '>
                                                                <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                    Description  
                                                                </label>
                                                                <div className="input-group input-group w-100">
                                                                    <textarea  class="form-control"  style={{ height: 112 }} id="textAreaExample1" placeholder='Enter Description'
                                                                      rows="2"  value={description}  onChange={handleDescription} > 
                                                                    </textarea>
                                                                </div> 
                                                            </div> 
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal 
                                         showModal={msgPopup}
                                        msgPopup ={msgPopup}
                                        handleOk= {handleOk}
                                        handleCancel_new={handleCancel_new}
                                        tittle={'Plot Acreage Master'}
                                        childrens={<div className="p-4 d-flex justify-content-center" style={{fontSize:"18px", color:"green", fontWeight:500}}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}