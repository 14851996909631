import React, { useState } from "react";
function ToggleSwitch({ isToggled, handleToggleToggleClicked }) {

    return (
        <div
            className={`toggle-switch ${isToggled ? "on" : "off"}`}
            onClick={handleToggleToggleClicked}
            style={{
                display: "inline-block",
                width: "30px",
                height: "18px",
                borderRadius: "16px",
                backgroundColor: isToggled ? "#4CAF50" : "#ccc",
                position: "relative",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                marginLeft: "-5px",
            }}
        >
            <div
                style={{
                    width: "12px",
                    height: "13px",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "2px",
                    left: isToggled ? "calc(100% - 14px)" : "2px",
                    transition: "left 0.3s ease",
                }}
            ></div>
        </div>
    );
}

export default ToggleSwitch;
