import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import { Card } from "react-bootstrap";
import {
  corePermitInitialsConst,
  ElevatedWork,
  EmergencyConst,
  HazardIdentConst,
  Mitigation,
  RoofAccessConstant,
  FallRescueConstant,
  CommentsConst,
  rWorkPermit,
  SignatureConstant,
} from "../Utilities/criticalElevated-JSON";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  MOBILE_NUMBER,
  USER_PROFILE,
  retrieveData,
  ADDITIONAL_PERMITID,
  PERMIT_NAME,
  checkForEmptyValue,
} from "../Utils";
import CorePermitInputFields from "../components/criticalElevatedWork/corePermitInputFields";
import Emergency from "../components/criticalElevatedWork/Emergency";
import ScopeOfElevatedWork from "../components/criticalElevatedWork/ScopeOfElevatedWork";
import FallRescue from "../components/criticalElevatedWork/FallRescue";
import CoreRoofAccess from "../components/criticalElevatedWork/RoofAccess";
import HazardIdentific from "../components/criticalElevatedWork/HazardIdentification";
import HazardMitigation from "../components/criticalElevatedWork/HazardMitigation";
import ReviedWork from "../components/criticalElevatedWork/ReviedWork";
import Signatures from "../components/criticalElevatedWork/Signatures";
import AdditionalComments from "../components/criticalElevatedWork/AdditionalComments";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import Accordion from "../Accordation/Accordian";

//Approve
import Approved from "../images/Approved.png";
import Reject from "../images/Reject.png";
import Loader_Bulk from "../Utilities/Loader_Bulk";

const CriticalElevated2Additional = (props) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [emergency, setEmergency] = useState(EmergencyConst);
  const [scopeOfElevatedWork, setScopeOfElevatedWork] = useState(ElevatedWork);
  const [hazardIdentification, setHazardIdentification] =
    useState(HazardIdentConst);
  const [hazardMitigation, setHazardMitigation] = useState(Mitigation);
  const [roofAccess, setRoofAccess] = useState(RoofAccessConstant);
  const [fallRescue, setFallRescue] = useState(FallRescueConstant);
  const [additionalComments, setAdditionalComments] = useState(CommentsConst);
  const [terms, setTerms] = useState(rWorkPermit);
  const [signature, setSignature] = useState(SignatureConstant);
  const [serverRecordId, setServerRecordId] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  //Reject
  const [showNewModal, setShowNewModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const IdFromURL = searchParams.get("corePermitID");
  const PermitUserIdfromURL = searchParams.get("acceptorUserId");
  const PermitNamefromURL = searchParams.get("permitName");
  const AditionpermitIDFromURL = searchParams.get("additionalPermitID");
  const mobileNumberfromURL = searchParams.get("mb");
  const [permitStatus, setPermitStatus] = useState("");
  const [permitObject, setPermitObject] = useState(null);


  // validation
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [scopOfElevatedToggleOn, setScopOfElevatedToggleOn] = useState(true);
  const [hazardIdentificationToggleOn, setHazardIdentificationToggleOn] = useState(true);
  const [hazardMitigationToggleOn, setHazardMitigationToggleOn] = useState(true);
  const [roofAccessToggleOn, setRoofAccessToggleOn] = useState(true);
  const [roofRescueToggleOn, setRoofRescueToggleOn] = useState(true);
  const [additionalToggleOn, setAdditionalToggleOn] = useState(true);
  const [reviewdToggleOn, setReviewdToggleOn] = useState(true);
  const [sighnToggleOn, setSighnToggleOn] = useState(true);
  const [permitIntials, setPermitIntials] = useState(true);

  useEffect(() => {
    getFormData();
    return () => { };
  }, []);

  const resetForm = () => {
    setEmergency(EmergencyConst);
    setScopeOfElevatedWork(ElevatedWork);
    setHazardIdentification(HazardIdentConst);
    setHazardMitigation(Mitigation);
    setRoofAccess(RoofAccessConstant);
    setFallRescue(FallRescueConstant);
    setAdditionalComments(CommentsConst);
    setTerms(rWorkPermit);
    setSignature(SignatureConstant);
    setFinalSubmit(false);
  };

  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);

    setIsEdit(isEdit);

    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id) => {
    console.log(id);
    try {
      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });

      console.log("response data:", response.data);
      // Check if the response contains data
      if (response.data && response.data.response) {
        const elevated =
          response?.data?.response?.critical_elevated_permit || [];
        const dataLast = elevated[elevated.length - 1]?.permitObject || null;
        console.log("filteredArray checking", JSON.stringify(dataLast));
        if (
          response.data.response.critical_elevated_permit != null &&
          response.data.response.critical_elevated_permit.length > 0
        ) {
          setPermitStatus(
            response.data.response.critical_elevated_permit.find(
              (item) => item.permitID === AditionpermitIDFromURL
            )?.permitStatus
          );
        }
        // Set state values based on the retrieved data
        if (dataLast) {
          setPermitObject(dataLast);
          setPermitInitials(
            dataLast?.permitInitials ? dataLast?.permitInitials : permitInitials
          );
          setEmergency(dataLast?.emergency ? dataLast?.emergency : emergency);
          setScopeOfElevatedWork(
            dataLast?.scopeOfElevatedWork
              ? dataLast?.scopeOfElevatedWork
              : scopeOfElevatedWork
          );
          setHazardIdentification(
            dataLast?.hazardIdentification
              ? dataLast?.hazardIdentification
              : hazardIdentification
          );
          setHazardMitigation(
            dataLast?.hazardMitigation
              ? dataLast?.hazardMitigation
              : hazardMitigation
          );
          setRoofAccess(
            dataLast?.roofAccess ? dataLast?.roofAccess : roofAccess
          );
          setFallRescue(
            dataLast?.fallRescue ? dataLast?.fallRescue : fallRescue
          );
          setAdditionalComments(
            dataLast?.additionalComments
              ? dataLast?.additionalComments
              : additionalComments
          );
          setTerms(dataLast?.terms ? dataLast?.terms : terms);
          setSignature(dataLast?.signature ? dataLast?.signature : signature);
          //Toggles
          setEmergencyToggleOn(dataLast.emergencyToggleOn !== undefined ? dataLast.emergencyToggleOn : emergencyToggleOn);
          setScopOfElevatedToggleOn(dataLast.scopOfElevatedToggleOn !== undefined ? dataLast.scopOfElevatedToggleOn : scopOfElevatedToggleOn);
          setHazardIdentificationToggleOn(dataLast.hazardIdentificationToggleOn !== undefined ? dataLast.hazardIdentificationToggleOn : hazardIdentificationToggleOn);
          setHazardMitigationToggleOn(dataLast.hazardMitigationToggleOn !== undefined ? dataLast.hazardMitigationToggleOn : hazardMitigationToggleOn);
          setRoofAccessToggleOn(dataLast.roofAccessToggleOn !== undefined ? dataLast.roofAccessToggleOn : roofAccessToggleOn);
          setRoofRescueToggleOn(dataLast.roofRescueToggleOn !== undefined ? dataLast.roofRescueToggleOn : roofRescueToggleOn);
          setAdditionalToggleOn(dataLast.additionalToggleOn !== undefined ? dataLast.additionalToggleOn : additionalToggleOn);
          setReviewdToggleOn(dataLast.reviewdToggleOn !== undefined ? dataLast.reviewdToggleOn : reviewdToggleOn);
          setSighnToggleOn(dataLast.sighnToggleOn !== undefined ? dataLast.sighnToggleOn : sighnToggleOn);

          setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
          console.log("emergency :: ", emergency);
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleAprrovedOk = async (e) => {
    e.preventDefault();
    setStatus("");
    setIsLoading(false);
    setShowApproved(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };


  //Toggle
  const handleToggleItems = () => {
    setEmergencyToggleOn(!emergencyToggleOn)
  }
  const handleScopeToggleItems = () => {
    setScopOfElevatedToggleOn(!scopOfElevatedToggleOn)
  }

  const handleHazardIdentificationToggleItems = () => {
    setHazardIdentificationToggleOn(!hazardIdentificationToggleOn)
  }
  const handleHazardMitigationToggleItems = () => {
    setHazardMitigationToggleOn(!hazardMitigationToggleOn);
  }

  const handleRoofAccessToggleItems = () => {
    setRoofAccessToggleOn(!roofAccessToggleOn)
  }

  const handleRescueToggleItems = () => {
    setRoofRescueToggleOn(!roofRescueToggleOn)
  }

  const handleAdditionalToggleItems = () => {
    setAdditionalToggleOn(!additionalToggleOn)
  }

  const handleReviewdToggleItems = () => {
    setReviewdToggleOn(!reviewdToggleOn)
  }

  const handleSignatureToggleItems = () => {
    setSighnToggleOn(!sighnToggleOn)
  }

  const handleApproved = async (e) => {
    const hazardList = hazardIdentification.hazard.filter(
      (item) => item.checked
    );
    const hazardChecking = hazardList.length >= 1 ? "true" : "";

    const workAccessList = hazardMitigation.workAccess.filter(
      (item) => item.description
    );
    const workAccessChecking = workAccessList.length >= 1 ? "true" : "";

    const slopeList = roofAccess.slopeType.list.filter((item) => item.checked);
    const slopeListchecked = slopeList.length >= 1 ? "true" : "";

    const fallListcheck = fallRescue.fallList.filter(
      (item) => item.description
    ); //  .fallList.filter(item => item.description);
    const fallListchecked = fallListcheck.length >= 1 ? "true" : "";

    const termsList = terms.list1.filter((item) => item.description);
    const termsListchecked = termsList.length >= 1 ? "true" : "";

    const datesList = signature.dates[0].fromDate;
    const datesList1 = signature.dates[0].toDate;
    const datesListchecked =
      datesList != null &&
        datesList != "" &&
        datesList1 != null &&
        datesList1 != ""
        ? "true"
        : "";

    var valuesToCheck = [];

    if (permitInitials) {
      const updatedValues = [...valuesToCheck];
      updatedValues.push({
        permitInitials: {
          initialsOne: permitInitials.initialsOne || "",
          permitsOne: permitInitials.permitsOne || "",

        },
      });
      valuesToCheck = [...updatedValues];
    }

    if (emergencyToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        emergency: {
          phoneNumber: emergency.phoneNumber,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (scopOfElevatedToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        scopeOfElevatedWork: {
          Company: scopeOfElevatedWork.Company,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (hazardIdentificationToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        hazardIdentification: {
          hazard: hazardChecking,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (hazardMitigationToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        hazardMitigation: {
          workAccess: workAccessChecking,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (roofAccessToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        roofAccess: {
          slopeType: slopeListchecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (roofRescueToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        fallRescue: {
          fallList: fallListchecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (additionalToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        additionalComments: {
          description: additionalComments.description,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (reviewdToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        terms: {
          list1: termsListchecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (sighnToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        signature: {
          dates: datesListchecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {

        case "permitInitials":
          if (!permitInitials.initialsOne || !permitInitials.permitsOne) {
            alert("Please Enter Initials and Permits");
            allCasesValid = false;
          }
          break;

        case "emergency":
          if (emergencyToggleOn) {
            alert("Fill the Emergency Data");
            allCasesValid = false;
          }
          break;

        case "scopeOfElevatedWork":
          if (scopOfElevatedToggleOn) {
            alert("Please Fill The ScopeOfElevatedWork Data");
            allCasesValid = false;
          }
          break;


        case "hazardIdentification":
          if (hazardIdentificationToggleOn) {
            alert("Please Fill The HazardIdentification Data");
            allCasesValid = false;
          }
          break;

        case "hazardMitigation":
          if (hazardMitigationToggleOn) {
            alert("Please Fill The hazardMitigation Data");
            allCasesValid = false;
          }
          break;

        case "roofAccess":
          if (roofAccessToggleOn) {
            alert("Please Fill The RoofAccess Data");
            allCasesValid = false;
          }
          break;


        case "fallRescue":
          if (roofRescueToggleOn) {
            alert("Please Fill The FallRescue Data");
            allCasesValid = false;
          }
          break;


        case "additionalComments":
          if (additionalToggleOn) {
            alert("Please Fill The AdditionalComments Data");
            allCasesValid = false;
          }
          break;

        case "terms":
          if (reviewdToggleOn) {
            alert("Please Fill The Terms Data");
            allCasesValid = false;
          }
          break;


        case "signature":
          if (sighnToggleOn) {
            alert("Please Fill The Signature Data");
            allCasesValid = false;
          }
          break;
      }
    }
    console.log(e, "handleOk");
    if (e == "Submitted") {
      const userProfileData = {
        id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
        mobileNumber: mobileNumberfromURL,
        name: "",
        corePermitID: IdFromURL ? IdFromURL : 0,
        additionalPermitName: "critical_elevated_permit",
      };
      const finalJson = {
        request: {
          permitInitials,
          emergency,
          scopeOfElevatedWork,
          hazardIdentification,
          hazardMitigation,
          roofAccess,
          fallRescue,
          additionalComments,
          terms,
          signature,
          scopOfElevatedToggleOn,
          emergencyToggleOn,
          hazardIdentificationToggleOn,
          hazardMitigationToggleOn,
          roofAccessToggleOn,
          roofRescueToggleOn,
          additionalToggleOn,
          reviewdToggleOn,
          sighnToggleOn,
        },
        ...userProfileData,
      };
      console.log(finalJson, "finalJson***");
      setIsLoading(true);
      const response1 = await axios.post(saveAdditionalPermit, finalJson);

      setIsLoading(false);
      if (response1.data.statusCode == 200) {
        console.log(e, "handleOk");
        if (allCasesValid) {
          const ApproveData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL,
            status: "Approve",
            deviceType: "iOS, Android",
            remarks: "",
          };
          setIsLoading(true);
          const response = await axios.post(ApproveRejectPermit, ApproveData);
          console.log("responce", response);

          setTimeout(() => {
            setIsLoading(false);
            setApiStatus(response.data.message);
            setShowApproved(true);
          }, 3000);
        }
      }
    } else if (e == "Approve") {
      if (allCasesValid) {
        handleSubmit();
      }
    }
  };



  const handleReject = (e) => {
    e.preventDefault();
    if (!permitInitials.initialsOne || !permitInitials.permitsOne.trim()) {
      alert("Please Enter The Intials and Permits Details");
      return;
    }
    setShowReject(true);
    setRemarks("");
    setIsLoading(false);
  };


  const handleConfirmationOk = () => {
    setShowReject(false);
    setShowConfirmationPopup(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleNewModalOk = () => {
    setShowNewModal(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleCancelReject = () => {
    setShowReject(false);
  }



  const handleRejectOk = async (e) => {
    try {
      e.preventDefault();

      // Validate remarks input
      if (!remarks.trim()) {
        alert("Please Enter Remarks Before Proceeding.");
        return;
      }

      const userProfileData = {
        id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
        mobileNumber: mobileNumberfromURL,
        name: "",
        corePermitID: IdFromURL ? IdFromURL : 0,
        additionalPermitName: "critical_elevated_permit",
      };

      const finalJson = {
        request: {
          permitInitials,
          emergency,
          scopeOfElevatedWork,
          hazardIdentification,
          hazardMitigation,
          roofAccess,
          fallRescue,
          additionalComments,
          terms,
          signature,
          scopOfElevatedToggleOn,
          emergencyToggleOn,
          hazardIdentificationToggleOn,
          hazardMitigationToggleOn,
          roofAccessToggleOn,
          roofRescueToggleOn,
          additionalToggleOn,
          reviewdToggleOn,
          sighnToggleOn,
        },
        ...userProfileData,
      };

      setIsLoading(true);
      try {
        // Save the CorePermit data
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log("Core permit response:", response);

        if (response.data.statusCode === "200") {
          const RejectData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL || "0",
            status: "Reject",
            deviceType: "iOS, Android",
            remarks: remarks,
          };

          // Send rejection data
          const rejectResponse = await axios.post(ApproveRejectPermit, RejectData);
          console.log("Rejection response:", rejectResponse);

          if (rejectResponse.data.statusCode !== "200") {
            setShowConfirmationPopup(true);
            setApiStatus(rejectResponse.data.message);
          } else {
            setShowConfirmationPopup(true);
            setApiStatus("Rejection successful!");
          }
        }
      } catch (err) {
        console.error("Error in submission:", err);
      } finally {
        setIsLoading(false);
        setShowReject(false);
      }
    } catch (error) {
      console.error("Error in handleRejectOk:", error);
    }
  };



  const handleSubmit = async () => {
    //event.preventDefault();
    const userProfileData = {
      id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
      mobileNumber: mobileNumberfromURL,
      name: "",
      corePermitID: IdFromURL ? IdFromURL : 0,
      additionalPermitName: "critical_elevated_permit",
    };
    const finalJson = {
      request: {
        permitInitials,
        emergency,
        scopeOfElevatedWork,
        hazardIdentification,
        hazardMitigation,
        roofAccess,
        fallRescue,
        additionalComments,
        terms,
        signature,
        scopOfElevatedToggleOn,
        emergencyToggleOn,
        hazardIdentificationToggleOn,
        hazardMitigationToggleOn,
        roofAccessToggleOn,
        roofRescueToggleOn,
        additionalToggleOn,
        reviewdToggleOn,
        sighnToggleOn,
      },
      ...userProfileData,
    };
    console.log(finalJson, "finalJson***");
    setIsLoading(true);
    const response = await axios.post(saveAdditionalPermit, finalJson);
    console.log(response.status, "sdmnhba");
    console.log("response", response);
    setIsLoading(false);
    if (response.data.statusCode == 200) {
      setApiStatus(response.data.message);
      setShowApproved(true);
    }
  };

  return (
    <body id="page-top">
      {/* {finalSubmit ? <>
                <h4>Form Submitted Successful!!!</h4>
            </> :  */}
      {finalSubmit ? (
        <>
          <h3>Form Submitted Successfully!!!</h3>
        </>
      ) : (
        <>
          <div id="wrapper">
            {/* 
                <SideMenu /> */}

            <div
              id="content-wrapper"
              className="d-flex flex-column"
              style={{ backgroundColor: "white" }}
            >
              <div id="content">
                {/* 
                        <Header /> */}
                <div
                  className="row"
                  style={{ backgroundColor: "blue", padding: "7px" }}
                ></div>
                <div>
                  <div className="container-fluid">
                    <div className="d-flex justify-content-between"></div>
                    <div>
                      <form className="CriticalElevated-form">
                        <CorePermitInputFields
                          data={permitInitials}
                          setPermitInitials={setPermitInitials}
                          editable={true}
                          onDataChange={(data) => {
                            setPermitInitials(data);
                          }}
                        />

                        <Accordion
                          title="EMERGENCY"
                          bgColor="#DF3A1C"
                          handleToggle={handleToggleItems}
                          isToggled={emergencyToggleOn}
                          content={
                            <Emergency
                              data={emergency}
                              emergencyToggleOn={emergencyToggleOn}
                              setEmergency={setEmergency}
                              onDataChange={(data) => {
                                setEmergency(data);
                              }}
                            />
                          }
                          bordercolor="#DF3A1C"
                          showToggle={true}
                        />
                        <Accordion
                          title="SCOPE OF ELEVATED WORK"
                          bgColor="#262828"
                          handleToggle={handleScopeToggleItems}
                          isToggled={scopOfElevatedToggleOn}
                          content={
                            <ScopeOfElevatedWork
                              data={scopeOfElevatedWork}
                              scopOfElevatedToggleOn={scopOfElevatedToggleOn}
                              setScopeOfElevatedWork={setScopeOfElevatedWork}
                              onDataChange={(data) => {
                                setScopeOfElevatedWork(data);
                              }}
                            />
                          }
                          bordercolor="#262828"
                          showToggle={true}
                        />
                        <Accordion
                          title="HAZARD IDENTIFICATION"
                          bgColor="#566060"
                          handleToggle={handleHazardIdentificationToggleItems}
                          isToggled={hazardIdentificationToggleOn}
                          content={
                            <HazardIdentific
                              data={hazardIdentification}
                              hazardIdentificationToggleOn={hazardIdentificationToggleOn}
                              setHazardIdentification={setHazardIdentification}
                              onDataChange={(data) =>
                                setHazardIdentification(data)
                              }
                            />
                          }
                          bordercolor="#566060"
                          showToggle={true}
                        />
                        <Accordion
                          title="HAZARD MITIGATION"
                          bgColor="#DF3A1C"
                          handleToggle={handleHazardMitigationToggleItems}
                          isToggled={hazardMitigationToggleOn}
                          content={
                            <HazardMitigation
                              data={hazardMitigation}
                              hazardMitigationToggleOn={hazardMitigationToggleOn}
                              setHazardMitigation={setHazardMitigation}
                              onDataChange={(data) =>
                                setHazardIdentification(data)
                              }
                            />
                          }
                          bordercolor="#DF3A1C"
                          showToggle={true}
                        />
                        <Accordion
                          title="ROOF ACCESS"
                          bgColor="#FF7E51"
                          handleToggle={handleRoofAccessToggleItems}
                          isToggled={roofAccessToggleOn}
                          content={
                            <CoreRoofAccess
                              data={roofAccess}
                              roofAccessToggleOn={roofAccessToggleOn}
                              setRoofAccess={setRoofAccess}
                              onDataChange={(data) => setRoofAccess(data)}
                            />
                          }
                          bordercolor="#FF7E51"
                          showToggle={true}
                        />
                        <Accordion
                          title="FALL RESCUE"
                          bgColor="#34B85F"
                          handleToggle={handleRescueToggleItems}
                          isToggled={roofRescueToggleOn}
                          content={
                            <FallRescue
                              data={fallRescue}
                              roofRescueToggleOn={roofRescueToggleOn}
                              setFallRescue={setFallRescue}
                              onDataChange={(val) => {
                                setFallRescue(val);
                              }}
                            />
                          }
                          bordercolor="#34B85F"
                          showToggle={true}
                        />
                        <Accordion
                          title="ADDITIONAL COMMENTS & MODIFICATIONS"
                          bgColor="#566060"
                          handleToggle={handleAdditionalToggleItems}
                          isToggled={additionalToggleOn}
                          content={
                            <AdditionalComments
                              data={additionalComments}
                              setAdditionalComments={setAdditionalComments}
                              additionalToggleOn={additionalToggleOn}
                              onDataChange={(data) =>
                                setAdditionalComments(data)
                              }
                            />
                          }
                          bordercolor="#566060"
                          showToggle={true}
                        />

                        <Accordion
                          title="I HAVE REVIEWED AND UNDERSTOOD..."
                          bgColor="#A1A2A5"
                          handleToggle={handleReviewdToggleItems}
                          isToggled={reviewdToggleOn}
                          content={
                            <ReviedWork
                              data={terms}
                              reviewdToggleOn={reviewdToggleOn}
                              setTerms={setTerms}
                              onDataChange={(data) => setTerms(data)}
                            />
                          }
                          bordercolor="#A1A2A5"
                          showToggle={true}
                        />
                        <Accordion
                          title="SIGNATURES"
                          bgColor="#2C2E35"
                          handleToggle={handleSignatureToggleItems}
                          isToggled={sighnToggleOn}
                          content={
                            <Signatures
                              data={signature}
                              sighnToggleOn={sighnToggleOn}
                              setSignature={setSignature}
                              onDataChange={(data) => setSignature(data)}
                            />
                          }
                          bordercolor="#2C2E35"
                          showToggle={true}
                        />

                        <div>
                          <Modal
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            size="sm"
                            id="nsmodel"
                            className="ppopup"
                          >
                            <Modal.Body>

                              <div>
                                <div className="row">
                                  <div>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "15vh",
                                      }}
                                    >
                                      <img
                                        src={Approved}
                                        alt="save"
                                        className="popupicons"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "3vh",
                                    }}
                                  >
                                    <h5>Critical Elevated sent for Approval</h5>
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "8vh",
                                    }}
                                  >

                                  </p>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>

                          {permitStatus !== "" && (
                            <button
                              type="button"
                              onClick={() => {
                                handleApproved(permitStatus);
                              }}
                              className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                              style={{
                                height: "50px",
                                backgroundColor: "#007AFF",
                                color: "white",
                                border: "none",
                                marginTop: "10px",
                              }}
                            >
                              {permitStatus == "Submitted"
                                ? "Approve"
                                : "Update"}
                            </button>
                          )}
                          {permitStatus == "Submitted" && (
                            <button
                              type="button"
                              onClick={handleReject}
                              className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                              style={{
                                height: "50px",
                                backgroundColor: "#FC2727",
                                color: "white",
                                border: "none",
                                marginTop: "10px",
                              }}
                            >
                              Reject
                            </button>
                          )}
                        </div>
                        <div>
                          <Modal
                            show={showApproved}
                            onHide={() => setShowApproved(false)}
                            size="sm"
                            id="nsmodel"
                            className="ppopup"
                          >
                            <Modal.Body>
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "10vh",
                                  }}
                                >
                                  <img
                                    src={Approved}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>

                              <div className="form-group">
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>{apiStatus} </h5>
                                  {/* Approved */}
                                </p>
                              </div>

                              {/* <Modal.Footer style={{ border: '0' }}> */}
                              <div
                                className="d-flex"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2
                                                                  ${"custom-rounded"}`}
                                  style={{
                                    height: "43px",
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={handleAprrovedOk}
                                >
                                  Ok
                                </Button>
                              </div>
                              {/* <span>{apiStatus}</span> */}
                              {/* </Modal.Footer > */}
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showReject}
                            onHide={() => setShowReject(false)}
                            size="sm"
                            id="nsmodel"
                            className="ppopup"
                          >
                            <Modal.Header className="no-border">
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancelReject}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body>
                              <div>
                                <div className="row">
                                  <div>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "15vh",
                                      }}
                                    >
                                      <img
                                        src={Reject}
                                        alt="save"
                                        className="popupicons"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "3vh",
                                    }}
                                  >
                                    <h5>Are you sure want to Reject?</h5>
                                  </p>
                                </div>

                              </div>

                              <div className="form-group">
                                <label htmlFor="name">
                                  <h6>Reason:</h6>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  placeholder="Reason for Rejection"
                                  value={remarks}
                                  onChange={(e) => {
                                    setRemarks(e.target.value);
                                  }}
                                />
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "8vh",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      height: "43px",
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={handleRejectOk}
                                    disabled={remarks.trim() === ""}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>

                              <Modal
                                show={showNewModal}
                                onHide={() => setShowNewModal(false)}
                                size="sm"
                                id="newModal"
                                className="ppopup"
                              >
                                <Modal.Body>
                                  <div className="row">
                                    <div>
                                      <span
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "15vh",
                                        }}
                                      >
                                        <img
                                          src={Approved}
                                          alt="save"
                                          className="popupicons"
                                        />
                                      </span>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "3vh",
                                        }}
                                      >
                                        <h5>
                                          CriticalElevated Permit Rejected
                                          Successfully
                                        </h5>
                                      </p>
                                    </div>

                                  </div>

                                  <Button
                                    variant="primary"
                                    onClick={handleNewModalOk}
                                  >
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                            </Modal.Body>

                          </Modal>

                          {/* Confirmation Popup */}
                          <Modal
                            show={showConfirmationPopup}
                            onHide={() => setShowConfirmationPopup(false)}
                            size="sm"
                            id="nsmodel"
                            className="ppopup"
                          >
                            <Modal.Body>
                              <div className="row">
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "13vh",
                                  }}
                                >
                                  <img
                                    src={Reject}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>

                              <div className="form-group">
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                    color: "green",
                                  }}
                                >
                                  <h5>
                                    CriticalElevated Permit Rejected
                                    Successfully
                                  </h5>
                                </p>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                  style={{
                                    height: "43px",
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={handleConfirmationOk}
                                >
                                  Ok
                                </Button>
                              </div>

                              {/* </Modal.Footer > */}
                            </Modal.Body>
                          </Modal>
                        </div>
                      </form>
                      {/* </div>
                                            </div> */}
                      {/* </div>
                                    </div> */}
                    </div>
                  </div>
                  {/*  Clseout end */}
                </div>
                {/* <Footer /> */}
                {/* ... End of Footer...  */}
              </div>
            </div>
            {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            {/* <Logout /> */}
          </div>
        </>
      )}
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CriticalElevated2Additional;
