import React, { useState, useEffect } from 'react'

function CorePermitM2Work(props) {

    const [workEnv, setWorkEnv] = useState(props.data)

    useEffect(() => {
        setWorkEnv(props.data)
    }, [])

    useEffect(() => {
        props.onDataChange(workEnv)
    }, [workEnv])

    const handleCheckBox = (id, list) => {
        if (list == 'accessList') {
            const updatedAccessListList = workEnv.accessList.map(opItem =>
                ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setWorkEnv((prev) => ({ ...prev, accessList: updatedAccessListList }))
        } else if (list == 'preventionList') {
            const updatedPreventionList = workEnv.preventionList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setWorkEnv((prev) => ({ ...prev, preventionList: updatedPreventionList }))
        } else if (list == 'protectionList') {
            const updatedProtectionList = workEnv.protectionList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setWorkEnv((prev) => ({ ...prev, protectionList: updatedProtectionList }))
        } else if (list == 'additionalRequirmentList') {
            const updatedAdditionalRequirmentList = workEnv.additionalRequirmentList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setWorkEnv((prev) => ({ ...prev, additionalRequirmentList: updatedAdditionalRequirmentList }))
        } else if (list == 'barricadesList') {
            const updatedBarricadesList = workEnv.barricadesList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setWorkEnv((prev) => ({ ...prev, barricadesList: updatedBarricadesList }))
        }
        else if (list == 'signage') {
            const updatedSignageList = workEnv.signage.map(opItem =>
                ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setWorkEnv((prev) => ({ ...prev, signage: updatedSignageList }))
        }
    }
    const handleInput = (id, list, value) => {
        console.log('updeted', value)
        if (list == 'accessList') {
            const updatedHeadFaceList = workEnv.accessList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setWorkEnv((prev) => ({ ...prev, accessList: updatedHeadFaceList }))
        } else if (list == 'preventionList') {
            const updatedHandsList = workEnv.preventionList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setWorkEnv((prev) => ({ ...prev, preventionList: updatedHandsList }))
        } else if (list == 'protectionList') {
            const updatedArmsBodyList = workEnv.protectionList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setWorkEnv((prev) => ({ ...prev, protectionList: updatedArmsBodyList }))
        } else if (list == 'additionalRequirmentList') {
            const updatedRespiratoryList = workEnv.additionalRequirmentList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setWorkEnv((prev) => ({ ...prev, additionalRequirmentList: updatedRespiratoryList }))
        } else if (list == 'barricadesList') {
            const updatedFeetLegList = workEnv.barricadesList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setWorkEnv((prev) => ({ ...prev, barricadesList: updatedFeetLegList }))
        }
        else if (list == 'signage') {
            const updatedSignList = workEnv.signage.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setWorkEnv((prev) => ({ ...prev, signage: updatedSignList }))
        }
    }


    return (
        <div className="row">
            <div className="col-12 col-xs-2">
                <div>
                  
                    <div className="Corper-subtxt protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2", marginTop: "-27px" }}>
                        <p className="card-subheading protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }} >Elevated Work</p>
                    </div>
                    <div className="Corper-subtxt protection-sub-bg-color">
                        <p className="card-subheading protection-sub-bg-color">Access<span style={{ color: "blue" }}> *</span> </p>
                    </div>                 
                    {
                        workEnv.accessList.map(accessItem => (<>
                            {(accessItem.cbTitle && accessItem.cbTitle !== "") &&
                                <div class="form-group row mt-2">
                                    <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                        <div class="col-12 form-check form-check-inline">
                                            <input class="form-check-input  mt-1"
                                                    disabled={props.approvalStatus}
                                            checked={accessItem.checked
                                                === true}
                                                onChange={() => { handleCheckBox(accessItem.id, 'accessList') }}
                                                type="checkbox"
                                                id={`accessListCore${accessItem.id}`} value="option1" />
                                            <label class="form-check-label  mt-0" for={`accessListCore${accessItem.id}`}>{accessItem.cbTitle}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                          
                            {accessItem.inputAvaiable && (
                                <div>
                                    <div class="form-group row mt-2">
                                        <div class="" style={{display: 'flex', flexDirection: 'row'}}>
                                            <div class="col-12 form-check form-check-inline d-flex 
                                                    align-items-center">

                                                {accessItem.cbTitle === '' &&
                                                 (<input class="form-check-input mt-0" type="checkbox"
                                                 disabled={props.approvalStatus}
                                                  checked={accessItem.checked === true}
                                                  onChange={() => {
                                                    handleCheckBox(accessItem.id, 'accessList');
                                                }}
                                                id={`accessListCore${accessItem.id}`} />)}

                                                <input type="text" 
                                                        readOnly={props.approvalStatus}
                                                  class="form-control col-12 mt-0"
                                                    id="inputsignageListCore125" placeholder=""
                                                    onChange={(e) => {
                                                        console.log(e, e.target.value)
                                                        handleInput(accessItem.id, 'accessList', e.target.value)
                                                    }}
                                                    value={accessItem.inputValue}/>
                                            </div>
                                        </div>
                                    </div>

                                  


                                  
                                </div>
                            )}


                        </>))
                    }
                  
                </div >
            </div>

            <div className="col-12 col-xs-2">
                <div>
                   
                    <div className="Corper-subtxt protection-sub-bg-color">
                        <p className="card-subheading protection-sub-bg-color">Preventation</p>
                    </div>
                  
                    {
                        workEnv.preventionList.map(preItem => (
                            <>
                                {(preItem.cbTitle && preItem.cbTitle !== "") &&
                                    <div class="form-group row mt-2">
                                        <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                            <div class="col-12 form-check form-check-inline">
                                                <input class="form-check-input  mt-1" type="checkbox"
                                                        disabled={props.approvalStatus}
                                                    checked={preItem.checked === true}
                                                    onChange={() => { handleCheckBox(preItem.id, 'preventionList') }}
                                                    id={`preventListCore${preItem.id}`} value="option1" />
                                                <label class="form-check-label  mt-0" for={`preventListCore${preItem.id}`}>{preItem.cbTitle}</label>
                                            </div>
                                        </div>
                                    </div>}
                                { preItem.inputAvaiable &&
                                    <div class="form-group row mt-2">
                                        <div>
                                            <input type='text'
                                             value={preItem.inputValue}
                                             readOnly={props.approvalStatus}
                                                onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(preItem.id, 'preventionList', e.target.value)
                                                }} class="form-control col-8" id="inputPassword" placeholder="" />
                                        </div>
                                    </div>}

                            </>
                        ))
                    }

                </div>
            </div>


            <div className="col-12 col-xs-2">
                <div>
                   
                    <div className="Corper-subtxt protection-sub-bg-color">
                        <p className="card-subheading protection-sub-bg-color">Protection</p>
                    </div>
                   
                    {
                        workEnv.protectionList.map(proItem => (
                            <>

                                {(proItem.cbTitle && proItem.cbTitle !== "") &&
                                    <div class="form-group row mt-2">
                                        <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                            <div class="col-12 form-check form-check-inline">
                                                <input class="form-check-input  mt-1" 
                                                        readOnly={props.approvalStatus} type="checkbox"
                                                    checked={proItem.checked === true}
                                                    onChange={() => { handleCheckBox(proItem.id, 'protectionList') }}
                                                    id={`protectionList${proItem.id}`} value="option1" />
                                                <label class="form-check-label  mt-0" for={`protectionList${proItem.id}`}>{proItem.cbTitle}</label>
                                            </div>
                                        </div>
                                    </div>}
                                {
                                    proItem.inputAvaiable &&
                                    <div class="form-group row mt-2">
                                        <div>
                                            <input type='text' value={proItem.inputValue}
                                                    readOnly={props.approvalStatus}
                                                onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(proItem.id, 'protectionList', e.target.value)
                                                }} class="form-control col-8" id="inputPassword" placeholder="" />
                                        </div>
                                    </div>}

                            </>
                        ))
                    }

                </div>
            </div>
            <div className="col-12 col-xs-2">
                <div>
                   
                    <div className="Corper-subtxt protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }}>
                        <p className="card-subheading protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }} >Barricades</p>
                    </div>
                   
                    {
                        workEnv.barricadesList.map(barItem => (
                            <>

                                {(barItem.cbTitle && barItem.cbTitle !== "") &&
                                    <div class="form-group row mt-2">
                                        <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                            <div class="col-12 form-check form-check-inline">
                                                <input class="form-check-input  mt-1" 
                                                        disabled={props.approvalStatus} type="checkbox"
                                                    checked={barItem.checked === true}
                                                    onChange={() => { handleCheckBox(barItem.id, 'barricadesList') }}
                                                    id={`barricadesListListCore${barItem.id}`} value="option1" />
                                                <label class="form-check-label  mt-0" for={`barricadesListListCore${barItem.id}`}>{barItem.cbTitle}</label>
                                            </div>
                                        </div>
                                    </div>}
                                {
                                    barItem.inputAvaiable &&
                                    <div class="form-group row mt-2">
                                        <div>
                                            <input type='text' value={barItem.inputValue}
                                                    readOnly={props.approvalStatus}
                                                onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(barItem.id, 'barricadesList', e.target.value)
                                                }} class="form-control col-8" id="inputPassword" placeholder="" />
                                        </div>
                                      
                                    </div>}

                            </>
                        ))
                    }

                </div>
            </div>
            <div className="col-12 col-xs-2">
                <div>
                 
                    <div className="Corper-subtxt protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }}>
                        <p className="card-subheading protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }} >Additional requirements</p>
                    </div>
                   
                    {
                        workEnv.additionalRequirmentList.map(addItem => (
                            <>

                                {(addItem.cbTitle && addItem.cbTitle !== "") &&
                                    <div class="form-group row mt-2">
                                        <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                            <div class="col-12 form-check form-check-inline">
                                                <input class="form-check-input  mt-1" 
                                                   disabled={props.approvalStatus} type="checkbox"
                                                    checked={addItem.checked === true}
                                                    onChange={() => { handleCheckBox(addItem.id, 'additionalRequirmentList') }}
                                                    id={`additionalRequirmentList${addItem.id}`} value="option1" />
                                                <label class="form-check-label  mt-0" for={`additionalRequirmentList${addItem.id}`}>{addItem.cbTitle}</label>
                                            </div>
                                        </div>
                                    </div>}
                                {
                                    addItem.inputAvaiable &&
                                    <div class="form-group row mt-2">
                                        <div>
                                            <input type='text' value={addItem.inputValue}
                                                    readOnly={props.approvalStatus}
                                                onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(addItem.id, 'additionalRequirmentList'
                                                    , e.target.value)
                                                }} class="form-control col-8" id="inputPassword" placeholder="" />
                                        </div>

                                      
                                    </div>}

                            </>
                        ))
                    }
                </div>
            </div>

            <div className="col-12 col-xs-2">
                <div>
                    <div className="Corper-subtxt protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }}>
                        <p className="card-subheading protection-sub-bg-color" style={{ color: "#fff", backgroundColor: "#99A4A2" }}>Signage</p>
                    </div>
                    {
                        workEnv.signage.map(sigItem => (
                            <div key={sigItem.id} className="form-group row mt-2">
                                {sigItem.inputAvaiable &&
                                    <div className="" style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="col-12 form-check form-check-inline d-flex align-items-center">
                                            <input
                                                    disabled={props.approvalStatus}
                                                className="form-check-input mt-0"
                                                type="checkbox"
                                                checked={sigItem.checked === true}
                                                onChange={() => {
                                                    handleCheckBox(sigItem.id, 'signage');
                                                }}
                                                id={`signageListCore${sigItem.id}`}
                                            />
                                            <input
                                                type='text'
                                                readOnly={props.approvalStatus}
                                                value={sigItem.inputValue}
                                                onChange={(e) => {
                                                    console.log(e, e.target.value);
                                                    handleInput(sigItem.id, 'signage', e.target.value);
                                                }}
                                                className="form-control col-12 mt-0"
                                                id={`inputsignageListCore${sigItem.id}`}
                                                placeholder=""
                                            />
                                        </div>
                                    </div>}
                            </div>
                        ))
                    }

                </div>

            </div>

        </div >
    )
}

export default CorePermitM2Work