import React, { useState, useEffect } from "react";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllPermitsByCorePermitID } from "../Utilities/URLCONSTANTS";
import { useSearchParams } from "react-router-dom";
import cranesetup1 from "../images/Crane-setup1.png";
import jsonData from "../jsonData.json";
//import '../RandD.css'
//Header images
import Factory from "../images/Factory-header-corepermit.png";
import CortevaImage from "../images/corteva-logo.png";
import HouseAndLighthouse from "../images/HouseAndLighthouse.png";
import Labtubeheadercorepermit from "../images/Labtubeheadercorepermit.png";
import shakinghandsHeadersCorepermit from "../images/shakinghandsHeadersCorepermit.png";
import ImageheaderCorepermit from "../images/ImageheaderCorepermit.png";
import DroneHeaderCorepermit from "../images/DroneHeaderCorepermit.png";
import { weekdaysMin } from "moment";
import Loader from "../../src/Utilities/Loader";
import moment from "moment/moment";

const CraneOperationPermit_Web = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [data, setData] = useState(jsonData);

  //CORE Permit #:
  const [initialsOne, setInitialsOne] = useState("");
  const [initialsTwo, setInitialsTwo] = useState("");
  const [initialsThree, setInitialsThree] = useState("");

  const [dateInputOne, setDateInputOne] = useState("");

  const [permitsOne, setPermitsOne] = useState("");
  const [permitsTwo, setPermitsTwo] = useState("");

  //Emergency
  const [phoneNumber, setphoneNumber] = useState();
  const [assemble, setassemble] = useState();
  //Basic Details
  const [company, setcompany] = useState();
  const [issueDate, setissueDate] = useState();
  const [locationForCrameWork, setlocationForCrameWork] = useState();
  const [expirationDate, setexpirationDate] = useState();
  const [craneOperatorDate, setcraneOperatorDate] = useState();
  //EQUIPMENT INFORMATION
  const [scopeOfWork, setscopeOfWork] = useState();
  const [size_or_capacity_in_tons, setsize_or_capacity_in_tons] = useState();
  const [make_or_model, setmake_or_model] = useState();
  const [searal, setsearal] = useState();
  //Type
  const [eiType0, seteiType0] = useState();
  const [eiType1, seteiType1] = useState();
  const [eiType2, seteiType2] = useState();
  const [eiType3, seteiType3] = useState();
  const [eiType4, seteiType4] = useState();
  const [eiType5, seteiType5] = useState();
  const [eiType6, seteiType6] = useState();
  //IMPORTANT
  //Review this checklist with crew completing crane operations.All answers must be answered Yes or N/A.If no then take proper corective measures.
  const [rtYes0, setrtYes0] = useState();
  const [rtNa0, setrtNa0] = useState();

  const [rtYes1, setrtYes1] = useState();
  const [rtNa1, setrtNa1] = useState();

  const [rtYes2, setrtYes2] = useState();
  const [rtNa2, setrtNa2] = useState();

  const [rtYes3, setrtYes3] = useState();
  const [rtNa3, setrtNa3] = useState();

  const [rtYes4, setrtYes4] = useState();
  const [rtNa4, setrtNa4] = useState();

  const [rtYes5, setrtYes5] = useState();
  const [rtNa5, setrtNa5] = useState();

  const [rtYes6, setrtYes6] = useState();
  const [rtNa6, setrtNa6] = useState();

  const [rtYes7, setrtYes7] = useState();
  const [rtNa7, setrtNa7] = useState();

  const [rtYes8, setrtYes8] = useState();
  const [rtNa8, setrtNa8] = useState();

  const [rtYes9, setrtYes9] = useState();
  const [rtNa9, setrtNa9] = useState();

  const [rtYes10, setrtYes10] = useState();
  const [rtNa10, setrtNa10] = useState();

  const [rtYes11, setrtYes11] = useState();
  const [rtNa11, setrtNa11] = useState();

  const [rtYes12, setrtYes12] = useState();
  const [rtNa12, setrtNa12] = useState();

  const [rtYes13, setrtYes13] = useState();
  const [rtNa13, setrtNa13] = useState();
  //Certificates
  const [cYes0, setcYes0] = useState();
  const [cNo0, setcNo0] = useState();

  const [cYes1, setcYes1] = useState();
  const [cNo1, setcNo1] = useState();

  const [cYes2, setcYes2] = useState();
  const [cNo2, setcNo2] = useState();

  const [cYes3, setcYes3] = useState();
  const [cNo3, setcNo3] = useState();

  const [cYes4, setcYes4] = useState();
  const [cNo4, setcNo4] = useState();
  //Note
  //If a lift meets any of the following criteria,then it shall be deemed a critical lift
  const [noteYes0, setnoteYes0] = useState();
  const [noteNo0, setnoteNo0] = useState();

  const [noteYes1, setnoteYes1] = useState();
  const [noteNo1, setnoteNo1] = useState();

  const [noteYes2, setnoteYes2] = useState();
  const [noteNo2, setnoteNo2] = useState();

  const [noteYes3, setnoteYes3] = useState();
  const [noteNo3, setnoteNo3] = useState();

  const [noteYes4, setnoteYes4] = useState();
  const [noteNo4, setnoteNo4] = useState();

  const [noteYes5, setnoteYes5] = useState();
  const [noteNo5, setnoteNo5] = useState();

  const [noteYes6, setnoteYes6] = useState();
  const [noteNo6, setnoteNo6] = useState();

  const [noteYes7, setnoteYes7] = useState();
  const [noteNo7, setnoteNo7] = useState();
  //  Basic Details
  const [permitissuer, setpermitissuer] = useState();
  const [permitissuerdate, setpermitissuerdate] = useState();
  const [permitreceiver, setpermitreceiver] = useState();
  const [permitreceiverdate, setpermitreceiverdate] = useState();
  //LIFT CONDITIONS
  const [r, setr] = useState();
  const [h, seth] = useState();
  const [l, setl] = useState();
  const [w, setw] = useState();

  const [m_front_length, setm_front_length] = useState();
  const [m_front_width, setm_front_width] = useState();
  const [m_front_height, setm_front_height] = useState();
  const [m_back_length, setm_back_length] = useState();
  const [m_back_width, setm_back_width] = useState();
  const [m_back_height, setm_back_height] = useState();

  const [one, setone] = useState();
  const [two, settwo] = useState();
  const [three, setthree] = useState();
  const [four, setfour] = useState();

  const [question3_ans, setquestion3_ans] = useState("");
  const [question3_no, setquestion3_no] = useState("");

  const [question5_ans, setQuestion5_ans] = useState();
  const [question6_ans, setQuestion6_ans] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  //const IdFromURL = searchParams.get("corePermitID");
  const IdFromURLwithEDit = searchParams.get("corePermitID");
  var IdFromURL;
  var additional_permit_id;
  if (IdFromURLwithEDit == null) {
  } else {
    IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
    additional_permit_id = IdFromURLwithEDit.toString().split("/")[1];
  }

  useEffect(() => {
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
  }, []);
  const getFormData = async (id, additional_permit_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });
      setIsLoading(false);
      if (response.data && response.data.response) {
        const elevated = response?.data?.response?.crane_permit || [];
        //const dataLast = response?.data?.response?.crane_permit || [];
        const dataLast =
          elevated.find((item) => item.permitID === additional_permit_id)
            ?.permitObject || null;
        // console.log("filteredArray checking", JSON.stringify(dataLast));

        if (dataLast) {
          //alert(JSON.stringify(dataLast));
          //Emergency
          setInitialsOne(dataLast?.permitInitials.initialsOne);
          setInitialsTwo(dataLast?.permitInitials.initialsTwo);
          setInitialsThree(dataLast?.permitInitials.initialsThree);
          // setDateInputOne(dataLast?.permitInitials.dateInputOne);

          // setDateInputOne(
          //   dataLast?.permitInitials.dateInputOne
          //     ? moment(dataLast.permitInitials.dateInputOne).format(
          //         "DD-MMM-YYYY"
          //       )
          //     : ""
          // );

          // const rawDateInputOne = dataLast?.permitInitials.dateInputOne;

          // // Validate and format the date
          // const formattedDateInputOne = moment(rawDateInputOne, "DD-MM-YYYY", true).isValid()
          //   ? moment(rawDateInputOne, "DD-MM-YYYY").format("DD-MMM-YYYY")
          //   : rawDateInputOne;

          // setDateInputOne(formattedDateInputOne);

          const rawDateInputOne = dataLast?.permitInitials.dateInputOne || "";
          const formattedDateInputOne = moment(rawDateInputOne, "DD-MM-YYYY", true).isValid()
            ? moment(rawDateInputOne, "DD-MM-YYYY").format("DD-MMM-YYYY")
            : rawDateInputOne;

          setDateInputOne(formattedDateInputOne);


          setPermitsOne(dataLast?.permitInitials.permitsOne);
          setPermitsTwo(dataLast?.permitInitials.permitsTwo);

          setphoneNumber(dataLast?.emergency.phoneNumber);
          setassemble(dataLast?.emergency.assemblyLocation);

          //Basic Details
          setcompany(dataLast?.basicDetails.company);
          setissueDate(dataLast?.basicDetails.issueDate);
          setlocationForCrameWork(dataLast?.basicDetails.locationForCrameWork);
          setexpirationDate(dataLast?.basicDetails.expirationDate);
          setcraneOperatorDate(dataLast?.basicDetails.craneOperatorDate);
          //EQUIPMENT INFORMATION
          setscopeOfWork(dataLast?.eqipmentInformation.scopeOfWork);
          setsize_or_capacity_in_tons(
            dataLast?.eqipmentInformation.size_or_capacity_in_tons
          );
          setmake_or_model(dataLast?.eqipmentInformation.make_or_model);
          setsearal(dataLast?.eqipmentInformation.searal);
          //Type
          if (dataLast?.eqipmentInformation.type["0"].checked == true) {
            seteiType0("checked");
          }
          if (dataLast?.eqipmentInformation.type["1"].checked == true) {
            seteiType1("checked");
          }
          if (dataLast?.eqipmentInformation.type["2"].checked == true) {
            seteiType2("checked");
          }
          if (dataLast?.eqipmentInformation.type["3"].checked == true) {
            seteiType3("checked");
          }
          if (dataLast?.eqipmentInformation.type["4"].checked == true) {
            seteiType4("checked");
          }
          if (dataLast?.eqipmentInformation.type["5"].checked == true) {
            seteiType5("checked");
          }
          if (dataLast?.eqipmentInformation.type["6"].checked == true) {
            seteiType6("checked");
          }
          //Important
          ////Review this checklist with crew completing crane operations.All answers must be answered Yes or N/A.If no then take proper corective measures.
          if (dataLast?.important.importantList["0"].cbYes == "true") {
            setrtYes0("checked");
          }
          if (dataLast?.important.importantList["0"].cbNA == "true") {
            setrtNa0("checked");
          }

          if (dataLast?.important.importantList["1"].cbYes == "true") {
            setrtYes1("checked");
          }
          if (dataLast?.important.importantList["1"].cbNA == "true") {
            setrtNa1("checked");
          }

          if (dataLast?.important.importantList["2"].cbYes == "true") {
            setrtYes2("checked");
          }
          if (dataLast?.important.importantList["2"].cbNA == "true") {
            setrtNa2("checked");
          }

          if (dataLast?.important.importantList["3"].cbYes == "true") {
            setrtYes3("checked");
          }
          if (dataLast?.important.importantList["3"].cbNA == "true") {
            setrtNa3("checked");
          }

          if (dataLast?.important.importantList["4"].cbYes == "true") {
            setrtYes4("checked");
          }
          if (dataLast?.important.importantList["4"].cbNA == "true") {
            setrtNa4("checked");
          }

          if (dataLast?.important.importantList["5"].cbYes == "true") {
            setrtYes5("checked");
          }
          if (dataLast?.important.importantList["5"].cbNA == "true") {
            setrtNa5("checked");
          }

          if (dataLast?.important.importantList["6"].cbYes == "true") {
            setrtYes6("checked");
          }
          if (dataLast?.important.importantList["6"].cbNA == "true") {
            setrtNa6("checked");
          }

          if (dataLast?.important.importantList["7"].cbYes == "true") {
            setrtYes7("checked");
          }
          if (dataLast?.important.importantList["7"].cbNA == "true") {
            setrtNa7("checked");
          }

          if (dataLast?.important.importantList["8"].cbYes == "true") {
            setrtYes8("checked");
          }
          if (dataLast?.important.importantList["8"].cbNA == "true") {
            setrtNa8("checked");
          }

          if (dataLast?.important.importantList["9"].cbYes == "true") {
            setrtYes9("checked");
          }
          if (dataLast?.important.importantList["9"].cbNA == "true") {
            setrtNa9("checked");
          }

          if (dataLast?.important.importantList["10"].cbYes == "true") {
            setrtYes10("checked");
          }
          if (dataLast?.important.importantList["10"].cbNA == "true") {
            setrtNa10("checked");
          }

          if (dataLast?.important.importantList["11"].cbYes == "true") {
            setrtYes11("checked");
          }
          if (dataLast?.important.importantList["11"].cbNA == "true") {
            setrtNa11("checked");
          }

          if (dataLast?.important.importantList["12"].cbYes == "true") {
            setrtYes12("checked");
          }
          if (dataLast?.important.importantList["12"].cbNA == "true") {
            setrtNa12("checked");
          }

          if (dataLast?.important.importantList["13"].cbYes == "true") {
            setrtYes13("checked");
          }
          if (dataLast?.important.importantList["13"].cbNA == "true") {
            setrtNa13("checked");
          }

          //Certificates

          if (
            dataLast?.important.cerifications.cerificationsList["0"].cbYes ==
            "true"
          ) {
            setcYes0("checked");
          }
          if (
            dataLast?.important.cerifications.cerificationsList["0"].cbNA ==
            "true"
          ) {
            setcNo0("checked");
          }

          if (
            dataLast?.important.cerifications.cerificationsList["1"].cbYes ==
            "true"
          ) {
            setcYes1("checked");
          }
          if (
            dataLast?.important.cerifications.cerificationsList["1"].cbNA ==
            "true"
          ) {
            setcNo1("checked");
          }

          if (
            dataLast?.important.cerifications.cerificationsList["2"].cbYes ==
            "true"
          ) {
            setcYes2("checked");
          }
          if (
            dataLast?.important.cerifications.cerificationsList["2"].cbNA ==
            "true"
          ) {
            setcNo2("checked");
          }

          if (
            dataLast?.important.cerifications.cerificationsList["3"].cbYes ==
            "true"
          ) {
            setcYes3("checked");
          }
          if (
            dataLast?.important.cerifications.cerificationsList["3"].cbNA ==
            "true"
          ) {
            setcNo3("checked");
          }

          if (
            dataLast?.important.cerifications.cerificationsList["4"].cbYes ==
            "true"
          ) {
            setcYes4("checked");
          }
          if (
            dataLast?.important.cerifications.cerificationsList["4"].cbNA ==
            "true"
          ) {
            setcNo4("checked");
          }
          //Note
          if (dataLast?.note.noteList["0"].cbYes == "true") {
            setnoteYes0("checked");
          }
          if (dataLast?.note.noteList["0"].cbNA == "true") {
            setnoteNo0("checked");
          }
          if (dataLast?.note.noteList["1"].cbYes == "true") {
            setnoteYes1("checked");
          }
          if (dataLast?.note.noteList["1"].cbNA == "true") {
            setnoteNo1("checked");
          }
          if (dataLast?.note.noteList["2"].cbYes == "true") {
            setnoteYes2("checked");
          }
          if (dataLast?.note.noteList["2"].cbNA == "true") {
            setnoteNo2("checked");
          }
          if (dataLast?.note.noteList["3"].cbYes == "true") {
            setnoteYes3("checked");
          }
          if (dataLast?.note.noteList["3"].cbNA == "true") {
            setnoteNo3("checked");
          }
          if (dataLast?.note.noteList["4"].cbYes == "true") {
            setnoteYes4("checked");
          }
          if (dataLast?.note.noteList["4"].cbNA == "true") {
            setnoteNo4("checked");
          }
          if (dataLast?.note.noteList["5"].cbYes == "true") {
            setnoteYes5("checked");
          }
          if (dataLast?.note.noteList["5"].cbNA == "true") {
            setnoteNo5("checked");
          }
          if (dataLast?.note.noteList["6"].cbYes == "true") {
            setnoteYes6("checked");
          }
          if (dataLast?.note.noteList["6"].cbNA == "true") {
            setnoteNo6("checked");
          }
          if (dataLast?.note.noteList["7"].cbYes == "true") {
            setnoteYes7("checked");
          }
          if (dataLast?.note.noteList["7"].cbNA == "true") {
            setnoteNo7("checked");
          }
          //Basic Details

          setpermitissuer(dataLast?.permitIssuer.permitissuer);
          setpermitissuerdate(dataLast?.permitIssuer.date);
          setpermitreceiver(dataLast?.permitReceiver.permitreceiver);
          setpermitreceiverdate(dataLast?.permitReceiver.date);

          //LIFT CONDITIONS
          setr(dataLast?.liftConditions.r);
          seth(dataLast?.liftConditions.h);
          setl(dataLast?.liftConditions.l);
          setw(dataLast?.liftConditions.w);
          //

          if (dataLast?.liftConditions.question3_ans == "yes") {
            setquestion3_ans("checked");
          }

          if (dataLast?.liftConditions.question3_ans == "false") {
            setquestion3_no("checked");
          }

          setQuestion5_ans(dataLast?.liftConditions.uestion5_ans);
          setQuestion6_ans(dataLast?.liftConditions.question6_ans);

          setone(dataLast?.liftConditions.one);
          settwo(dataLast?.liftConditions.two);
          setthree(dataLast?.liftConditions.three);
          setfour(dataLast?.liftConditions.four);

          setm_front_length(dataLast?.liftConditions.m_front_length);
          setm_front_width(dataLast?.liftConditions.m_front_width);
          setm_front_height(dataLast?.liftConditions.m_front_height);
          setm_back_length(dataLast?.liftConditions.m_back_length);
          setm_back_width(dataLast?.liftConditions.m_back_width);
          setm_back_height(dataLast?.liftConditions.m_back_height);
        }
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <body id="page-top">
      {/* <page size="A4"> */}
      <div style={{ textAlign: "right" }}>
        <button onClick={handlePrint} className="btn btn-primary print-hidden ">
          Print
        </button>
      </div>
      <div id="wrapper">
        {/* <SideMenu /> */}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/* <Header /> */}
            <form className="CorePermit_Web-form bodys">
              <div>
                <div className="container">
                  {/* <div className="card border-0">
                                        <div className="card-body pt-0 pl-1"> */}
                  <div>
                    <div>
                      <div>
                        <div className="row">
                          <div
                            className="d-flex flex-row"
                            style={{
                              margin: "0px",
                              padding: "2px",
                              width: "100%",
                            }}
                          >
                            {/* <div style={{ backgroundColor: "#E8EAEC", width: "15%" }}>
                                                                <div style={{ margin: "2px 5px", padding: "2px" }}>
                                                                    <h3 style={{ fontWeight: "bolder", fontSize: "36px" }}>CORE</h3>
                                                                </div>
                                                            </div> */}
                            <div style={{ width: "100%" }}>
                              <div
                                className="d-flex flex-row"
                                style={{
                                  backgroundColor: "#E6E8E9",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    width: "60%",
                                  }}
                                >
                                  <div>
                                    <h3
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "26px",
                                        color: "#000000",
                                        padding: "6px",
                                        letterSpacing: "5px",
                                      }}
                                    >
                                      CRANE OPERATION PERMIT
                                    </h3>
                                  </div>
                                  {/* <div><h6 style={{ fontSize: "14px", marginTop: "22px" }}>SEEDS</h6></div> */}
                                </div>
                                <div style={{ width: "40%" }}>
                                  <div
                                    className="d-flex flex-row"
                                    style={{
                                      backgroundColor: "#ffffff",
                                      margin: "5px",
                                      padding: "5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#000",
                                        fontSize: "12px",
                                        margin: "0px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      <p>Crane Permit #:</p>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div className="mr-2">
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsOne}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsTwo}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsThree}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          Initials
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div className="d-flex flex-row">
                                          <div className="mr-2">
                                            <input
                                              type=""
                                              className="microsite-txtbxxdate"
                                              value={dateInputOne}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          Date
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div>
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={permitsOne}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={permitsTwo}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          #permits
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div
                            className="d-flex flex-row"
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <div
                              style={{ backgroundColor: "red", width: "40%" }}
                            >
                              <div
                                className="d-flex flex-row"
                                style={{ padding: "5px" }}
                              >
                                <div>
                                  <i className="phonerining m-1 p-1"></i>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "5px",
                                    }}
                                  >
                                    Emergency
                                  </label>
                                </div>
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <label
                                    class="col-3"
                                    style={{ fontSize: "9px" }}
                                  >
                                    Phone <br />
                                    Number
                                  </label>
                                  <input
                                    type="text"
                                    class="col-3"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#DEE5FF",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    value={phoneNumber}
                                  />
                                  <label
                                    class="col-3"
                                    style={{ fontSize: "9px" }}
                                  >
                                    Assembly <br />
                                    Location
                                  </label>
                                  <input
                                    type="text"
                                    class="col-3"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#DEE5FF",
                                      padding: "0px",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    value={assemble}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                margin: "2px",
                                padding: "2px",
                                width: "40%",
                              }}
                            >
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #F4673C" }}
                              >
                                <img
                                  src={Factory}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #E0A350" }}
                              >
                                <img
                                  src={HouseAndLighthouse}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #34B85F" }}
                              >
                                <img
                                  src={Labtubeheadercorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #4275BA" }}
                              >
                                <img
                                  src={shakinghandsHeadersCorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #FFC85B" }}
                              >
                                <img
                                  src={ImageheaderCorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #793637" }}
                              >
                                <img
                                  src={DroneHeaderCorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                style={{
                                  display: "inline-block",
                                  border: "none",
                                  padding: "3px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  width: "35px",
                                  height: "35px",
                                  marginright: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <img
                                  src={CortevaImage}
                                  alt="img"
                                  style={{ width: "120px", height: "23px" }}
                                />
                              </span>
                            </div>

                            <div
                              style={{
                                margin: "2px",
                                padding: "2px",
                                display: "none",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    border: "none",
                                    padding: "3px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                    width: "35px",
                                    height: "35px",
                                    marginright: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <img
                                    src={CortevaImage}
                                    alt="img"
                                    style={{ width: "170px", height: "37px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <div>
                              <div className="row mt-1 mb-0">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    fontWeight: "bold",
                                    backgroundColor: "black",
                                    color: "white",
                                    marginTop: "-18px",
                                    display: "none",
                                  }}
                                >
                                  <p style={{ marginTop: "10px" }}>
                                    1.Basic Details
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="row mb-0">
                                  <div className="col-12">
                                    <div>
                                      <div className="m-0">
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-1 col-form-label lbl-bg-color-crane-bd label-height">
                                            Company
                                          </label>
                                          <div class="col-5">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={company}
                                            />
                                          </div>
                                          <label class="col-sm-2 col-form-label lbl-bg-color-crane-bd label-height">
                                            Issue Date
                                          </label>
                                          <div class="col-4">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={issueDate}
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-2 col-form-label lbl-bg-color-crane-bd label-height">
                                            Location for Crane Work
                                          </label>
                                          <div class="col-4">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={locationForCrameWork}
                                            />
                                          </div>
                                          <label class="col-2 col-form-label lbl-bg-color-crane-bd label-height">
                                            Expiration Date
                                          </label>
                                          <div class="col-4">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={expirationDate}
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-2 col-form-label lbl-bg-color-crane-bd label-height">
                                            Crane Operation(s) Name
                                          </label>
                                          <div class="col-10">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={craneOperatorDate}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                      </div>
                      {/* EQUIPMENT INFORMATION */}
                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #F58355",
                            borderRadius: "0px",
                          }}
                        >
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontWeight: "bold",
                              backgroundColor: "#F58355",
                              color: "white",
                              marginTop: "0px",
                            }}
                          >
                            <p style={{ margin: "5px" }}>
                              EQUIPMENT INFORMATION
                            </p>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            {/* <div>
                                                        <div> */}
                            <div>
                              {/* heading */}

                              <div>
                                <div className="row">
                                  <div className="col-12">
                                    <div>
                                      <div className="m-1">
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-2 col-form-label lbl-bg-color-crane-ei label-height">
                                            Size/Capacity (Tons)
                                          </label>
                                          <div class="col-2">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={size_or_capacity_in_tons}
                                            />
                                          </div>
                                          <label class="col-2 col-form-label lbl-bg-color-crane-ei label-height">
                                            Make/Model
                                          </label>
                                          <div class="col-2">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={make_or_model}
                                            />
                                          </div>
                                          <label class="col-2 col-form-label lbl-bg-color-crane-ei label-height">
                                            Serial #
                                          </label>
                                          <div class="col-2">
                                            <input
                                              type="text"
                                              class="col-12"
                                              value={searal}
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <div class="form-group row frm-grp-mb d-flex flex-row">
                                            {/* <div className='col-sm-3 col-12'>
                                                                            <input type="checkbox" id="GPRCheckbox" name="response" value="GPR" />
                                                                            <label for="GPRCheckbox">Ground Penetrating Radar</label>
                                                                        </div> */}

                                            <label class="col-1 col-form-label lbl-bg-color-crane-ei label-height">
                                              Type
                                            </label>
                                            <div class="col-10 d-flex flex-row cb-mt-5px">
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType0}
                                                />
                                                <label class="form-check-label">
                                                  Boom
                                                </label>
                                              </div>
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType1}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineCheckbox2"
                                                >
                                                  Hydralic
                                                </label>
                                              </div>
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType2}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineCheckbox2"
                                                >
                                                  All Terrain
                                                </label>
                                              </div>
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType3}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineCheckbox2"
                                                >
                                                  Truck
                                                </label>
                                              </div>
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType4}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineCheckbox2"
                                                >
                                                  Lattice
                                                </label>
                                              </div>
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType5}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineCheckbox2"
                                                >
                                                  Rough Terrain
                                                </label>
                                              </div>
                                              <div class="form-check form-check-inline d-flex align-items-center">
                                                <input
                                                  class="cb-size cb-mt-5px"
                                                  type="checkbox"
                                                  checked={eiType6}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineCheckbox2"
                                                >
                                                  Crawler
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb">
                                            <label class="col-2 col-form-label lbl-bg-color-crane-ei label-height">
                                              Scope of Work
                                            </label>
                                            <div
                                              class="col-10"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <input
                                                class="col-12"
                                                value={scopeOfWork}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            class="form-group row frm-grp-mb"
                                            style={{ display: "none" }}
                                          >
                                            <textarea
                                              class="form-control col-12"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                      </div>
                      {/* IMPORTANT */}
                      <div className="row mt-1">
                        <div className="col-6 m-0 p-0  d-flex flex-column">
                          <div className="row">
                            <div style={{ width: "100%" }}>
                              <div
                                className="card"
                                style={{
                                  border: "1px solid #3F57B1",
                                  borderRadius: "0px",
                                }}
                              >
                                <div
                                  className="card-body"
                                  style={{ padding: "0px" }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        fontWeight: "bold",
                                        backgroundColor: "#3F57B1",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: "5px",
                                          display: "flex",
                                          flexDirection: "row",
                                          padding: "5px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        <i className="infopng"></i>
                                        <i style={{ fontStyle: "normal" }}>
                                          IMPORTANT
                                        </i>
                                      </p>
                                    </div>
                                    <div>
                                      <div style={{ margin: "2px 5px" }}>
                                        <p
                                          style={{
                                            color: "#000000",
                                            fontWeight: "600",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          Review this checklist with crew
                                          completing crane operations.All
                                          answers must be answered Yes or N/A.If
                                          no then take proper corective
                                          measures.
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      className="mt-0"
                                      style={{ paddingLeft: "13px" }}
                                    >
                                      <div>
                                        {/* <div class="form-group row frm-grp-mb">
                                                                                        <label class="col-10 col-form-label lbl-bg-color label-height">All people working under this permit have the necessary site training and/or orientations</label>
                                                                                        <div class="col-2 d-flex flex-row cb-mt-5px">
                                                                                            <div class="form-check form-check-inline form-checks">
                                                                                                <input class="cb-size cb-mt-5px" type="checkbox" id="" value="option1" />
                                                                                                <label class="form-check-label" for="">YES</label>
                                                                                            </div>
                                                                                            <div class="form-check form-check-inline form-checks">
                                                                                                <input class="cb-size cb-mt-5px" type="checkbox"  value="option2" />
                                                                                                <label class="form-check-label" for="inlineCheckbox2">N/A</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}

                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Have proper crane escort
                                            precaution/safegaurds been
                                            communicated?
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes0}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa0}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Has a pre-lift plan for the job been
                                            completed and communicated to all
                                            persoannel involved in the lift?
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes1}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa1}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Does crane onsite match What is in
                                            the plan?(Size/Capacity/Type)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes2}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa2}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Has rigging plan ben completed and
                                            communictaed?(Equipment,configuration,working
                                            load limits)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes3}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa3}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Have ground conditions been verified
                                            and communicated?(Undergound
                                            drawings,utilities,tanks,etc.)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes4}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa4}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Have locations of overhead electric
                                            power lines been communicated?(With
                                            in 20 ft)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes5}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa5}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Have weather conditions been
                                            evaluted?(High
                                            wind,lighting,ice,etc.)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes6}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa6}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Has proximity to existing equipment
                                            /adjacent hazards been communicated?
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes7}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa7}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Have barricades been effectively
                                            used to protect people from all
                                            hazards?(Swing radius of
                                            counterweights,outriggers,lifting
                                            zone)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes8}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa8}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Have all work and lift areas been
                                            evacuated?(This includes occupied
                                            buildings,structures or buildings
                                            designed to be occupied.)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes9}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa9}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="Corper-subtxt" style={{height:"116px"}}> */}
                                        <div>
                                          <p
                                            style={{
                                              color: "#000000",
                                              fontWeight: "600",
                                              margin: "2px",
                                            }}
                                          >
                                            Have proper inspections been
                                            documented?
                                          </p>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Crane(Daily)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes10}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa10}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Crane(Annually)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes11}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa11}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Rigging Equipment(Before each use)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes12}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa12}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Has load capacity been verified for
                                            crane configuration?
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtYes13}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={rtNa13}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <p
                                            style={{
                                              color: "#000000",
                                              fontWeight: "600",
                                              margin: "2px",
                                            }}
                                          >
                                            IF YES: % of capacity
                                          </p>
                                        </div>
                                        <div>
                                          <p
                                            style={{
                                              color: "#000000",
                                              fontWeight: "600",
                                              margin: "2px",
                                            }}
                                          >
                                            Are certificates,qualifications and
                                            training in place prior to work
                                            beginning?(All Individuals)
                                          </p>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            National Commision certified Crane
                                            Opeation(NCCCO)or national
                                            equivalent
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cYes0}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cNo0}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Certified Signal Person
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cYes1}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cNo1}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Qualified Rigger(S)
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cYes2}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cNo2}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Lead Rigger Idenitified
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cYes3}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cNo3}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Will a Critical Lift take place? If
                                            yes, a critical lift meeting is
                                            required.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cYes4}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={cNo4}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* note */}
                          <div className="row m-0 p-0">
                            <div
                              style={{
                                width: "100%",
                                padding: "0px",
                                margin: "0px",
                              }}
                            >
                              <div
                                className="card"
                                style={{
                                  border: "1px solid #000000",
                                  borderRadius: "0px",
                                }}
                              >
                                <div
                                  className="card-body"
                                  style={{ padding: "0px" }}
                                >
                                  {/* <div>
                                                                    <div> */}
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        fontWeight: "bold",
                                        backgroundColor: "#000000",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <p style={{ margin: "5px" }}>NOTE</p>
                                    </div>
                                    <div>
                                      <div>
                                        <p
                                          style={{
                                            color: "#000000",
                                            fontWeight: "600",
                                            margin: "2px",
                                          }}
                                        >
                                          If a lift meets any of the following
                                          criteria,then it shall be deemed a
                                          critical lift
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      className="m-1"
                                      style={{ paddingLeft: "9px" }}
                                    >
                                      <div>
                                        {/* <div class="form-group row frm-grp-mb">
                                                                                        <label class="col-10 col-form-label lbl-bg-color label-height">All people working under this permit have the necessary site training and/or orientations</label>
                                                                                        <div class="col-2 d-flex flex-row cb-mt-5px">
                                                                                            <div class="form-check form-check-inline form-checks">
                                                                                                <input class="cb-size cb-mt-5px" type="checkbox" id="" value="option1" />
                                                                                                <label class="form-check-label" for="">YES</label>
                                                                                            </div>
                                                                                            <div class="form-check form-check-inline form-checks">
                                                                                                <input class="cb-size cb-mt-5px" type="checkbox"  value="option2" />
                                                                                                <label class="form-check-label" for="inlineCheckbox2">N/A</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Lift that exceeds 75% of the rated
                                            capacity of the crane.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes0}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo0}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Lift that exceeds 50% of rated
                                            capacity over power lines,process
                                            lines,or equipment.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes1}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo1}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Lift that involves the use of crane
                                            siuspended work
                                            platform/man-basket(requires an
                                            additional permit).
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes2}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo2}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Lift that requires the use of more
                                            than one boom or another mobile
                                            lifting device.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes3}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo3}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Working with any part of a crane or
                                            load closer than 20 feet(6.1
                                            meters)to an overhead electric line
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes4}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo4}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Lift over an occupied
                                            building,strcutures,or building
                                            designed to be occupied.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes5}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo5}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color twolabel-height">
                                            Crane travelling with load that
                                            poses a hazard or exceeds 50% of the
                                            cranes's load chart under lifting
                                            condition.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes6}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo6}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb">
                                          <label class="col-9 col-form-label lbl-bg-color label-height">
                                            Designated Site Crane Focal Point
                                            determines it to be a Critical Lift.
                                          </label>
                                          <div class="col-3 d-flex flex-row cb-mt-5px">
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteYes7}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="form-check form-check-inline d-flex align-items-center">
                                              <input
                                                class="cb-size cb-mt-5px"
                                                type="checkbox"
                                                checked={noteNo7}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div style={{ width: "100%" }}>
                              <div
                                className="card"
                                style={{
                                  border: "1px solid #CB3A27",
                                  borderRadius: "0px",
                                }}
                              >
                                <div
                                  className="card-body"
                                  style={{ padding: "0px" }}
                                >
                                  {/* <div>
                                                                    <div> */}
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "#CB3A27",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <div className="col-12 d-flex flex-row">
                                        <div className="col-6">
                                          <p
                                            style={{
                                              margin: "5px",
                                              backgroundColor: "#CB3A27",
                                              color: "white",
                                              marginLeft: "0px",
                                            }}
                                          >
                                            Permit Issuer
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p
                                            style={{
                                              margin: "5px",
                                              backgroundColor: "#CB3A27",
                                              color: "white",
                                            }}
                                          >
                                            Date
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      <table
                                        class="table nstable"
                                        style={{
                                          width: "100%",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {/* <thead className="thead-dark">
                                                                                        <tr>
                                                                                            <th scope="col">Permit Issuer</th>
                                                                                            <th scope="col">Date</th>
                                                                                        </tr>
                                                                                    </thead> */}
                                        <tbody>
                                          {/* <tr>
                                                                                            <td style={{backgroundColor:"#CB3A27",color:"white" }}>Permit Issuer</td>
                                                                                            <td style={{backgroundColor:"#CB3A27",color:"white" }}>Date</td>
                                                                                        </tr> */}
                                          <tr>
                                            <td>
                                              <input
                                                type="text"
                                                className="col-12"
                                                value={permitissuer}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="col-12"
                                                value={permitissuerdate}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div style={{ width: "100%" }}>
                              <div
                                className="card"
                                style={{
                                  border: "1px solid #CB3A27",
                                  borderRadius: "0px",
                                }}
                              >
                                <div
                                  className="card-body"
                                  style={{ padding: "0px" }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "#CB3A27",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <div className="col-12 d-flex flex-row">
                                        <div className="col-6">
                                          <p
                                            style={{
                                              margin: "5px",
                                              backgroundColor: "#CB3A27",
                                              color: "white",
                                              marginLeft: "0px",
                                            }}
                                          >
                                            Permit Receiver
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p
                                            style={{
                                              margin: "5px",
                                              backgroundColor: "#CB3A27",
                                              color: "white",
                                            }}
                                          >
                                            Date
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <table
                                      class="table"
                                      style={{
                                        width: "100%",
                                        margin: "0px",
                                        padding: "0px",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td>
                                            <input
                                              type="text"
                                              className="col-12"
                                              value={permitreceiver}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="col-12"
                                              value={permitreceiverdate}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-6 m-0 p-0">
                          <div style={{ width: "auto" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #5F92CD",
                                borderRadius: "0px",
                              }}
                            >
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      fontWeight: "bold",
                                      backgroundColor: "#5F92CD",
                                      color: "white",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <p style={{ margin: "8px" }}>
                                      LIFT CONDITIONS
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      fontWeight: "bold",
                                      backgroundColor: "#ffffff",
                                      color: "black",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <p style={{ margin: "10px" }}>SETUP 1</p>
                                  </div>
                                  <div>
                                    <div className="m-3">
                                      <div className="row">
                                        <div
                                          className="ml-5"
                                          style={{ margin: "10px" }}
                                        >
                                          <img
                                            src={cranesetup1}
                                            alt="Cranesetup"
                                            className="liftimg"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <p
                                          style={{
                                            color: "#000000",
                                            fontWeight: "600",
                                            backgroundColor: "#A7C3E4",
                                            padding: "5px",
                                            margin: "0px",
                                          }}
                                        >
                                          {data.response.leftConditions.title1}
                                        </p>
                                      </div>
                                      <div className="row">
                                        <table className="table">
                                          <tbody>
                                            <tr>
                                              <td className="lbl-bg-color">
                                                R=
                                              </td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  // value={
                                                  //   data.response.leftConditions
                                                  //     .m_front_length
                                                  // }
                                                  value={r}
                                                />
                                              </td>
                                              <td>H=</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  // value={
                                                  //   data.response.leftConditions
                                                  //     .m_front_width

                                                  // }

                                                  value={h}
                                                />
                                              </td>
                                              <td>L=</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={l}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td scope="row">W=</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={w}
                                                // value={data.response.leftConditions
                                                //     .w}
                                                />
                                              </td>
                                              <td colSpan={2}>
                                                Lifting in Quadrats
                                              </td>
                                              <td colSpan={2}>
                                                <div
                                                  class="col-12"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <div class="form-check form-check-inline col-2 d-flex align-items-center">
                                                    <input
                                                      class="cb-size cb-mt-5px  mt-0"
                                                      type="checkbox"
                                                      checked={one}
                                                    />
                                                    <label
                                                      class="form-check-label  mt-0"
                                                      for=""
                                                    >
                                                      1
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-check-inline col-2 d-flex align-items-center">
                                                    <input
                                                      class="cb-size cb-mt-5px mt-0"
                                                      type="checkbox"
                                                      checked={two}
                                                    />
                                                    <label
                                                      class="form-check-label mt-0"
                                                      for="inlineCheckbox2"
                                                    >
                                                      2
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-check-inline col-2 d-flex align-items-center">
                                                    <input
                                                      class="cb-size cb-mt-5px  mt-0"
                                                      type="checkbox"
                                                      checked={three}
                                                    />
                                                    <label class="form-check-label  mt-0">
                                                      3
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-check-inline col-2 d-flex align-items-center">
                                                    <input
                                                      class="cb-size cb-mt-5px mt-0"
                                                      type="checkbox"
                                                      checked={four}
                                                    />
                                                    <label
                                                      class="form-check-label mt-0"
                                                      for="inlineCheckbox2"
                                                    >
                                                      4
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="row">
                                        <p
                                          style={{
                                            color: "#000000",
                                            fontWeight: "600",
                                            backgroundColor: "#A7C3E4",
                                            padding: "5px",
                                            margin: "0px",
                                          }}
                                        >
                                          outriggers mat sizes (front and back):
                                        </p>
                                      </div>
                                      <div className="row frm-grp-mb">
                                        <table className="table nstable">
                                          <thead className="thead-dark">
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col" colSpan={2}>
                                                LENGTH
                                              </th>
                                              <th scope="col" colSpan={2}>
                                                WIDTH
                                              </th>
                                              <th scope="col">HEIGHT</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <th scope="row">M front</th>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={m_front_length}
                                                />
                                              </td>
                                              <td>x</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={m_front_width}
                                                />
                                              </td>
                                              <td>x</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={m_front_height}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">M back</th>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={m_back_length}
                                                />
                                              </td>
                                              <td>x</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={m_back_width}
                                                />
                                              </td>
                                              <td>x</td>
                                              <td>
                                                <input
                                                  className="col-12"
                                                  value={m_back_height}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* mat sizes */}

                                      <div class="form-group row frm-grp-mb">
                                        <label class="col-9 col-form-label lbl-bg-color label-height">
                                          {/* {
                                            data.response.leftConditions
                                              .question3
                                          } */}
                                          Mat sizes are approved by an engineer?
                                        </label>
                                        <div class="col-3 d-flex flex-row cb-mt-5px">
                                          <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                            <input
                                              class="cb-size cb-mt-5px"
                                              type="checkbox"
                                              // value={
                                              //   data.response.leftConditions
                                              //     .question3_ans
                                              // }
                                              checked={question3_ans}
                                            />
                                            <label class="form-check-label">
                                              YES
                                            </label>
                                          </div>
                                          <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                            <input
                                              class="cb-size cb-mt-5px"
                                              type="checkbox"
                                              checked={question3_no}
                                            />
                                            <label class="form-check-label">
                                              NO
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      {/* for crawler */}
                                      <div className="row">
                                        <p
                                          style={{
                                            color: "#000000",
                                            fontWeight: "600",
                                            backgroundColor: "#A7C3E4",
                                            padding: "5px",
                                            margin: "0px",
                                          }}
                                        >
                                          {
                                            data.response.leftConditions
                                              .question4
                                          }
                                        </p>
                                      </div>

                                      {/* Maximum pressure */}
                                      <div class="form-group row frm-grp-mb">
                                        <label class="col-7 col-form-label lbl-bg-color label-height">
                                          {/* {
                                            data.response.leftConditions
                                              .question5
                                          }
                                          = */}
                                          Maximum pressure applied by the
                                          tracks=
                                        </label>
                                        <div class="col-5">
                                          <input
                                            type="text"
                                            class="col-12"
                                            value={question5_ans}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group row frm-grp-mb">
                                        <label class="col-form-label lbl-bg-color label-height">
                                          {
                                            data.response.leftConditions
                                              .question6
                                          }
                                          =
                                        </label>
                                      </div>
                                      <div className="form-group row frm-grp-mb">
                                        <input
                                          className="col-12"
                                          id="inputPassword"
                                          value={question6_ans}
                                          placeholder=""
                                        />
                                      </div>

                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            fontWeight: "bold",
                                            backgroundColor: "#ffffff",
                                            color: "black",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <p style={{ margin: "10px" }}>
                                            SETUP 2
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            margin: "10px 0px 0px 100px",
                                          }}
                                        >
                                          <img
                                            src={cranesetup1}
                                            alt="Cranesetup"
                                            className="liftimg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Euip and Chem end */}

                        {/* work envi end */}

                        {/*  Clseout end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Footer /> */}
            </form>
            {/* ... End of Footer...  */}
          </div>
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div>
      {/* </page> */}
      {isLoading && (
        <Loader
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CraneOperationPermit_Web;
