import React, { useState } from 'react'
import { useEffect } from 'react'

function ScopeOfElevatedWork(props) {
    //     const handleScopeElevated = (key, val) => {
    //         props.setScopeOfElevatedWork((prev) => ({ ...prev, [key]: val }))
    //     }


    const handleScopeElevated = (key, val) => {
        if (key === 'checked' && !val) {
            // If checkbox is unchecked, clear the 'description' field
            props.setScopeOfElevatedWork((prev) => ({ ...prev, checked: val, description: "" }));
        } else {
            props.setScopeOfElevatedWork((prev) => ({ ...prev, [key]: val }));
        }
    }


    return (
        <div className="m-2">
            <div className="row">
                <div >
                    <div>
                        <div>
                            <div class="form-group">
                                <label for="inputText">Location(building/area/equipment)</label>
                                <div>
                                    <input class="form-control" id="lc" placeholder="Location"
                                        value={props.data?.['Location(building/area/equipment)']}
                                        onChange={(e) => handleScopeElevated('Location(building/area/equipment)', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="inputText">Company <span className='required'> *</span></label>
                                <div>
                                    <input class="form-control" id="cm" placeholder="Company"
                                        value={props.data?.Company}
                                        onChange={(e) => handleScopeElevated('Company', e.target.value)} />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="inputText">Elevated work scope/description</label>
                                <div>
                                    <input class="form-control" id="dc" placeholder="description"
                                        value={props.data?.['Elevated work scope/description']}
                                        onChange={(e) => handleScopeElevated('Elevated work scope/description', e.target.value)} />
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        checked={props.data?.checked}
                                        onChange={(e) => {
                                            handleScopeElevated('checked', e.target.checked);
                                        }}
                                    />
                                    < label class="form-check-label" for="flexCheckDefault">See Scope on Core Permit</label>
                                </div>
                                <div>
                                    <input class="form-control" id="dc" placeholder="description"
                                        value={props.data?.['description']}
                                        onChange={(e) => handleScopeElevated('description', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default ScopeOfElevatedWork;