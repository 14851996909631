 
import React, { useState, useRef } from 'react';
import { FaEdit, FaEye, FaSearch, FaFileExcel, FaTimes } from 'react-icons/fa';
import * as XLSX from 'xlsx';   
import Loader from '../Utilities/Loader';

export const CustomTable = ({ headers, data, FileName, showAction, onEditClicked, ActionEdit, ActionView, onViewClicked, ActionName, showButtons, loader }) => {
    const [SearchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);  // Current page
    const [rowsPerPage] = useState(10);  // Number of rows per page
    const [sortConfig, setSortConfig] = useState(null); // For sorting columns
    const tableRef = useRef();
    const [dataPerPage, setDataPerPage] = useState(10);
    // Function to get nested values
    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => {
            if (part.includes('[') && part.includes(']')) {
                const [arrayName, index] = part.split(/\[|\]/).filter(Boolean);
                return acc && acc[arrayName] && acc[arrayName][parseInt(index, 10)];
            }
            return acc && acc[part];
        }, obj);
    };

    //const StatusFlag= (data.status || data.active) ? 'Active' : 'in-Active';
 
    // Handle edit action
    const handleEdit = (data) => {
        onEditClicked(data);
    };

    // Handle view action
    const handleView = (data) => {
        onViewClicked(data);
    };

    // Handle search input
    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };
    const handleClear=()=>{
        setSearchInput('')
    }
 
    // const handleExportToExcel = () => {
    //     const worksheet = XLSX.utils.json_to_sheet(
    //         filteredData.map((row, index) =>
    //             headers.reduce((acc, header) => {
    //                 const value = getNestedValue(row, header.field);
    //                 acc[header.header] = header.field === "active" || header.field === "status" 
    //                     ? (value === '1' || value === 'true' || value === 'Active' || value === true ? "Active" : "In-active")   
    //                     : value;
    //                 return acc;
    //             },  { "Sl.No": index + 1 })
    //         )
    //     );
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, `${FileName}_Master_data`); 
    //     const fileName = `${FileName}_Master_${new Date().toISOString().slice(0, 10)}.xlsx`;  
    //     XLSX.writeFile(workbook, fileName);
    // };
    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredData.map((row, index) =>
                headers.reduce((acc, header) => {
                    const value = getNestedValue(row, header.field);
                    acc[header.header] = header.field === "active" || header.field === "status" 
                        ? (value === '1' || value === 'true' || value === 'Active' || value === true ? "Active" : "In-active")   
                        : value;
                    return acc;
                },  { "Sl.No": index + 1 })
            )
        );
        
        const workbook = XLSX.utils.book_new();
    
        // Truncate the sheet name to 31 characters
        const sheetName = `${FileName}_Master_data`.slice(0, 31);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); 
    
        const fileName = `${FileName}_Master_${new Date().toISOString().slice(0, 10)}.xlsx`;  
        XLSX.writeFile(workbook, fileName);
    };
        

    // Filtered data based on search input
    const filteredData = SearchInput
        ? data.filter((row) =>
            headers.some((header) =>
                getNestedValue(row, header.field)
                    ?.toString()
                    .toLowerCase()
                    .includes(SearchInput.toLowerCase())
            )
        )
        : data;

    // Sorting function
    const sortedData = React.useMemo(() => {
        if (sortConfig !== null) {
            const { key, direction } = sortConfig;
            return [...filteredData].sort((a, b) => {
                const aValue = getNestedValue(a, key);
                const bValue = getNestedValue(b, key);
                if (aValue < bValue) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredData;
    }, [filteredData, sortConfig]);

    const indexOfLastRow = currentPage * dataPerPage;
    const indexOfFirstRow = indexOfLastRow - dataPerPage;
    const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const pageNumbers = [];
    const totalPages = Math.ceil(sortedData.length / dataPerPage);

    const handleDataPerPageChange = (e) => {
        setDataPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page
    };

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const generatePageNumbers = () => {
        let visiblePages = [];
    
        if (totalPages <= 5) {
            visiblePages = pageNumbers;
        } else {
            if (currentPage <= 3) {
                visiblePages = [1, 2, 3, 4, '...', totalPages];
            } else if (currentPage >= totalPages - 2) {
                visiblePages = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            } else {
                visiblePages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
            }
        }
    
        if (!visiblePages.includes(totalPages)) {
            visiblePages.push(totalPages);
        }
    
        return visiblePages;
    };
    
    
    // Handle sorting on header click
    // const requestSort = (key) => {
    //     let direction = 'ascending';
    //     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
    //         direction = 'descending';
    //     }
    //     setSortConfig({ key, direction });
    // };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };
    
    const sortedData1 = React.useMemo(() => {
        if (!sortConfig || !sortConfig.key) return data;
    
        return [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }, [data, sortConfig]);






    // Calculate the range (Showing X to Y of Z entries)
    const startIndex = indexOfFirstRow + 1;
    const endIndex = indexOfLastRow < sortedData.length ? indexOfLastRow : sortedData.length;
    const totalEntries = sortedData.length;

 

    return (
        <div>
            <div className='d-flex flex-direction-row justify-content-between'>
                <div className='d-flex flex-direction-row'>
                    <select
                        value={dataPerPage}
                        onChange={handleDataPerPageChange}
                        className="form-control ml-3"
                        style={{ width: 'auto' }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </select>
                    <span className='ml-3 mt-1'>entries per page</span>
                </div> 
                    <div className="d-flex mb-2">
                        <div style={{ position: 'relative', width: '55%', marginRight: '8px' }}>
                            <FaSearch
                                style={{
                                    position: 'absolute',
                                    left: '18px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    color: '#aaa',
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Search..."
                                value={SearchInput}
                                onChange={handleSearch}
                                className="form-control"
                                style={{
                                    paddingLeft: '35px', width: '100%',
                                }}
                            />
                                    {SearchInput && (
                                    <FaTimes
                                        onClick={handleClear}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#aaa',
                                        }}
                                    />
                                )}
                        </div> 
                        {showButtons && (
                            <div>
                                <button
                                    className="btn btn-success mr-2 d-inline-flex align-items-center"
                                    onClick={handleExportToExcel}
                                    style={{ gap: '5px', marginRight: '4px', background: "#6f42c1" }}
                                >
                                    <FaFileExcel />  <span>Download Excel</span>
                                </button>
                            </div>
                        )}
                    </div>
            </div>
            <div style={{overflow:'auto', height:458}}>

            <table
                ref={tableRef}
                data-testid="table"
                className="table table-bordered Content_data_table table-striped responsiveTable"
                style={{ width: '100%' }}
            >
                <thead>
                    <tr>
                        <th className="tableHead">S.No</th>
                        {/* {headers?.map((header, index) => (
                            <th key={index} className="tableHead" onClick={() => requestSort(header.field)} style={{ cursor: 'pointer' }}>
                                {header.header} 
                                {sortConfig?.key === header.field ? (sortConfig.direction === "asc"
                                        ? <i className="fa fa-sort-up fa-xs icon-opacity ml-2" />
                                        : <i className="fa fa-sort-down fa-xs icon-opacity ml-2" />)
                                      : <i className="fa fa-sort fa-xs icon-opacity ml-2" />
                                }
                            </th>
                        ))} */}

                    {headers?.map((header, index) => (
                    <th
                        key={index}
                        className="tableHead"
                        onClick={() => requestSort(header.field)}
                        style={{ cursor: 'pointer' }}
                    >
                        {header.header}
                        {sortConfig?.key === header.field ? (
                            sortConfig.direction === "ascending" ? (
                                <i className="fa fa-sort-up fa-xs icon-opacity ml-2" />
                            ) : (
                                <i className="fa fa-sort-down fa-xs icon-opacity ml-2" />
                            )
                        ) : (
                            <i className="fa fa-sort fa-xs icon-opacity ml-2" />
                        )}
                    </th>
                ))}
                        {showAction && <th className="tableHead">{ActionName}</th>}
                    </tr>
                </thead>
                <tbody>
                    {currentRows.length > 0 ? (
                        currentRows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{indexOfFirstRow + rowIndex + 1}</td> {/* Serial number continues across pages */}
                                {headers.map((header, colIndex) => (
                                    <td key={colIndex} className="tableData">
                                        {header.field === "active" || header.field === "status"
                                            ? (row.status === true || row.status === "true" || row.active === true || row.active === "true" || row.status === "Active" || row.status === "1") ? (
                                                <span style={{ color: 'green' }}>Active</span>
                                            ) : row.status === null || row.active === null ? "" : row.active === "InActive" || row.status === "InActive" || row.status === "0" ? (
                                                <span style={{ color: 'red' }}>In-Active</span>
                                            ) : (
                                                <span style={{ color: 'red' }}>In-Active</span>
                                            )
                                            : getNestedValue(row, header.field)}
                                    </td>
                                ))}
                                {ActionEdit && (
                                    <td>
                                        <FaEdit
                                            className="tex-center"
                                            style={{ cursor: 'pointer', color: "#1a969e" }}
                                            onClick={() => handleEdit(row)}
                                        />
                                    </td>
                                )}
                                {ActionView && (
                                    <td>
                                        <FaEye
                                            className="tex-center"
                                            style={{ cursor: 'pointer', color: "#1a969e" }}
                                            onClick={() => handleView(row)}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={headers.length + (ActionEdit ? 1 : 0) + (ActionView ? 1 : 0)} style={{ textAlign: 'center' }}>
                                No results found
                            </td>
                        </tr>
                    )}
                </tbody>

                {loader && <Loader />}
            </table>
            </div>

            <div className="d-flex justify-content-between mt-3">
                <div className="d-flex align-items-center">
                    <span>Showing {startIndex} to {endIndex} of {totalEntries} {`${FileName}s`}</span>
                </div>

                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                        </li>
                        {generatePageNumbers().map((page, index) => (
                            <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                {page === '...' ? (
                                    <span className="page-link">...</span>
                                ) : (
                                    <button className="page-link" onClick={() => paginate(page)}>
                                        {page}
                                    </button>
                                )}
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                        </li>
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => paginate(totalPages)}>Last</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};
