import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import downarrowwhite from '../images/Down-arrow-white.png'
import uparrowwhite from '../images/Up-arrow-white.png';

import Circle from '../images/CircleAccordianImg.png'

const ButtonAccordion = ({ title, content, bgColor, buttonData, bordercolor, isOpen, setIsOpen }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleItem = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    // const [isOpen, setIsOpen] = useState(false);

    const bgColorT = (bgColor != '') ? bgColor : 'red';

    const toggleAccordion = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const buttonStyle = {
        display: "inline-block",
        border: "none",
        padding: "12px",
        cursor: "pointer",
        marginRight: "10px",
        border: "medium",
        backgroundrepeat: "no-repeat",
        backgroundposition: "center",
        backgroundsize: "45px",
        width: "46px",
        height: "50px",
        marginright: "10px",
    };

    return (
        <div className={`mb-4`}>
            <button
                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${isOpen ? "custom-rounded-top" : "custom-rounded"}`} style={{ height: "55px", backgroundColor: bordercolor, border: "none" }}

                onClick={toggleAccordion}
            >
                <span></span>

                <span style={{ fontSize: '16px', lineHeight: '24px', alignContent: "center" }}>
                    {title}
                </span>
            </button>
            {isOpen && (
                <div className="p-4" style={{ fontSize: '16px', fontWeight: 'normal', lineHeight: '24px', border: `2px solid ${bordercolor}`, bordertop: "none", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                    {content}
                </div>
            )}

        </div>
    );
};

export default ButtonAccordion;
