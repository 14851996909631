import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CostAnalysisAPI, getMasters } from "../../Utilities/URLCONSTANTS";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
var requObj;

Chart.register(...registerables);

const CostChart = () => {
  const [CostCount, setCostCount] = useState([]);
  const [CostLabels, setCostLabels] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [showDatePicker, setDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [SelectedType, setSelectedType] = useState("");
  const [error, setError] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [functionalLead, setFunctionalLead] = useState([]);
  const [selectedFunctionalLead, setSelectedFunctionalLead] = useState()

  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;
    const CostAnalysisRequest = CostAnalysisAPI;
    const requObj = {
      type: (startDate && endDate) ? "" : selectedValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      functionalLead: selectedFunctionalLead
    };
    try {
      const response = await axios.post(CostAnalysisRequest, requObj);
      console.log("CostAnalysisRequest" + JSON.stringify(response.data));
      const formattedDatasets = response.data.response.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.map((value) => parseFloat(value.toFixed(2))),
      }));

      setCostCount(formattedDatasets);
      setCostLabels(response.data.response.labels);


    } catch (error) {
      console.log("CostAnalysisRequest", error);
    }
  };
  useEffect(() => {
    getMasterData();
    const shouldFetchData = selectedValue === "DTD" && startDate && endDate;
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate, selectedFunctionalLead);
    }
  }, [startDate, endDate, selectedValue, selectedFunctionalLead]);

  const data = {
    labels: CostLabels,
    datasets: CostCount,
  };

  // console.log(JSON.stringify(data)+"niharika");

  // const options = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: "left",
  //       labels: {
  //         boxWidth: 10, // Set box width for each legend item
  //         padding: 10, // Set padding between legend items
  //       },
  //     },
  //     title: {
  //       display: true,
  //       text: "",
  //       align: "start",
  //     },

  //     datalabels: {
  //       color: '#fff',
  //       font: {
  //         size: 12,
  //         weight: 'bold',
  //       },
  //       formatter: (value, context) => {
  //         return value && value !== 0 ? value + "%" : null;
  //       },
  //       anchor: 'end',
  //       align: 'start',
  //     },
  //   },
  // };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
        labels: {
          boxWidth: 10, // Set box width for each legend item
          padding: 10, // Set padding between legend items
        },
      },
      title: {
        display: true,
        text: "",
        align: "start",
      },
      datalabels: {
        color: '#fff',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value, context) => {
          const threshold = 1; // Set the threshold value
          // Return the value as a percentage if it meets the condition
          return value && value >= threshold ? value + "%" : null;
        },
        anchor: 'end',
        align: 'start',
      },
    },
  };



  const getMasterData = async () => {
    const apiGetmaster = getMasters;
    const headers = await GetApiHeaders();
    try {
      const response = await axios.post(apiGetmaster, {}, { headers });
      setFunctionalLead(response.data.response.functionsLeadsList);
      console.log(
        "getmaster response---D----->",
        response.data.response.functionsLeadsList
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div className="card p-4">
      <label className="chartname">Cost Center</label>
      <div className="d-flex" id="filtersInputs">
        <select
          className="form-control w-80"
          value={selectedValue}
          onChange={handleSelect}
        >
          <option value="">All</option>
          <option value="MTD">Monthly</option>
          <option value="YTD">Yearly</option>
          <option value="DTD">Custom Selection</option>
        </select>

        <select className="form-control w-100" value={selectedFunctionalLead} onChange={(e) => setSelectedFunctionalLead(e.target.value)}>
          <option value="">Select Functional Lead</option>
          {functionalLead.map((option) => (
            <option key={option.code} value={option.functionalLead}>
              {option.functionalLead}
            </option>
          ))}
        </select>
      </div>
      {selectedValue === "DTD" && (
        <div className="d-flex">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            className="form-control  custom-datepicker"
          />
          <DatePicker
            style={{ marginLeft: "130px  " }}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            className="form-control  custom-date"
          />
        </div>
      )}
      <di style={{ color: "red", fontSize: 12, marginLeft: 11 }}> {error}</di>
      <div
        className="chart-container"
        style={{ height: "215px", marginTop: "-20px" }}
      >
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default CostChart;
