import React, { useState, useEffect } from 'react'
import { coreEquipmentConstant } from '../../Utilities/corePermit.constant';

function CorePermitM2Equipment(props) {

    const [equipment, setEquipment] = useState(props.data)
    const [isChecked, setIsChecked] = useState(false);

    console.log(props.isEquipmentToggleOn, "isEquipmentToggleOn");


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        var object = { ...equipment };
        object.answer = event.target.checked ? "true" : "";
        if (event.target.checked) {
            var equipmentList = equipment.equipmentList;
            const updatedEquipmentList = equipmentList.map(equip => ({ ...equip, checked: false, inputValue: "" }));
            object.equipmentList = updatedEquipmentList;
        }
        setEquipment(object);
    };


    useEffect(() => {
        setEquipment(props.data)
    }, [])

    useEffect(() => {
        props.onDataChange(equipment)
    }, [equipment])

    const handleCheckBox = (id) => {
        const updatedEquipmentList = equipment.equipmentList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
        setEquipment((prev) => ({ ...prev, equipmentList: updatedEquipmentList }))
    }

    const handleInput = (id, list, value) => {
        const updatedArmsBodyList = equipment.equipmentList.map(opItem => {
            if (id == opItem.id) {
                return { ...opItem, 'inputValue': value }
            } else {
                return opItem
            }
        })
        setEquipment((prev) => ({ ...prev, equipmentList: updatedArmsBodyList }))
    }

    return (<div>
        <div class="form-group row">
            <label class="col-9 col-form-label">List the hazards of the area, the work and the equipment for the specific task.</label>
            <div class="col-3">
                <div class="form-check form-check-inline">

                    <input class="form-check-input mt-0"
                        type="checkbox" id="inlineCheckbox2"
                        // disabled={props.approvalStatus || !props.isEquipmentToggleOn}
                        disabled={props.approvalStatus}
                        onClick={handleCheckboxChange} checked={equipment.answer != "" ?
                            equipment.answer == "true" ? true : false : false} value={equipment.answer != "" ?
                                equipment.answer == "true" ? true : false : false} />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">N/A</label>
                </div>
            </div>
        </div>

        {
            equipment.equipmentList.map(item => (
                <>
                    <div class="form-group row" key={item.id}>
                        <div class="" style={{ display: "flex", flexDirection: "row" }}>
                            <div class="col-12 form-check form-check-inline d-flex align-items-center ">
                                <input class="form-check-input  mt-0"
                                    checked={item.checked}
                                    readOnly={props.approvalStatus}
                                    onChange={() => { handleCheckBox(item.id, 'equipmentList') }}
                                    type="checkbox"
                                    id={`coreEquipmentList${item.id}`}
                                    value="option1"
                                    // disabled={equipment.answer == 'true' || !props.isEquipmentToggleOn}
                                    disabled={equipment.answer == 'true'}
                                />
                                <label class="form-check-label  mt-0"
                                    for={`coreEquipmentList${item.id}`}>{item.cbTitle}</label>
                                {
                                    item.inputAvaiable &&
                                    <div class="col-8 mt-2">

                                        <input type='text' value={item.inputValue}
                                            readOnly={props.approvalStatus}
                                            onChange={(e) => {
                                                console.log(e, e.target.value)
                                                handleInput(item.id, '', e.target.value)
                                            }} class="col-12 form-control" id="inputPassword"
                                            placeholder=""
                                            // disabled={equipment.answer == 'true' || !props.isEquipmentToggleOn}
                                            disabled={equipment.answer == 'true'}
                                        />
                                    </div>}

                            </div>
                        </div>
                    </div>

                </>
            ))
        }

    </div>
    )
}

export default CorePermitM2Equipment