import React, { useEffect, useState } from 'react'
import { coreChemicalConstant } from '../../Utilities/corePermit.constant';

function CorePermitM2Chemical(props) {

    const [chemical, setChemical] = useState(props.data)
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        var object = { ...chemical };
        object.answer = event.target.checked ? "true" : "";
        if (event.target.checked) {
            var chemicalHazardsList = chemical.chemicalHazardsList;
            const updatedChemicalHazardsList = chemicalHazardsList.map(chem => ({ ...chem, checked: false, inputValue: "", }));
            object.chemicalHazardsList = updatedChemicalHazardsList;
        }
        setChemical(object);
    };


    useEffect(() => {
        setChemical(props.data)
    }, [])

    useEffect(() => {
        props.onDataChange(chemical)
    }, [chemical])

    const handleCheckBox = (id) => {
        const updatedChemicalList = chemical.chemicalHazardsList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
        setChemical((prev) => ({ ...prev, chemicalHazardsList: updatedChemicalList }))
    }
    const handleInput = (id, list, value) => {
        const updatedArmsBodyList = chemical.chemicalHazardsList.map(opItem => {
            if (id == opItem.id) {
                return { ...opItem, 'inputValue': value }
            } else {
                return opItem
            }
        })
        setChemical((prev) => ({ ...prev, chemicalHazardsList: updatedArmsBodyList }))
    }

    return (
        <div>
            <div class="form-group row">
                <label class="col-9 col-form-label">List below any contained process chemical(s) or job specific chemical(s). See Safety Data Sheet(s) for review of safety & health hazards.</label>
                <div class="col-3">
                    <div class="form-check form-check-inline d-flex align-items-center mt-3">

                        <input class="form-check-input mt-0"
                            disabled={props.approvalStatus}
                            type="checkbox"
                            onClick={handleCheckboxChange} checked={chemical.answer != "" ?
                                chemical.answer == "true" ? true : false : false} value={chemical.answer != "" ?
                                    chemical.answer == "true" ? true : false : false} />
                        <label class="form-check-label mt-0" >N/A</label>
                    </div>
                </div>
            </div>
            <div className="Corper-subtxt">
                <p className="card-subheading">HAZARDS</p>
            </div>
            <div class="col-12" style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'space-between' }}>

                {chemical.chemicalHazardsList.map(item => (<>
                    {
                        item.inputAvaiable ? <div class="form-group row mt-2 col-6">

                            <div class="col-12 form-check form-check-inline d-flex align-items-center">
                                <input class="form-check-input  mt-0"
                                    // readOnly={props.approvalStatus}
                                    // checked={item.checked === true}
                                    checked={item.checked}
                                    onChange={() => { handleCheckBox(item.id) }}
                                    type="checkbox"
                                    id={`coreChemicalList${item.id}`}
                                    value="option1"
                                    // disabled={(chemical.answer)||(props.approvalStatus)}
                                    disabled={chemical.answer == 'true'}
                                />
                                <input type='text' value={item.inputValue} readOnly={props.approvalStatus} onChange={(e) => {
                                    handleInput(item.id, '', e.target.value)
                                }} class="col-12 form-control" id="inputPassword" placeholder=""
                                    disabled={chemical.answer == 'true'} />
                                {/* </div> */}
                            </div>
                        </div> :
                            <div class="form-group row mt-2 col-6">
                                {/* <div class="col-12" style={{ display: "flex", flexDirection: "row" }}> */}
                                <div class="col-12 form-check form-check-inline d-flex align-items-center">
                                    <input class="form-check-input  mt-0"
                                        checked={item.checked === true}
                                        onChange={() => { handleCheckBox(item.id) }}
                                        type="checkbox" id={`coreChemicalList${item.id}`}
                                        value="option1"
                                        disabled={chemical.answer == 'true'}
                                    //readOnly={props.approvalStatus}
                                    />
                                    <label class="form-check-label  mt-0" for={`coreChemicalList${item.id}`}>{item.cbTitle}</label>
                                </div>
                                {/* </div> */}

                            </div>
                    }</>))}
            </div>
            {/* <div class="form-group row mt-2">
                                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input  mt-1" type="checkbox"  value="option1" />
                                                                    <label class="form-check-label  mt-0" >Flammable</label>
                                                                </div>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input mt-1" type="checkbox"  value="option2" />
                                                                    <label class="form-check-label mt-0" >Inhalation</label>
                                                                </div>

                                                            </div>
                                                        </div>




                                                        <div class="form-group row">
                                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input  mt-1" type="checkbox"  value="option1" />
                                                                    <label class="form-check-label  mt-0" >Skin irritant</label>
                                                                </div>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input mt-1" type="checkbox"  value="option2" />
                                                                    <label class="form-check-label mt-0" >Corrosive</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input  mt-1" type="checkbox"  value="option1" />
                                                                    <label class="form-check-label  mt-0" >Toxic</label>
                                                                </div>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input mt-1" type="checkbox"  value="option2" />
                                                                    <label class="form-check-label mt-0" >Reactive</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="col-6 form-check form-check-inline">
                                                                    <input class="form-check-input mt-1" type="checkbox"  value="option2" />
                                                                    <label class="form-check-label mt-0" >Skin absorption</label>
                                                                </div>
                                                            </div>
                                                        </div> */}

        </div>
    )
}

export default CorePermitM2Chemical