import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import {  RMActivityMasterAdd, RMActivityMasterGet,  } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";

export const RMActivityMaster = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false) 
    const [name, setName] = useState()
    const [locationName, setLocationName] = useState() 
    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg , setResponseMsg]= useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [uomError, setUomError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)
    const [description, setDescription] = useState()
    const [uom, setUom] = useState()

    const getData = async () => {
        const URL = RMActivityMasterGet;
        try {
            setLoader(true)
            const response = await axios.get(URL) 
            setData(response.data.response)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    } 

    useEffect(() => {
        getData() 
    }, [])

    const headersData = [
        {
            field: "name",
            header: "Name",
        }, 
        {
            field: "createdBy.firstName",
            header: "Created By",
        },
        {
            field: "description",
            header: "Description",
        },
      
        {
            field: "active",
            header: "Status",
        },
    ];

    const onEditClicked = (data) => { 
        console.log(data);
        
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.name) 
        setDescription(data.description) 
        setStatus(data.active)
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('') 
        setStatus('')
        setDescription('') 
    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) =>{
        const Input = e.target.value
        setName(Input)
        if (Input) {
            setError('')
        }
    }
 
 
    const handleStatus = (e) =>{
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }

    const handleDescription = (e) =>{
        const Input = e.target.value
        setDescription(Input)
        
    }
console.log(status, "status")
    const handleSubmitData = async () =>{ 
        if (!status) {
            setStatusError("Select Status")
        }
     
        if (!name) {
            setError('Enter Name')   
        }
        const apiUrl = RMActivityMasterAdd;
        const reqData = { 
            id: saveEdit === "Submit" ? 0 : id,
            name: name, 
            description : description,
            status  : status 
         }
         const headers = await GetApiHeaders() 
         try { 
            if (name !==''  && status !=='') {
                setLoader(true)
                const response = await axios.post(apiUrl,reqData, {headers} )
             if (response.data.statusCode == 200 || response.data.status == 500) {
                 setShowModal(false)
                 setMsgPopup(true)
                 setResponseMsg(response.data.message);
                 setLoader(false)
             }
            }
         } catch (error) {
            console.log(error )
         }
    }
    const handleOk = () =>{
        setMsgPopup(false)
        getData()
    }

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">  Master</h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text"> R&M Activity Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add R&M Activity Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader = {loader}
                                            FileName={'RM_Activity'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'R&M Activity  Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                            R&M Activity  <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='EnterR&M Activity  Name'value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12 '>
                                                                <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                    Description  
                                                                </label>
                                                                <div className="input-group input-group w-100">
                                                                <textarea  class="form-control"  style={{ height: 125 }} id="textAreaExample1" placeholder='Enter Description'
                                                                rows="2"  value={description}  onChange={handleDescription} > 
                                                                </textarea>
                                                                </div> 
                                                            </div>
                                                    </div>
                                                    <div className='row'>
                                                          
                                                        <div className='col-md-6 col-12' style={{ marginTop: -65 }}>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                            >Status <span style={{ color: "red" }}>*</span> </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control p-2" value={status} onChange={handleStatus}>
                                                                <option value="" disabled>Select Status</option> 
                                                                <option value={true}>Active</option>
                                                                <option value={false}>In-Active</option>
                                                            </select>
                                                        <span style={{ color: "red" }}> {statusError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal 
                                         showModal={msgPopup}
                                        msgPopup ={msgPopup}
                                        handleOk= {handleOk}
                                        handleCancel_new={handleCancel_new}
                                        tittle={'R&M Activity Master'}
                                        childrens={<div className="p-4 d-flex justify-content-center" style={{fontSize:"18px", color:"green", fontWeight:500}}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}