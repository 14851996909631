export const corePermitInitialsConst = {
    "initialsOne": "",
    "initialsTwo": "",
    "initialsThree": "",
    "dateInputOne": "",
    "dateInputTwo": "",
    "dateInputThree": "",
    "dateInputFour": "",
    "dateInputFive": "",
    "dateInputSix": "",
    "permitsOne": "",
    "permitsTwo": ""
}

export const coreEmergencyConst = {

    "phoneNumber": "",

    "assemblyLocation": ""

}
export const corePeopleConstant = {
    "date": "",
    "startTime": "",
    "endTime": "",
    "permitIssuer": "",
    "additionalAttachment": "",
    "jobSiteInspector": {
        "jobSiteInspector": "",
        "startTime": "",
        "endTime": "",
        "selectedValue": null
    },
    "question": "",
    "workLimit": "",
    "namesWorkingPermit": "",
    "receiverTransferTo": "",
    "noOfWorkingPermit": "",
    "peopleList": [
        {
            "question": "All people working under this permit have the necessary site training and/or orientations",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "1",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "Emergency procedures have been reviewed and are understood (alarms, locations of assembly points and evacuation routes)",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "2",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "Emergency equipment has been located and reviewed (Examples include, but are not limited to: nearest safety shower, eye wash,fire extinguisher and telephone and/or intercom",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "3",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "The scope and boundaries of any other work in the area that could impact this permitted work has been reviewed and is understood",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "4",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "Other workers in the area have been notified that this permitted work could impact their work, including the locations of any barricades",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "5",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "All equipment to be worked on has been properly prepared and identified and is ready to work on",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "6",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "All energy sources, including radiation, are isolated, tagged and confirmed",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "7",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "For demolition and renovation work, has the work area been inspected for asbestos",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "8",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "A procedure is in place and reviewed for tasks as required by department Procedure Use Policy (PUP)",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "9",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "Line of fire hazards have been discussed and mitigation techniques will be in place",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "10",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "Fall hazards are present and an additional assessment will need to be completed (ladders, scaold)",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "11",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "phoneNumber": "",
    "answer": "",
    "coSignature": "",
    "extendedInspection": {
        "jobSiteInspector": null,
        "startTimeOne": "",
        "endTimeOne": "",
        "selectedValue": ""
    },
    "extendedUse": {
        "yes": "",
        "NA": "",
        "mon": "",
        "tues": "",
        "wed": "",
        "thus": "",
        "fri": "",
        "sat": "",
        "sun": "",
        "startTimeOne": "",
        "endTimeOne": "",
    },
    "permitReceiver": "",
    "plantFacility": "",
    "onSiteInspection": null,
    "jobSiteInspector": "",
    "inspectorName": "",
    "code": "",
    "company": "",
    "startTimeTwo": "",
    "id": "1",
    "endTimeTwo": "",
    "issueTransferTo": ""
}

export const coreEnergyConstant = {
    "question": "For the scope of work, check the appropriate permit below. Fill out the checklist or permit and attach it to this CORE Permit using the same number as the CORE permit.",
    "answer": "",
    "energyList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "16",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Line/Equipment Opening Permit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "17",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Hot Work Permit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "18",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Confined Space Entry Permit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "19",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Critical Elevated Work Permit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "20",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Crane Operation Permit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "21",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Isolations of Energy (IOE)"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "22",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Excavation"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "23",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Hydroblasting"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "24",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Pressure washing"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "25",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Energized electrical work"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "26",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Rigging Permit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "27",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "IOE master #:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "28",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "id": "3"
}

export const coreEquipmentConstant = {
    "question": "List the hazards of the area, the work and the equipment for the specific task.",
    "answer": "",
    "id": "4",
    "equipmentList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "29",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Noise"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "30",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Pinch Points"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "31",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Radiation"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "32",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Thermal burn"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "33",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Sharp edges"
        },

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "34",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Inert atmosphere"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "35",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Flash fire"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "36",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Heat stress"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "37",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Asbestos"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "38",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Pressure extreme"
        },

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "39",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Flying debris"
        },



        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "40",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Vibrations"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "41",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Falls"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "42",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Dust type:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "43",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "44",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Electrical/high voltage"
        }
    ]
}

export const coreChemicalConstant = {
    "question": "List below any contained process chemical(s) or job specific chemical(s). See Safety Data Sheet(s) for review of safety & health hazards.",
    "answer": "",
    "chemicalHazardsList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "45",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Flammable"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "46",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Inhalation"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "47",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Toxic"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "48",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "SkinAbsorption"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "49",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "SkinIrritant"
        },

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "50",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Corrosive"
        },

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "51",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Reactive"
        },

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "52",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "id": "5"
}

export const coreProtectionConstant = {
    "respiratoryList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "83",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Supplied air"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "84",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Full face purifying"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "85",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Powered air purifying"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "86",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "1/2 face purifying"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "87",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Dust mask"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "88",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "CartridgeType:"
        },
        // {
        //     "question": "",
        //     "requiredField": false,
        //     "inputValue": "",
        //     "checked": "",
        //     "id": "89",
        //     "inputAvaiable": true,
        //     "selectedValue": "",
        //     "cbTitle": ""
        // },
        // {
        //     "question": "",
        //     "requiredField": false,
        //     "inputValue": "",
        //     "checked": "",
        //     "id": "90",
        //     "inputAvaiable": true,
        //     "selectedValue": "",
        //     "cbTitle": ""
        // },
        // {
        //     "question": "",
        //     "requiredField": false,
        //     "inputValue": "",
        //     "checked": "",
        //     "id": "91",
        //     "inputAvaiable": true,
        //     "selectedValue": "",
        //     "cbTitle": ""
        // }
    ],
    "question": "Protection required for hazards - list personal protective equipment, precautions and safeguards required to protect against hazards. place an asterisk (*) beside any personal protective equipment with specific requirements/upgrade/downgrade, then define in section below.",
    "answer": "",
    "commentValue": "",
    "feetLegsList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "92",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Safety shoes"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "93",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Closed Toed Shoes"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "94",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Rubber boots - ankle"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "95",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Rubber boot - calf high"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "96",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Metatarsal protection"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "97",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Knee pads"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "98",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Thigh protection"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "99",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        },
        // {
        //     "question": "",
        //     "requiredField": false,
        //     "inputValue": "",
        //     "checked": "",
        //     "id": "100",
        //     "inputAvaiable": true,
        //     "selectedValue": "",
        //     "cbTitle": ""
        // }
    ],
    "armsBodyList": [

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "73",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Chemical suit type:"
        },

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "74",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Tyvek type:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "75",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Nomex (FRPC)"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "76",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Flash suit"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "77",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Welding jacket"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "78",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Apron type:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "79",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Lab coat"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "80",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Long sleeve"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "81",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "High visability/reflective"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "82",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "id": "6",
    "handsList": [

        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "63",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Chemical resistant gloves type:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "64",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Mechanical"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "65",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Welding"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "66",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Leather"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "67",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Thermal"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "68",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Electrical"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "69",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Gauntlet"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "70",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Cut resistant - level:"
        },
        // {
        //     "question": "",
        //     "requiredField": false,
        //     "inputValue": "",
        //     "checked": "",
        //     "id": "71",
        //     "inputAvaiable": true,
        //     "selectedValue": "",
        //     "cbTitle": ""
        // },
        // {
        //     "question": "",
        //     "requiredField": false,
        //     "inputValue": "",
        //     "checked": "",
        //     "id": "72",
        //     "inputAvaiable": true,
        //     "selectedValue": "",
        //     "cbTitle": ""
        // },
    ],
    "headFaceList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "53",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Hard hat/bump cap"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "54",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Welding hood"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "55",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Chemical hood type:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "56",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Hearing protection"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "57",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Double hearing protection"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "58",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Face shield"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "59",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Chemical goggles"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "60",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Safety glasses with side shields"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "61",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Safety glasses - foam seal"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "62",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    comments: {
        "cbTitle": "Reason for upgrading or downgreading PPE",
        "commentValue": " "
    },
}

export const coreWorkEnvConstant = {
    "barricadesList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "115",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Caution/Danger tape"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "116",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Physical"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "117",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "additionalRequirmentList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "118",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Electrical GFCI"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "119",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Written operating procedure"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "120",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Communication type:"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "121",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Safety attendant(s) required:Name: "
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "122",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Limited time exposure:(breaks, etc)"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "123",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Fire extinguisher"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "124",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "question": "",
    "answer": "",
    "accessList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "101",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Ladder"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "102",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Mobile scaffold"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "103",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Aerial lift devices"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "104",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Scaffold"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "105",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "signage": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "125",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "126",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "127",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "128",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "id": "7",
    "preventionList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "106",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Guardrail"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "107",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Restraint line"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "108",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Retractable lifeline"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "109",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Designated area:"
        }
    ],
    "protectionList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "110",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Harness w/ lanyard"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "111",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Vertical lifeline"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "112",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Safety net"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "113",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Horizontal lifeline"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "114",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": "Defined anchor point:"
        }
    ]
}


export const environMentConstants = {
    fromEnergy: false,
    question: "",
    answer: "",
    environmentList: [
        {
            question:
                "Potential environmental impact & procedures for addressing impact: spills and/or leaks",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "12",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "",
        },
        {
            question: "Waste disposal & housekeeping requirements:",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "13",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "",
        },
        {
            question: "Special atmospheric monitoring requirements:",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "14",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "",
        },
        {
            question: "Ergonomic concerns & safeguards:",
            requiredField: false,
            inputValue: "",
            checked: false,
            id: "15",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "",
        },
    ],
    id: "2",
};

export const CloseOutConstants = {
    date: "",
    closeOutList: [
        {
            question: "",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "157",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "Closeout job site inspection completed?",
        },
        {
            question: "",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "158",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "Status of job and equipment reviewed?",
        },
        {
            question: "",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "159",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "Additional paperwork reconciled?",
        },
    ],
    question: "",
    answer: "",
    id: "12",
    time: "",
    remarks: "",
};

export const changeConstants = {
    date: "",
    question: "",
    answer: "",
    receiver: null,
    sighn: "",
    changeList: [
        {
            question: "",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "154",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "Scope changed",
        },
        {
            question: "",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "155",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "Hazards changed",
        },
        {
            question: "",
            requiredField: false,
            inputValue: "",
            checked: "",
            id: "156",
            inputAvaiable: false,
            selectedValue: "",
            cbTitle: "Control measures changed",
        },
    ],
    id: "11",
    time: "",
    issuer: "",
    signature: "",
};

export const responsibilitites = {
    "answer": "",
    "question": "Responsibilities communicated to permit receiver:",
    "responsibilitiesList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "149",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Conditions for work stoppage"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "150",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Reporting changes that assect job safety"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "151",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Crew accountability & addressing workers’ concerns"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "152",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Reporting a change in the permit receiver"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "153",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "phone": "",
    "initials": "",
    "id": "10"
}

export const corePermitReceiverConstants = {
    "question": "The Core Permit Receiver will ensure that all workers:",
    "answer": "",
    "corePermitReceiverList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "141",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Understand the hazards of the area, equipment and work and the safeguards in place."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "142",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Understand potential environmental impact and procedures for addressing this."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "143",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Understand and follow Personal Protective Equipment requirements."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "144",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Have the necessary skills and knowledge to do the permitted work safety."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "145",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Know emergency procedures, alarm and assembly points."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "146",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Know the location of and how to use emergency equipment."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "147",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Know the scope of other work that could impact this work."
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "148",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Understand the scope of this permitted work."
        }
    ],
    "id": "9"
}

export const trainingConstants = {
    "question": "Workers have specialty training as required:",
    "answer": "",
    "trainingList": [
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "129",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Equipment"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "130",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "PPEuse"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "131",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "PoweredIndustrialTruck"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "132",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "CompetentPerson"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "133",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "AerialWorkPlatform"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "134",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Hazwoper"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "135",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "PCB,PMN,5e"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "136",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Certified"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "137",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Asbestos"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "138",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "QualifiedPerson"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "139",
            "inputAvaiable": false,
            "selectedValue": "",
            "cbTitle": "Lead"
        },
        {
            "question": "",
            "requiredField": false,
            "inputValue": "",
            "checked": "",
            "id": "140",
            "inputAvaiable": true,
            "selectedValue": "",
            "cbTitle": ""
        }
    ],
    "id": "8"
}


