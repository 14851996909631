import React, { useState, useEffect, useRef } from "react";
//import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
//import Accordion from "react-bootstrap/Accordion";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  PartOneExavation,
  PartTwoExavation,
  PartThreeExavation,
  EmergencyConst,
} from "../Utilities/exavationPermit-JSON";
import Emergency from "../components/excavationPermit/Emergency";
import PartONE from "../components/excavationPermit/PartONE";
import PartTWO from "../components/excavationPermit/PartTWO";
import PartTHREE from "../components/excavationPermit/PartTHREE";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  MOBILE_NUMBER,
  USER_PROFILE,
  retrieveData,
  ADDITIONAL_PERMITID,
  PERMIT_NAME,
  checkForEmptyValue,
} from "../Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import CorePermitInputFields from "../components/excavationPermit/corePermitInputFields";
import arrowIcon from "../images/Left-arrow.png";
import Accordion from "../Accordation/Accordian";
import axios from "axios";

//Approve
import Approved from "../images/Approved.png";
import Reject from "../images/Reject.png";
import Loader_MS from "../Utilities/Loader_MS";
import Loader from "../Utilities/Loader";
import Loader_Bulk from "../Utilities/Loader_Bulk";

const ExcavationPermit2Additional = (props) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [emergency, setEmergency] = useState(EmergencyConst);
  const [partOne, setPartOne] = useState(PartOneExavation);
  const [partTwo, setPartTwo] = useState(PartTwoExavation);
  const [partThree, setPartThree] = useState(PartThreeExavation);
  const [activeIndex, setActiveIndex] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  //Reject
  const [showNewModal, setShowNewModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [serverRecordId, setServerRecordId] = useState(null);


  //validations
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [partOneToggleOn, setPartOneToggleOn] = useState(true);
  const [partTwoToggleOn, setPartTwoToggleOn] = useState(true);
  const [partThreeToggleOn, setPartThreeToggleOn] = useState(true)
  const [permitIntials, setPermitIntials] = useState(true);




  useEffect(() => {
    console.log(partThree, "partOne");
  }, [partThree]);
  const toggleItem = (index) => {
    // debugger;
    setActiveIndex(index === activeIndex ? null : index);
  };

  const PermitUserIdfromURL = searchParams.get("acceptorUserId");
  const IdFromURL = searchParams.get("corePermitID");
  const PermitNamefromURL = searchParams.get("permitName");
  const AditionpermitIDFromURL = searchParams.get("additionalPermitID");
  const mobileNumberfromURL = searchParams.get("mb");
  const [permitStatus, setPermitStatus] = useState("");
  const [permitObject, setPermitObject] = useState(null);
  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);

    setIsEdit(isEdit);
    // alert(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id) => {
    console.log(id);
    try {
      // First, retrieve data from sessionStorage if needed
      const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);

      if (response.data && response.data.response) {
        const elevated = response?.data?.response?.excavation_permit || [];
        const dataLast = elevated[elevated.length - 1]?.permitObject || null;
        console.log("filteredArray checking", JSON.stringify(dataLast));
        if (
          response.data.response.excavation_permit != null &&
          response.data.response.excavation_permit.length > 0
        ) {
          setPermitStatus(
            response.data.response.excavation_permit.find(
              (item) => item.permitID === AditionpermitIDFromURL
            )?.permitStatus
          );
        }
        if (dataLast) {
          setPermitObject(dataLast);
          setPermitInitials(
            dataLast.permitInitials ? dataLast?.permitInitials : permitInitials
          );
          setEmergency(dataLast.emergency || emergency);
          setPartOne(dataLast.partOne || partOne);
          setPartTwo(dataLast.partTwo || partTwo);
          setPartThree(dataLast.partThree || partThree);
          //Toggle
          setEmergencyToggleOn(dataLast.emergencyToggleOn !== undefined ? dataLast.emergencyToggleOn : emergencyToggleOn);
          setPartOneToggleOn(dataLast.partOneToggleOn !== undefined ? dataLast.partOneToggleOn : partOneToggleOn);
          setPartTwoToggleOn(dataLast.partTwoToggleOn !== undefined ? dataLast.partTwoToggleOn : partTwoToggleOn);
          setPartThreeToggleOn(dataLast.partThreeToggleOn !== undefined ? dataLast.partThreeToggleOn : partThreeToggleOn);
          setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleAprrovedOk = async (e) => {
    e.preventDefault();
    setStatus("");
    setIsLoading(false);
    setShowApproved(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };


  const handleToggleItems = () => {
    setEmergencyToggleOn(!emergencyToggleOn)
  }

  const handlePartOneItems = () => {
    setPartOneToggleOn(!partOneToggleOn)
  }

  const handlePartTwoItems = () => {
    setPartTwoToggleOn(!partTwoToggleOn)
  }

  const handlePartThreeItems = () => {
    setPartThreeToggleOn(!partThreeToggleOn)
  }

  const handleApproved = async (e) => {
    const checkedItems = partOne.list.filter((item) => item.value);
    const partOneChecked = checkedItems.length >= 1 ? "true" : "";
    // part 2
    const checkedPartTwoyes = partTwo.basic.filter(
      (item) => item.title1yes === "true"
    );
    const checkedPartTwoNo = partTwo.basic.filter(
      (item) => item.title1no === "true"
    );

    const ispartTwoCheckedyes =
      checkedPartTwoyes.length >= 1
        ? "true"
        : checkedPartTwoyes.length === 0 && checkedPartTwoNo.length === 0
          ? ""
          : "false";

    const ispartTwoCheckedNa =
      checkedPartTwoNo.length >= 1
        ? "true"
        : checkedPartTwoyes.length === 0 && checkedPartTwoNo.length === 0
          ? ""
          : "false";
    const checkedPartThreeyes = partThree.isTheescavation.cbYes;
    const checkedPartThreeNo = partThree.isTheescavation.cbNo;
    const checkedPartThreeNa = partThree.isTheescavation.cbNA;

    const ispartThreeCheckedyes =
      checkedPartThreeyes === "true"
        ? "true"
        : checkedPartThreeyes === "" &&
          checkedPartThreeNo === "" &&
          checkedPartThreeNa === ""
          ? ""
          : "false";

    const ispartThreeCheckedNo =
      checkedPartThreeNo === "true"
        ? "true"
        : checkedPartThreeyes === "" &&
          checkedPartThreeNo === "" &&
          checkedPartThreeNa === ""
          ? ""
          : "false";

    const ispartThreeCheckedNa =
      checkedPartThreeNa === "true"
        ? "true"
        : checkedPartThreeyes === "" &&
          checkedPartThreeNo === "" &&
          checkedPartThreeNa === ""
          ? ""
          : "false";



    var valuesToCheck = [];

    if (permitInitials) {
      const updatedValues = [...valuesToCheck];
      updatedValues.push({
        permitInitials: {
          initialsOne: permitInitials.initialsOne || "",
          permitsOne: permitInitials.permitsOne || "",

        },
      });
      valuesToCheck = [...updatedValues];
    }

    if (emergencyToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        emergency: {
          phoneNumber: emergency.phoneNumber,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (partOneToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        partOne: {
          list: partOneChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (partTwoToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        partTwo: {
          partTwocbyes: ispartTwoCheckedyes,
          partTwocbno: ispartTwoCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (partThreeToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        partThree: {
          partThreecbYes: ispartThreeCheckedyes,
          partThreecbNA: ispartThreeCheckedNa,
          partThreecbNo: ispartThreeCheckedNo,
        },
      };
      valuesToCheck = [...updatedValues];
    }



    console.log("valuesToCheck" + JSON.stringify(valuesToCheck));
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {

        case "permitInitials":
          if (!permitInitials.initialsOne || !permitInitials.permitsOne) {
            alert("Please Enter Initials and Permits");
            allCasesValid = false;
          }
          break;

        case "emergency":
          if (emergencyToggleOn) {
            alert("Fill the Emergency Data");
            allCasesValid = false;
          }
          break;

        case "partOne":
          if (partOneToggleOn) {
            alert("Please Fill The PartOne Data");
            allCasesValid = false;
          }
          break;

        case "partTwo":
          if (partTwoToggleOn) {
            alert("Please Fill The PartTwo Data");
            allCasesValid = false;
          }
          break;

        case "partThree":
          if (partThreeToggleOn) {
            alert("Please Fill The partThree Data");
            allCasesValid = false;
          }
          break;
      }
    }
    if (e == "Submitted") {
      console.log(e, "handleOk");
      if (allCasesValid) {
        console.log(partOne, "PARTONE");
        const userProfileData = {
          id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
          mobileNumber: mobileNumberfromURL,
          name: "",
          corePermitID: IdFromURL ? IdFromURL : 0,
          additionalPermitName: "excavation_permit",
        };
        const finalJson = {
          request: {
            permitInitials,
            emergency,
            partOne,
            partTwo,
            partThree,
            emergencyToggleOn,
            partOneToggleOn,
            partTwoToggleOn,
            partThreeToggleOn,
          },
          ...userProfileData,
        };
        console.log(finalJson, "finalJson***");
        setIsLoading(true);
        const response1 = await axios.post(saveAdditionalPermit, finalJson);
        setIsLoading(false);

        if (response1.data.statusCode == 200) {
          console.log(e, "handleApproved");
          const ApproveData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL,
            status: "Approve",
            deviceType: "iOS, Android",
            remarks: "",
          };
          const response = await axios.post(ApproveRejectPermit, ApproveData);
          console.log("responce", response);

          setTimeout(() => {
            setIsLoading(false);
            setApiStatus(response.data.message);
            setShowApproved(true);
          }, 3000);
        }
      }
    } else if (e == "Approve") {
      if (allCasesValid) {
        handleSubmit();
      }
    }
  };

  const handleConfirmationOk = () => {
    setShowReject(false);
    setShowConfirmationPopup(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleNewModalOk = () => {
    setShowNewModal(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };



  const handleReject = (e) => {
    e.preventDefault();
    if (!permitInitials.initialsOne || !permitInitials.permitsOne.trim()) {
      alert("Please Enter The Intials and Permits Details");
      return;
    }
    setShowReject(true);
    setRemarks("");
    setIsLoading(false);
  };

  const handleCancelReject = () => {
    setShowReject(false);
  }

  const handleRejectOk = async (e) => {
    try {
      e.preventDefault();

      // Validate remarks input
      if (!remarks.trim()) {
        alert("Please Enter Remarks Before Proceeding.");
        return;
      }

      const userProfileData = {
        id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
        mobileNumber: mobileNumberfromURL,
        name: "",
        corePermitID: IdFromURL ? IdFromURL : 0,
        additionalPermitName: "excavation_permit",
      };

      const finalJson = {
        request: {
          permitInitials,
          emergency,
          partOne,
          partTwo,
          partThree,
          emergencyToggleOn,
          partOneToggleOn,
          partTwoToggleOn,
          partThreeToggleOn,
        },
        ...userProfileData,
      };

      setIsLoading(true);
      try {
        // Save the CorePermit data
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log("Core permit response:", response);

        if (response.data.statusCode === "200") {
          const RejectData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL || "0",
            status: "Reject",
            deviceType: "iOS, Android",
            remarks: remarks,
          };

          // Send rejection data
          const rejectResponse = await axios.post(ApproveRejectPermit, RejectData);
          console.log("Rejection response:", rejectResponse);

          if (rejectResponse.data.statusCode !== "200") {
            setShowConfirmationPopup(true);
            setApiStatus(rejectResponse.data.message);
          } else {
            setShowConfirmationPopup(true);
            setApiStatus("Rejection successful!");
          }
        }
      } catch (err) {
        console.error("Error in submission:", err);
      } finally {
        setIsLoading(false);
        setShowReject(false);
      }
    } catch (error) {
      console.error("Error in handleRejectOk:", error);
    }
  };




  const resetForm = () => {
    setEmergency(EmergencyConst);
    setPartOne(PartOneExavation);
    setPartTwo(PartTwoExavation);
    setPartThree(PartThreeExavation);
    setFinalSubmit(false);
  };

  const handleSubmit = async () => {
    // const dataFromStore = await retrieveData(USER_PROFILE);
    console.log(partOne, "PARTONE");
    const userProfileData = {
      id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
      mobileNumber: mobileNumberfromURL,
      name: "",
      corePermitID: IdFromURL ? IdFromURL : 0,
      additionalPermitName: "excavation_permit",
    };
    const finalJson = {
      request: {
        permitInitials,
        emergency,
        partOne,
        partTwo,
        partThree,
        emergencyToggleOn,
        partOneToggleOn,
        partTwoToggleOn,
        partThreeToggleOn,
      },
      ...userProfileData,
    };
    console.log(finalJson, "finalJson***");
    setIsLoading(true);
    const response = await axios.post(saveAdditionalPermit, finalJson);
    console.log(response.status, "sdmnhba");
    console.log("response", response);
    setApiStatus(response.data.message);
    setIsLoading(false);
    setShowApproved(true);
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}

        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          {finalSubmit ? (
            <>
              <h2>Form Submitted Successfully!!!</h2>
            </>
          ) : (
            <div id="content">
              {/* 
                        <Header /> */}
              <div
                className="row"
                style={{ backgroundColor: "blue", padding: "7px" }}
              ></div>
              <div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between"></div>

                  <div>
                    <form className="ExcavationPermit-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        setPermitInitials={setPermitInitials}
                        editable={true}
                        onDataChange={(data) => {
                          setPermitInitials(data);
                        }}
                      />

                      <Accordion
                        title="EMERGENCY"
                        bgColor="#DF3A1C"
                        handleToggle={handleToggleItems}
                        isToggled={emergencyToggleOn}
                        content={
                          <Emergency
                            data={emergency}
                            emergencyToggleOn={emergencyToggleOn}
                            setEmergency={setEmergency}
                            onDataChange={(data) => {
                              setEmergency(data);
                            }}
                          />
                        }
                        bordercolor="#DF3A1C"
                        showToggle={true}
                      />

                      <Accordion
                        title="PART1"
                        bgColor="#262828"
                        handleToggle={handlePartOneItems}
                        isToggled={partOneToggleOn}
                        content={
                          <PartONE
                            data={partOne}
                            partOneToggleOn={partOneToggleOn}
                            setPartOne={setPartOne}
                            onDataChange={(data) => {
                              setPartOne(data);
                            }}
                          />
                        }
                        bordercolor="#262828"
                        showToggle={true}
                      />
                      <Accordion
                        title="PART2"
                        bgColor="#566060"
                        handleToggle={handlePartTwoItems}
                        isToggled={partTwoToggleOn}
                        content={
                          <PartTWO
                            data={partTwo}
                            partTwoToggleOn={partTwoToggleOn}
                            setPartTwo={setPartTwo}
                            onDataChange={(data) => {
                              setPartTwo(data);
                            }}
                          />
                        }
                        bordercolor="#566060"
                        showToggle={true}
                      />

                      <Accordion
                        title="PART3"
                        bgColor="#C2C9C8"
                        handleToggle={handlePartThreeItems}
                        isToggled={partThreeToggleOn}
                        content={
                          <PartTHREE
                            data={partThree}
                            partThreeToggleOn={partThreeToggleOn}
                            setPartThree={setPartThree}
                            onDataChange={(data) => {
                              setPartThree(data);
                            }}
                          />
                        }
                        bordercolor="#C2C9C8"
                        showToggle={true}
                      />

                      <div>
                        <Modal
                          show={showModal}
                          onHide={() => setShowModal(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                  }}
                                >
                                  <img
                                    src={Approved}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                }}
                              >
                                <h5>Excavation Permit sent for Approval</h5>
                              </p>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "8vh",
                                }}
                              >

                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>

                        {permitStatus !== "" && (
                          <button
                            type="button"
                            onClick={() => {
                              handleApproved(permitStatus);
                            }}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#007AFF",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            {permitStatus == "Submitted" ? "Approve" : "Update"}
                          </button>
                        )}
                        {permitStatus == "Submitted" && (
                          <button
                            type="button"
                            onClick={handleReject}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#FC2727",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            Reject
                          </button>
                        )}
                      </div>
                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                  }}
                                >
                                  <img
                                    src={Approved}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                }}
                              >
                                <h5>{apiStatus} </h5>
                              </p>
                            </div>


                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary"
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                style={{
                                  height: "43px",
                                  backgroundColor: "#007AFF",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={handleAprrovedOk}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Body>

                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Header className="no-border">
                            <Button
                              variant="link"
                              className="close btn btn-danger close_btn"
                              onClick={handleCancelReject}
                            >
                              X
                            </Button>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                  }}
                                >
                                  <img
                                    src={Reject}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                }}
                              >
                                <h5>Are you sure want to Reject?</h5>
                              </p>
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">
                                <h6>Reason:</h6>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "8vh",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                  style={{
                                    height: "43px",
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={handleRejectOk}
                                  disabled={remarks.trim() === ""}
                                >
                                  Ok
                                </Button>
                              </p>
                            </div>

                            <Modal
                              show={showNewModal}
                              onHide={() => setShowNewModal(false)}
                              size="sm"
                              id="newModal"
                              className="ppopup"
                            >
                              <Modal.Body>
                                <div className="row">
                                  <div>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "15vh",
                                      }}
                                    >
                                      <img
                                        src={Approved}
                                        alt="save"
                                        className="popupicons"
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "3vh",
                                      }}
                                    >
                                      <h5>
                                        ExcavationPermit Rejected Successfully
                                      </h5>
                                    </p>
                                  </div>

                                </div>

                                <Button
                                  variant="primary"
                                  onClick={handleNewModalOk}
                                >
                                  Ok
                                </Button>
                              </Modal.Body>
                            </Modal>
                          </Modal.Body>

                          {/* </Modal.Footer> */}
                        </Modal>

                        {/* Confirmation Popup */}
                        <Modal
                          show={showConfirmationPopup}
                          onHide={() => setShowConfirmationPopup(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <span
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "13vh",
                                }}
                              >
                                <img
                                  src={Reject}
                                  alt="save"
                                  className="popupicons"
                                />
                              </span>
                            </div>

                            <div className="form-group">
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                  color: "green",
                                }}
                              >
                                <h5>ExcavationPermit Rejected Successfully</h5>
                              </p>
                            </div>
                            {/* <Modal.Footer style={{ border: '0' }}> */}
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary"
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                style={{
                                  height: "43px",
                                  backgroundColor: "#007AFF",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={handleConfirmationOk}
                              >
                                Ok
                              </Button>
                            </div>

                            {/* </Modal.Footer > */}
                          </Modal.Body>
                        </Modal>
                      </div>
                    </form>
                    {/* </div>
                                            </div> */}
                    {/* </div>
                                    </div> */}
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          )}
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */}
        {/* <Logout /> */}
      </div>
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default ExcavationPermit2Additional;
