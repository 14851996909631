import React, { useState } from 'react'
import { trainingConstants } from '../../Utilities/corePermit.constant';

const CorePermitTraining = ({ trainData, setTrainData, approvalStatus }) => {
    const [isChecked, setIsChecked] = useState(false);


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        var object = { ...trainData };
        object.answer = event.target.checked ? "true" : "";
        if (event.target.checked) {
            var trainingList = trainData.trainingList;
            const updatedTrainingList = trainingList.map(train => ({ ...train, checked: false, inputValue: "", }));
            object.trainingList = updatedTrainingList;
        }
        setTrainData(object);
    };



    const handleCheckBox = (id) => {
        const updatedEnergyList = trainData.trainingList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
        setTrainData((prev) => ({ ...prev, trainingList: updatedEnergyList }))
    }
    const setInputsValue = (id, val) => {
        const updatedEnergyList = trainData.trainingList.map(opItem => ({ ...opItem, inputValue: opItem.id === id ? val : opItem.inputValue }))
        setTrainData((prev) => ({ ...prev, trainingList: updatedEnergyList }))
    }

    return (
        <div>
            <div class="form-group row">
                <label class="col-9 col-form-label">Workers have specialty training as required:</label>
                <div class="col-3">
                    <div class="form-check form-check-inline d-flex align-items-center mt-3">
                        <input class="form-check-input mt-0"
                            type="checkbox" id="inlineCheckbox2"
                            disabled={approvalStatus}
                            onClick={handleCheckboxChange} checked={trainData.answer != "" ?
                                trainData.answer == "true" ? true : false : ""}
                            value={trainData.answer != "" ?
                                trainData.answer == "true" ? true : false : ""} />
                        <label class="form-check-label mt-0" for="inlineCheckbox2">N/A</label>
                    </div>
                </div>
            </div>
            <div class="col-12" style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'space-between' }}>

                {trainData.trainingList.map((item =>
                (<> {item.inputAvaiable ? <div class="form-group row col-12">

                    <div class="form-check form-check-inline d-flex align-items-center">
                        <input class="form-check-input  mt-0"
                            checked={item.checked}
                            readOnly={approvalStatus}
                            // checked={item.checked === true}
                            onChange={() => { handleCheckBox(item.id) }}
                            type="checkbox"
                            id={`coreEnergyItem${item.id}`}
                            value="option1"
                            disabled={trainData.answer == 'true'}
                        />
                        <input value={item.inputValue}
                            readOnly={approvalStatus}
                            onChange={(e) => { setInputsValue(item.id, e.target.value) }}
                            class="form-control col-12" id="inputPassword" placeholder=""
                            disabled={trainData.answer == 'true'}
                        />
                    </div>

                </div>
                    : <div class="form-group row col-12">
                        <div class="form-check form-check-inline d-flex align-items-center">
                            <input class="form-check-input  mt-0"
                                readOnly={approvalStatus}
                                checked={item.checked === true}
                                onChange={() => { handleCheckBox(item.id) }}
                                type="checkbox"
                                id={`coreEnergyItem${item.id}`}
                                value="option1"
                                disabled={trainData.answer == 'true'}
                            />
                            <label class="form-check-label  mt-0" for={`coreEnergyItem${item.id}`}>{item.cbTitle}</label>
                        </div>
                    </div>
                }</>)))}
            </div>

        </div>
    )
}

export default CorePermitTraining