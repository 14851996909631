import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button, Modal } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import {   addStoreMaster,  getMainStoreMaster,  getStoreMaster,   } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";

export const SubSiteMasters = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [siteNameGet, setSiteNameGet] = useState([])
    const [name, setName] = useState()
    const [siteName, setSiteName] = useState()
    const [siteId, setSiteId] = useState()
    const [status, setStatus] = useState()
    const [storeLocationname, setStoreLocationname] = useState() 
    const [storeLocationnameError, setStoreLocationnameError] = useState('') 
    const [contactPersonError, setContactPersonError] = useState('') 
    const [contactPerson, setContactPerson] = useState() 
    const [contactMobileNo, setContactMobileNo] = useState() 
    const [contactMobileNoError, setContactMobileNoError] = useState('') 
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg , setResponseMsg]= useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [siteError, setSiteError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)

    const getData = async () => {
        const headers = await GetApiHeaders();
        const URL = getStoreMaster;
        try {
            setLoader(true)
            const response = await axios.get(URL, {headers}) 
            setData(response.data.response.StoreMaster)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    }

    const MainSiteDropdown = async () =>{
        const apiUrl = getMainStoreMaster;
        const headers = await GetApiHeaders();
        try {
            const response = await axios.get(apiUrl, {headers})
            setSiteNameGet(response.data.response.MainStore)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
        MainSiteDropdown()
    }, [])

    const headersData = [
        {
            field: "mainStoreLocationName",
            header: " Main Site ",
        },
        {
            field: "name",
            header: "SubSite ",
        },
        {
            field: "storeLocation",
            header: "Store Location",
        },
 

        {
            field: "contactPerson",
            header: "Contact Person",
        },
        {
            field: "contactMobileNumber",
            header: "Contact Mobile No",
        },
      
        {
            field: "status",
            header: "Status",
        },
    ];

    const onEditClicked = (data) => { 
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.name)
        setStatus(data.status)
        setSiteId(data.mainStoreID.toString())
        setSiteName(data.mainStoreLocationName)
        setStoreLocationname(data.storeLocation)
        setContactMobileNo(data.contactMobileNumber)
        setContactPerson(data.contactPerson)
        setError('')
        setSiteError('')
        setStoreLocationnameError('')
        setContactPersonError('')
        setContactMobileNoError("");
        setStatusError('')
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('')
        setSiteName('')
        setStatus('')
        setSiteId('')
        setContactMobileNo('')
        setContactPerson('')
        setStoreLocationname('')
        setError('')
        setSiteError('')
        setStoreLocationnameError('')
        setContactPersonError('')
        setContactMobileNoError("");
        setStatusError('')

    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) =>{
        const Input = e.target.value
        const alphabeticInput = Input.replace(/[^a-zA-Z ]/g, "");
        setName(alphabeticInput)
        if (Input) {
            setError('')
        }
    }

    const handleSite = (e) =>{
        const Input = e.target.value
        const SelectedSiteId = e.target.options[e.target.selectedIndex].getAttribute("data-siteId");
        setSiteId(SelectedSiteId)
        setSiteName(Input) 
        if (Input) {
            setSiteError('')
        }
    }
    const handleStoreKication = (e) =>{
        const Input = e.target.value
        setStoreLocationname(Input)
        if (Input) { 
            setStoreLocationnameError('')
        }
    } 
 
    const handlePerson = (e) =>{
        const Input = e.target.value
        const alphabeticInput = Input.replace(/[^a-zA-Z ]/g, "");
        setContactPerson(alphabeticInput)
        if (Input) { 
            setContactPersonError('')
        }
    } 
    const handleMobileNo = (e) =>{
 
        const input = e.target.value;

        let result = input.replace(/[^0-9]/g, "");  
        if (input) {
            setContactMobileNoError("");
        }
        if (result.length > 10) { 
          result = result.substr(0, 10);
        }
    
        if (/^[6-9]/.test(result)) { 
          setContactMobileNo(result);
        } else {
          setContactMobileNo("");  
        }
    } 

    const handleStatus = (e) =>{
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }

    const handleSubmitData = async () =>{ 
        const apiUrl = addStoreMaster;
        const headers = await GetApiHeaders() 
        const reqData = { 
            Id: saveEdit === "Submit" ? "0" : id.toString(),
            mainStoreID: siteId,
            mainStoreLocationName: siteName,
            name: name,
            userID: headers.userId,
            storeLocation: storeLocationname,
            contactPerson: contactPerson,
            contactMobileNumber: contactMobileNo,
            status: status, 
            remarks: '',
         }
         try {
            if (!status) {
                setStatusError("Select Status")
            }
            if (!siteName) {
                setSiteError('Select Main Site')
            }
            if (!name) {
                setError('Enter SubSite Name')   
            }
            if (!storeLocationname) {
                setStoreLocationnameError('Enter Store Location') 
            }
            if (!contactPerson) {
                setContactPersonError('Enter Contact Person') 
            }
            if (!contactMobileNo) {
                setContactMobileNoError('Enter Mobile No') 
            }
            if (name !=='' && siteName !=='' && status !=='' && contactMobileNo !=='' && contactPerson !=='' && storeLocationname !=='') {
                setLoader(true)
                const response = await axios.post(apiUrl,reqData, {headers} )
             if (response.data.statusCode == 200 || response.data.status == 500) {
                 setShowModal(false)
                 setMsgPopup(true)
                 setResponseMsg(response.data.response);
                 setLoader(false)
             }
            }
             
         } catch (error) {
            console.log(error )
         }
    }
    const handleOk = () =>{
        setMsgPopup(false)
        getData()
    }

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">  Master</h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text"> SubSite Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add SubSite Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader = {loader}
                                            FileName ={'SubSite'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'SubSite Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>

                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1">
                                                           Main Site Name <span style={{ color: "red" }}>*</span> 
                                                            </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control" value={siteName}onChange={handleSite}>
                                                                <option value="" disabled>Select Main Site </option> 
                                                                {
                                                                    siteNameGet.map((item,index)=>
                                                                        item.status === '1' ?(<option key={item.id} value={item.name} data-siteId={item.id} >
                                                                            {item.name}
                                                                        </option>) : null
                                                                     )
                                                                }
                                                            </select>
                                                             <span style={{ color: "red" }}> {siteError}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12 '>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                SubSite Name <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter SubSite Name'value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                            Store Location   <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter Store Location Name'value={storeLocationname} onChange={handleStoreKication} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {storeLocationnameError}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                            Contact Person   <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter Contact Person'value={contactPerson} onChange={handlePerson} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {contactPersonError}</span>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                            Contact Mobile No   <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter Mobile Number'value={contactMobileNo} onChange={handleMobileNo} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {contactMobileNoError}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                            >Status <span style={{ color: "red" }}>*</span> </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control p-2" value={status} onChange={handleStatus}>
                                                                <option value="" disabled>Select Status</option> 
                                                                <option value={'1'}>Active</option>
                                                                <option value={'0'}>In-Active</option>
                                                            </select>
                                                        <span style={{ color: "red" }}> {statusError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal 
                                         showModal={msgPopup}
                                        msgPopup ={msgPopup}
                                        handleOk= {handleOk}
                                        handleCancel_new={handleCancel_new}
                                        tittle={'SubSite Master'}
                                        childrens={<div className="p-4 d-flex justify-content-center" style={{fontSize:"18px", color:"green", fontWeight:500}}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}