import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../src/Pages/SideMenu";
import Header from "../../src/Pages/Header";
import Footer from "../../src/Pages/Footer";
import Logout from "../../src/Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../src/Utilities/Loader";
import { equipmentMonitorAssignedList } from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import ModalImage from "react-modal-image";
import moment from "moment";

const RepairMaintenace = (category, index) => {
  const [data, setData] = useState([]);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [estDate, setEstDate] = useState();
  const [corePermit, setCorePermit] = useState("");
  const [assignWork, setAssignWork] = useState();
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleRadioChange = (value) => {
    setCorePermit(value);
  };
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.createdByEmpName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.equipment.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.startDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.site
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.subSite.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
    console.log("fdsafaGFH", filteredData);
  };

  const handleAprove = () => {
    setBoxVisible(true);
  };

  const closeBox = () => {
    setBoxVisible(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);

    //  window.location.reload();
  };
  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const handleDateChange = (e) => {
    setEstDate(e.target.value);
  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  console.log(currentItems);
  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = async () => {
    const getApi = equipmentMonitorAssignedList;

    const headers = await GetApiHeaders();

    // const   headers={
    //   "Accept": "application/json",
    //   "Content-Type": "application/json",
    //   // "deviceType": "Web",
    //   userId: 1,
    //   mobileNumber: 9704164746
    // }

    try {
      setIsLoading(true);
      const response = await axios.post(getApi, {}, { headers });

      setData(response.data.response.equipmentMonitoring);
      console.log("res:", response);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (err) {
      console.error("error", err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClear = () => {
    setSearchTerm("");
  };
  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Preventive Maintenance </h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline">
                      <div className="card-body " style={{ padding: "10px" }}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{}}
                        >
                          <div className="d-flex align-items-center">
                            <label className="mr-2">Show </label> &nbsp;&nbsp;
                            <select
                            // value={itemsPerPage} onChange={handleItemsPerPageChange}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              {/* Add more options as needed */}
                            </select>
                            &nbsp;&nbsp;
                            <label> entries</label>
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginRight: "-10px" }}
                          >
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2">
                                  Preventive Maintenance
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>

                              {data.map((item, index) => {
                                var newDateFormat = moment(
                                  item.workCreatedDate
                                ).format("DD-MMM-YYYY hh:mm:ss A");
                                var scheduleDate = moment(item.date).format(
                                  "DD-MMM-YYYY "
                                );
                                var startDate = moment(item.startDate).format(
                                  "DD-MMM-YYYY "
                                );
                                var endDate = moment(item.endDate).format(
                                  "DD-MMM-YYYY "
                                );
                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mb-2"
                                        id="dns"
                                      >
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            <div
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="ProductNameHeaderEq d-flex"
                                            >
                                              <span
                                                className=""
                                                style={{ width: "17%" }}
                                              >
                                                <b className="">
                                                  {" "}
                                                  Request ID &nbsp;&nbsp;
                                                  :&nbsp;{" "}
                                                </b>{" "}
                                                <span>
                                                  {" "}
                                                  {item.serverRecordId}
                                                </span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "33%" }}
                                              >
                                                <b className="">
                                                  {" "}
                                                  Work Created Date &nbsp;&nbsp;
                                                  :&nbsp;{" "}
                                                </b>{" "}
                                                <span> {newDateFormat}</span>
                                              </span>
                                              <span style={{ width: "30%" }}>
                                                <b className="quantty">
                                                  {" "}
                                                  Created By{" "}
                                                  <span
                                                    style={{ marginLeft: 10 }}
                                                  >
                                                    :&nbsp;{" "}
                                                  </span>
                                                </b>{" "}
                                                <span>
                                                  {" "}
                                                  {item.createdByEmpName}{" "}
                                                </span>
                                              </span>

                                              <span
                                                className="  "
                                                style={{ width: "18%" }}
                                              >
                                                <b> Status &nbsp;&nbsp;: </b>
                                                &nbsp;
                                                <span>
                                                  {" "}
                                                  {item.status === "Open" ? (
                                                    <span
                                                      style={{
                                                        color: "#FF0000",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Open
                                                    </span>
                                                  ) : item.status ===
                                                    "Close" ? (
                                                    <span
                                                      style={{
                                                        color: "#008000",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Close
                                                    </span>
                                                  ) : item.status}{" "}
                                                </span>{" "}
                                                &nbsp; &nbsp;
                                              </span>
                                            </div>
                                          </Accordion.Header>

                                          <Accordion.Body>
                                            <div style={{ fontSize: 14, width: "100%", }}
                                              className="ProductNameForRandM d-flex mt-3"   >
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                  Created by Mobile No
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '55%', display: 'inline-block' }}>{item.createdByEmpMobileNumber}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                  Schedule For
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '55%', display: 'inline-block' }}>{scheduleDate}</span>
                                              </span>
                                              {item.hasOwnProperty(
                                                "location"
                                              ) &&
                                                item.location != "" &&
                                                item.location != 0 &&
                                                item.locationId != "" &&
                                                item.locationId != 0 && (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Location
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.location}</span>
                                                  </span>
                                                )}
                                            </div>
                                            <div style={{ fontSize: 14, width: "100%", }}
                                              className="ProductNameForRandM d-flex mt-3"   >
                                              {item.hasOwnProperty(
                                                "subLocation"
                                              ) &&
                                                item.subLocation != "" &&
                                                item.subLocation != 0 &&
                                                item.subLocationId != 0 &&
                                                item.subLocationId != 0 && (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Sub Location
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.subLocation}</span>
                                                  </span>
                                                )}
                                              {item.hasOwnProperty("site") &&
                                                item.site != "" &&
                                                item.site != 0 && (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Site
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.site}</span>
                                                  </span>

                                                )}
                                              {item.hasOwnProperty("subSite") &&
                                                item.subSite != "" &&
                                                item.subSite != 0 && (
                                                  <span style={{ width: '33.4%' }}>
                                                    <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                      Sub Site
                                                    </span>
                                                    <span >  :&nbsp;
                                                    </span>
                                                    <span style={{ width: '55%', display: 'inline-block' }}>{item.subSite}</span>
                                                  </span>

                                                )}
                                            </div>

                                            <div style={{ fontSize: 14, width: "100%", }}
                                              className="ProductNameForRandM d-flex mt-3"   >
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                  Equipment
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '55%', display: 'inline-block' }}>{item.equipment}</span>
                                              </span>

                                              {item.startDate !== "" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Start Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{startDate}</span>
                                                </span>

                                              ) : (
                                                ""
                                              )}

                                              {item.endDate !== "" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    End Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{endDate}</span>
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <div style={{ fontSize: 14, width: "100%", }}
                                              className="ProductNameForRandM d-flex mt-3"   >
                                              {item.startTime !== "" ||
                                                item.status === "Close" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Start Time
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.startTime}</span>
                                                </span>) : ("")}
                                              {item.endTime !== "" ||
                                                item.status === "Close" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    End Time
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.endTime}</span>
                                                </span>) : ("")}

                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                  Equipment Description
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '55%', display: 'inline-block' }}>{item.equipmentDescription}</span>
                                              </span>

                                            </div>

                                            <div style={{ fontSize: 14, width: "100%", }}
                                              className="ProductNameForRandM d-flex mt-3"   >
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                  Assign To Shift
                                                  {/* Technician Role */}
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '55%', display: 'inline-block' }}>{item.technicianShiftName}</span>
                                              </span>
                                              {/* <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                  Assign Work to Mobile No
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '55%', display: 'inline-block' }}>{item.assignWorkToMobileNumber}</span>
                                              </span> */}


                                              {item.abnormality !== "" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Equipment Condition
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-block' }}>{item.abnormality}</span>
                                                </span>) : ("")}
                                            </div>

                                            <div style={{ fontSize: 14, width: "100%", }}
                                              className="ProductNameForRandM d-flex mt-3"   >
                                              {item.remarks !== "" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }} >
                                                    Remarks
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.remarks}</span>
                                                </span>) : (
                                                ""
                                              )}

                                              {item.status === "Close" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '43%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '58%' }}> {item.uploadCheckList.map(
                                                    (imageUrl, imgIndex) => (
                                                      <span key={imgIndex}
                                                        style={{
                                                          marginRight: "10px",
                                                          display:
                                                            "inline-block",
                                                          border: "1px solid",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        >
                                                          <img
                                                            key={imgIndex}
                                                            src={imageUrl}
                                                            alt={`Image ${imgIndex}`}
                                                            style={{
                                                              width: "30px",
                                                              height: "40px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleImageClick(
                                                                imageUrl
                                                              )
                                                            }
                                                          />
                                                        </span>
                                                      </span>
                                                    )
                                                  )}</span>
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div
                                              style={{ fontSize: 14 }}
                                              className="ProductNameForRandM d-grid"
                                            >
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </div>
                        <div
                          className="d-flex justify-content-between mb-10"
                          style={{ borderRadius: 5, padding: 15 }}
                        >
                          <div>
                            Showing {indexOfFirstItem + 1} to{" "}
                            {Math.min(indexOfLastItem, totalItems)} of{" "}
                            {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className="borderleft-radius-5"
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>

                              <button
                                className="ul-pagination page-item"
                                style={{
                                  backgroundColor:
                                    currentPage == 1 ? "#0056b3" : "white",
                                  color: currentPage == 1 ? "white" : "black",
                                }}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className="ul-pagination page-item"
                                  style={{
                                    backgroundColor:
                                      currentPage == number
                                        ? "#0056b3"
                                        : "white",
                                    color:
                                      currentPage == number ? "white" : "black",
                                  }}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className="ul-pagination page-item"
                                  style={{
                                    backgroundColor:
                                      currentPage ==
                                        Math.ceil(totalItems / itemsPerPage)
                                        ? "#0056b3"
                                        : "white",
                                    color:
                                      currentPage ==
                                        Math.ceil(totalItems / itemsPerPage)
                                        ? "white"
                                        : "black",
                                  }}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className="borderright-radius-5"
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <Loader_Bulk
                    loading={isLoading}
                    message={"Fetching Data. Please Wait..!"}
                    loderimage={loaderImage}
                  />
                )}
              </div>
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default RepairMaintenace;
