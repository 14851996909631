import React, { useState, useEffect, useRef } from "react";
//import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
//import Accordion from "react-bootstrap/Accordion";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  BasicDetailsConstant,
  PermitIssuerConst,
  PermitReceiverConst,
  EmergencyConst,
  EqipmentInformationConst,
  ImportantConst,
  LiftConditionsConst,
  NoteConstant,
} from "../Utilities/cranePermit-JSON";
//Crane
import CorePermitInputFields from "../components/cranePermit/corePermitInputFields";
import PermitIssuer from "../components/cranePermit/PermitIssuer";
import PermitReceiver from "../components/cranePermit/PermitReceiver";
import Emergency from "../components/cranePermit/Emergency";
import BasicDetails from "../components/cranePermit/BasicDetails";
import Equipment from "../components/cranePermit/Equipment";
import Important from "../components/cranePermit/Important";
import LiftConditions from "../components/cranePermit/LiftConditions";
import Note from "../components/cranePermit/Note";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import { COREPERMIT_MOBILE_NUMBER, checkForEmptyValue } from "../Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
//loader img
import Loader_Bulk from "../Utilities/Loader_Bulk";

import Accordion from "../Accordation/Accordian";
import axios from "axios";

//Approve
import Approved from "../images/Approved.png";

const CranePermitMain = (props) => {
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [permitIssuer, setPermitIssuer] = useState(PermitIssuerConst);
  const [permitReceiver, setPermitReceiver] = useState(PermitReceiverConst);
  const [emergency, setEmergency] = useState(EmergencyConst);
  const [basicDetails, setBasicDetails] = useState(BasicDetailsConstant);
  const [eqipmentInformation, setEquipmentInf] = useState(
    EqipmentInformationConst
  );
  const [important, setImportant] = useState(ImportantConst);
  const [liftConditions, setLiftConditions] = useState(LiftConditionsConst);
  const [note, setNote] = useState(NoteConstant);
  const [activeIndex, setActiveIndex] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);

  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  //loader
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  //Reject
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [serverRecordId, setServerRecordId] = useState(null);
  const addtionalpermitstatus = searchParams.get("permitstatus");

  //Validations 
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [basicDetailsToggleOn, setBasicDetailsToggleOn] = useState(true);
  const [equipmentToggleOn, setEquipmentToggleOn] = useState(true);
  const [importantToggleOn, setImportantToggleOn] = useState(true);
  const [liftConditionsToggleOn, setLiftConditionsToggleOn] = useState(true);
  const [noteListToggleOn, setNoteListToggleOn] = useState(true);
  const [permitIssuerToggleOn, setPermitIssuerToggleOn] = useState(true);
  const [permitRecieverToggleOn, setPermitRecieverToggleOn] = useState(true);

  useEffect(() => {
    console.log(liftConditions, "eq");
  }, [liftConditions]);
  const toggleItem = (index) => {
    // debugger;
    setActiveIndex(index === activeIndex ? null : index);
  };

  // api
  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  const resetForm = () => {
    setPermitInitials(corePermitInitialsConst);
    setEmergency(EmergencyConst);
    setBasicDetails(BasicDetailsConstant);
    setEquipmentInf(EqipmentInformationConst);
    setImportant(ImportantConst);
    setLiftConditions(LiftConditionsConst);
    setNote(NoteConstant);
    setPermitIssuer(PermitIssuerConst);
    setPermitReceiver(PermitReceiverConst);
  };

  const handleToggleItems = () => {
    setEmergencyToggleOn(!emergencyToggleOn)
  }

  const handleBasicDetailsItems = () => {
    setBasicDetailsToggleOn(!basicDetailsToggleOn)
  }

  const handleEquipmentItems = () => {
    setEquipmentToggleOn(!equipmentToggleOn)
  }
  const handleImportantToggleOnItems = () => {
    setImportantToggleOn(!importantToggleOn)
  }

  const handleLiftConditionsToggleOnItems = () => {
    setLiftConditionsToggleOn(!liftConditionsToggleOn)
  }

  const handleIsNoteListToggleOnItems = () => {
    setNoteListToggleOn(!noteListToggleOn)
  }

  const handlePermitIssuerToggleOnItems = () => {
    setPermitIssuerToggleOn(!permitIssuerToggleOn)
  }

  const handlePermitReceiverToggleOnItems = () => {
    setPermitRecieverToggleOn(!permitRecieverToggleOn)
  }


  useEffect(() => {
    // resetForm();
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    const IdFromURLwithEDit = searchParams.get("corePermitId");
    var IdFromURL;
    var isEdit = "true";
    var additional_permit_id;
    if (IdFromURLwithEDit == null) {
    } else {
      IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
      isEdit = IdFromURLwithEDit.toString().split("/")[1];
      additional_permit_id = IdFromURLwithEDit.toString().split("/")[2];
    }

    setIsEdit(isEdit);
    // alert(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id, additional_permit_id) => {
    try {
      // First, retrieve data from sessionStorage if needed
      const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
      // Make an API request using Axios to get permits by core permit ID
      const mobileNumber = sessionStorage.getItem("COREPERMIT_MOBILE_NUMBER");
      const name = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      const PermitID = sessionStorage.getItem("CorePermitM2_PERMITID");
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: mobileNumber,
        name: name,
        PermitID: addtionalpermitstatus == "Submit" ? PermitID : id, //id
      });
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);
      if (addtionalpermitstatus != "Submit") {
        if (response.data && response.data.response) {
          const elevated = response?.data?.response?.crane_permit || [];
          const dataLast =
            elevated.find((item) => item.permitID === additional_permit_id)
              ?.permitObject || null;
          console.log("filteredArray checking", JSON.stringify(dataLast));

          if (dataLast) {
            console.log("dataLast", dataLast);
            console.log("ssn id" + response.serverRecordId);
            setPermitInitials(
              dataLast?.permitInitials
                ? dataLast?.permitInitials
                : permitInitials
            );
            setPermitIssuer(
              dataLast?.permitIssuer ? dataLast?.permitIssuer : permitIssuer
            );
            setPermitReceiver(
              dataLast?.permitReceiver
                ? dataLast?.permitReceiver
                : permitReceiver
            );
            setEmergency(dataLast?.emergency ? dataLast?.emergency : emergency);
            setBasicDetails(
              dataLast?.basicDetails ? dataLast?.basicDetails : basicDetails
            );
            setEquipmentInf(
              dataLast?.eqipmentInformation
                ? dataLast.eqipmentInformation
                : eqipmentInformation
            );
            setImportant(dataLast.important ? dataLast.important : important);
            setLiftConditions(
              dataLast.liftConditions ? dataLast.liftConditions : liftConditions
            );
            setNote(dataLast.note ? dataLast.note : note);
            //Toggle values
            setEmergencyToggleOn(dataLast.emergencyToggleOn !== undefined ? dataLast.emergencyToggleOn : emergencyToggleOn);
            setBasicDetailsToggleOn(dataLast.basicDetailsToggleOn !== undefined ? dataLast.basicDetailsToggleOn : basicDetailsToggleOn);
            setEquipmentToggleOn(dataLast.equipmentToggleOn !== undefined ? dataLast.equipmentToggleOn : equipmentToggleOn);
            setImportantToggleOn(dataLast.importantToggleOn !== undefined ? dataLast.importantToggleOn : importantToggleOn);
            setLiftConditionsToggleOn(dataLast.liftConditionsToggleOn !== undefined ? dataLast.liftConditionsToggleOn : liftConditionsToggleOn);
            setNoteListToggleOn(dataLast.noteListToggleOn !== undefined ? dataLast.noteListToggleOn : noteListToggleOn);
            setPermitIssuerToggleOn(dataLast.permitIssuerToggleOn !== undefined ? dataLast.permitIssuerToggleOn : permitIssuerToggleOn);
            setPermitRecieverToggleOn(dataLast.permitRecieverToggleOn !== undefined ? dataLast.permitRecieverToggleOn : permitRecieverToggleOn);

            setServerRecordId(
              elevated.find((item) => item.permitID === additional_permit_id)
                ?.permitID || "0"
            );
          }
        } else {
          console.log("Response data is missing or invalid.");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleApproved = async (e) => {
    e.preventDefault();
    setStatus("");
    setShowApproved(true);
  };
  const handleAprrovedOk = async (e) => {
    console.log(e, "handleOk");
    const ApproveData = {
      permitName: "crane_permit",
      corePermitID: "5",
      additionalPermitID: "11",
      status: "Approve",
    };
    const response = await axios.post(ApproveRejectPermit, ApproveData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
      console.log("API throwing error");
    }
    setShowApproved(false);
    console.log(status);
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRemarks("");
    setShowReject(true);
  };

  const handleRejectOk = async (e) => {
    console.log(e, "handleRejectOk");

    const RejectData = {
      permitName: "cranePermit",
      corePermitID: "5",
      additionalPermitID: "7",
      status: "Reject",
      remarks: remarks,
    };
    const response = await axios.post(ApproveRejectPermit, RejectData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
    }
    setShowReject(false);
    console.log(status);
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    //Note
    const checkedNoteListyes = note.noteList.filter(
      (item) => item.cbYes === "true"
    );
    const checkedNoteListNa = note.noteList.filter(
      (item) => item.cbNA === "true"
    );

    const isNoteListCheckedyes =
      checkedNoteListyes.length >= 1
        ? "true"
        : checkedNoteListyes.length === 0 && checkedNoteListNa.length === 0
          ? ""
          : "false";

    const isNoteListCheckedNa =
      checkedNoteListNa.length >= 1
        ? "true"
        : checkedNoteListyes.length === 0 && checkedNoteListNa.length === 0
          ? ""
          : "false";

    //ImportantList
    const checkedImportantListyes = important.importantList.filter(
      (item) => item.cbYes === "true"
    );
    const checkedImportantListNa = important.importantList.filter(
      (item) => item.cbNA === "true"
    );

    const isImportantListCheckedyes =
      checkedImportantListyes.length >= 1
        ? "true"
        : checkedImportantListyes.length === 0 &&
          checkedImportantListNa.length === 0
          ? ""
          : "false";

    const isImportantListCheckedNa =
      checkedImportantListNa.length >= 1
        ? "true"
        : checkedImportantListyes.length === 0 &&
          checkedImportantListNa.length === 0
          ? ""
          : "false";

    var valuesToCheck = [];

    if (emergencyToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        emergency: {
          phoneNumber: emergency.phoneNumber,
          assemblyLocation: emergency.assemblyLocation,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (basicDetailsToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        basicDetails: {
          company: basicDetails.company,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (equipmentToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        eqipmentInformation: {
          size_or_capacity_in_tons:
            eqipmentInformation.size_or_capacity_in_tons,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (importantToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        important: {
          importantcbyes: isImportantListCheckedyes,
          importantcbNa: isImportantListCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    if (liftConditionsToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        liftConditions: {
          r: liftConditions.r,
          h: liftConditions.h,
          l: liftConditions.l,
          w: liftConditions.w,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (noteListToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        note: {
          noteListcbyes: isNoteListCheckedyes,
          noteListcbna: isNoteListCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (permitIssuerToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        permitIssuer: {
          permitissuer: permitIssuer.permitissuer,
          permitissuerdate: permitIssuer.date,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (permitRecieverToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        permitReceiver: {
          permitreceiver: permitReceiver.permitreceiver,
          permitreceiverdate: permitReceiver.date,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    console.log("valuesToCheck" + JSON.stringify(valuesToCheck));
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {


        case "emergency":
          if (emergencyToggleOn) {
            alert("Fill the Emergency Data");
            allCasesValid = false;
          }
          break;



        case "basicDetails":
          if (basicDetailsToggleOn) {
            alert("Please Fill The BasicDetails Data");
            allCasesValid = false;
          }
          break;


        case "eqipmentInformation":
          if (equipmentToggleOn) {
            alert("Please Fill The EqipmentInformation Data");
            allCasesValid = false;
          }
          break;



        case "important":
          if (importantToggleOn) {
            alert("Please Fill The Important Data");
            allCasesValid = false;
          }
          break;



        case "liftConditions":
          if (liftConditionsToggleOn) {
            alert("Please Fill The LiftConditions Data");
            allCasesValid = false;
          }
          break;



        case "note":
          if (noteListToggleOn) {
            alert("Please Fill The Note Data");
            allCasesValid = false;
          }
          break;



        case "permitIssuer":
          if (permitIssuerToggleOn) {
            alert("Please Fill The PermitIssuer Data");
            allCasesValid = false;
          }
          break;


        case "permitReceiver":
          if (permitRecieverToggleOn) {
            alert("Please Fill The PermitReceiver Data");
            allCasesValid = false;
          }
          break;
      }
    }

    if (allCasesValid) {
      // const dataFromStore = await retrieveData(USER_PROFILE);
      const mobileNumber = sessionStorage.getItem("COREPERMIT_MOBILE_NUMBER");
      const name = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      const id = sessionStorage.getItem("CorePermitM2_PERMITID");
      const userProfileData = {
        id: serverRecordId ? serverRecordId : "0",
        mobileNumber: mobileNumber,
        name: name,
        corePermitID: id,
        additionalPermitName: "crane_permit",
      };
      const finalJson = {
        request: {
          permitInitials,
          emergency,
          basicDetails,
          eqipmentInformation,
          important,
          liftConditions,
          note,
          permitIssuer,
          permitReceiver,
          emergencyToggleOn,
          basicDetailsToggleOn,
          equipmentToggleOn,
          importantToggleOn,
          liftConditionsToggleOn,
          noteListToggleOn,
          permitIssuerToggleOn,
          permitRecieverToggleOn,
        },
        ...userProfileData,

      };
      try {
        setIsLoading(true);
        console.log(finalJson, "finalJson***");
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log(response.status, "sdmnhba");
        console.log("response", response);
        if (response.status == 200 && response) {
          console.log("success");
          setShowApproved(true);
          setIsLoading(false);
          resetForm(); // Reset form fields
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}

        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          {finalSubmit ? (
            <>
              <h2>Form Submitted Successfully!!!</h2>
            </>
          ) : (
            <div id="content">
              {/* 
                        <Header /> */}
              <div
                className="row"
                style={{ backgroundColor: "blue", padding: "7px" }}
              >
                <div className="col-md-3 col-12">
                  {/* <span className="arrow" style={{ width: 50, height: 25, padding: 10,color:"white"}}>{'<-'}</span>    */}
                  <img
                    src={arrowIcon}
                    onClick={handleBackButtonClick}
                    alt="Back"
                    style={{
                      width: 40,
                      height: 30,
                      padding: 5,
                      verticalAlign: "middle",
                    }}
                  />
                  <span
                    style={{
                      fontSize: 20,
                      color: "white",
                      marginLeft: 10,
                      marginTop: 12,
                    }}
                  >
                    Crane Operations
                  </span>
                  {/* <h1 style={{ fontSize: 20, color: 'white'}}>Core Permit</h1> */}
                </div>
              </div>
              <div>
                <div className="container-fluid">
                  {/* <div className="card border-0">
                                    <div className="card-body pt-0 pl-1"> */}

                  <div className="d-flex justify-content-between"></div>
                  {/* <div className="mcrc_hrline"></div> */}

                  <div>

                    <form className="crane_permit-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        setPermitInitials={setPermitInitials}
                        editable={false}
                      />

                      <Accordion
                        title="EMERGENCY"
                        bgColor="#DF3A1C"
                        handleToggle={handleToggleItems}
                        isToggled={emergencyToggleOn}
                        content={
                          <Emergency
                            data={emergency}
                            emergencyToggleOn={emergencyToggleOn}
                            setEmergency={setEmergency}
                            onDataChange={(data) => {
                              setEmergency(data);
                            }}
                          />
                        }
                        bordercolor="#DF3A1C"
                        showToggle={true}

                      />
                      <Accordion
                        title="BASIC DETAILS"
                        bgColor="#2C53A5"
                        handleToggle={handleBasicDetailsItems}
                        isToggled={basicDetailsToggleOn}
                        content={
                          <BasicDetails
                            data={basicDetails}
                            basicDetailsToggleOn={basicDetailsToggleOn}
                            setBasicDetails={setBasicDetails}
                            onDataChange={(data) => {
                              setBasicDetails(data);
                            }}
                          />
                        }
                        bordercolor="#2C53A5"
                        showToggle={true}
                      />
                      <Accordion
                        title="EQUIPMENT INFORMATION"
                        bgColor="#F58355"
                        handleToggle={handleEquipmentItems}
                        isToggled={equipmentToggleOn}
                        content={
                          <Equipment
                            data={eqipmentInformation}
                            equipmentToggleOn={equipmentToggleOn}
                            setEquipmentInf={setEquipmentInf}
                            onDataChange={(data) => {
                              setEquipmentInf(data);
                            }}
                          />
                        }
                        bordercolor="#F58355"
                        showToggle={true}

                      />
                      <Accordion
                        title="IMPORTANT"
                        bgColor="#2C53A5"
                        handleToggle={handleImportantToggleOnItems}
                        isToggled={importantToggleOn}
                        content={
                          <Important
                            data={important}
                            importantToggleOn={importantToggleOn}
                            setImportant={setImportant}
                            onDataChange={(data) => {
                              setImportant(data);
                            }}
                          />
                        }
                        bordercolor="#2C53A5"
                        showToggle={true}
                      />
                      <Accordion
                        title="LIFT CONDITIONS"
                        bgColor="#5F92CD"
                        handleToggle={handleLiftConditionsToggleOnItems}
                        isToggled={liftConditionsToggleOn}
                        content={
                          <LiftConditions
                            liftConditionsToggleOn={liftConditionsToggleOn}
                            data={liftConditions}
                            setLiftConditions={setLiftConditions}
                            onDataChange={(data) => {
                              setLiftConditions(data);
                            }}
                          />
                        }
                        bordercolor="#5F92CD"
                        showToggle={true}
                      />
                      <Accordion
                        title={
                          <span>
                            NOTE <span className="required"> *</span> (Select
                            Any Checkbox )
                          </span>
                        }
                        bgColor="#2B2B2B"
                        handleToggle={handleIsNoteListToggleOnItems}
                        isToggled={noteListToggleOn}
                        content={
                          <Note
                            data={note}
                            noteListToggleOn={noteListToggleOn}
                            setNote={setNote}
                            onDataChange={(data) => {
                              setNote(data);
                            }}
                          />
                        }
                        bordercolor="#2B2B2B"
                        showToggle={true}

                      />

                      <Accordion
                        title="Permit Issuer"
                        bgColor="#CB3A27"
                        handleToggle={handlePermitIssuerToggleOnItems}
                        isToggled={permitIssuerToggleOn}
                        content={
                          <PermitIssuer
                            data={permitIssuer}
                            permitIssuerToggleOn={permitIssuerToggleOn}
                            setPermitIssuer={setPermitIssuer}
                            onDataChange={(data) => {
                              setPermitIssuer(data);
                            }}
                          />
                        }
                        bordercolor="#CB3A27"
                        showToggle={true}
                      />
                      <Accordion
                        title="Permit Receiver"
                        bgColor="#CB3A27"
                        handleToggle={handlePermitReceiverToggleOnItems}
                        isToggled={permitRecieverToggleOn}
                        content={
                          <PermitReceiver
                            data={permitReceiver}
                            permitRecieverToggleOn={permitRecieverToggleOn}
                            setPermitReceiver={setPermitReceiver}
                            onDataChange={(data) => {
                              setPermitReceiver(data);
                            }}
                          />
                        }
                        bordercolor="#CB3A27"
                        showToggle={true}
                      />

                      {/* <div style={{ marginTop: "20px" }}> */}
                      {isEdit.toString() == "true" && (
                        <button
                          type="button"
                          className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-3  ${"custom-rounded"}`}
                          style={{
                            height: "50px",
                            backgroundColor: "#007AFF",
                            color: "white",
                            border: "none",
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                      {/* </div> */}
                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Approved}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>Crane Permit sent for Approval</h5>
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      handleBackButtonClick();
                                      setShowModal(false);
                                    }}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "15vh",
                              }}
                            >
                              <h3> Are you sure want to Reject?</h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Reason:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={handleRejectOk}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </form>
                    {/* </div>
                                            </div> */}
                    {/* </div>
                                    </div> */}
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          )}
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */}
        {/* <Logout /> */}
      </div>
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};
export default CranePermitMain;
