import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button, Modal } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import {   FarmOperation_getMasters, SubFunctionsMasterAdd, SubFunctionsMasterGet } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import ReactSelect from 'react-select';

export const SubFunctionsMasters = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [getMainFunction, setGetMainFunction] = useState([])
    const [name, setName] = useState()
    const [mainFunctionName, setMainFunctionName] = useState()
    const [mainFunctionId, setMainFunctionId] = useState()
    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg , setResponseMsg]= useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [mainFunctionError, setMainFunctionError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)

    const getData = async () => {
        const URL = SubFunctionsMasterGet;
        try {
            setLoader(true)
            const response = await axios.get(URL) 
            setData(response.data.response)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    }

    const MainFunctionDropdown = async () =>{
        const apiUrl = FarmOperation_getMasters;
        const headers = await GetApiHeaders();
        try {
            const response = await axios.get(apiUrl, {headers})
            setGetMainFunction(response.data.response.mainFunctionMaster)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
        MainFunctionDropdown()
    }, [])

    const headersData = [
        {
            field: "mainFunction.name",
            header: "Function ",
        },
        {
            field: "subFunctionName",
            header: "Sub Function",
        }, 
        {
            field: "createdBy.firstName",
            header: "Created By",
        }, 
        {
            field: "status",
            header: "Status",
        },
    ];

    const onEditClicked = (data) => { 
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.subFunctionName)
        setMainFunctionName(data.mainFunction.name)
        setMainFunctionId(data.mainFunction.id)
        setStatus(data.status)
        setError('')
        setMainFunctionError('')
        setStatusError('')
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('')
        setMainFunctionName('')
        setStatus('')
        setMainFunctionId('')
        setError('')
        setMainFunctionError('')
        setStatusError('')
    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) =>{
        const Input = e.target.value
        const alphabeticInput = Input.replace(/[^a-zA-Z ]/g, "");
        setName(alphabeticInput)
        if (Input) {
            setError('')
        }
    }
 
    const handleMainFunction = (selectedOption) => {
        if (selectedOption) {
            setMainFunctionName(selectedOption.label); 
            setMainFunctionId(selectedOption.value);  
            setMainFunctionError('');  
        } else {
            setMainFunctionName('');  
            setMainFunctionId('');
        }
    };
    

    const MainFunction = getMainFunction
    ? getMainFunction
          .filter((item) => item)
          .map((item) =>({ value: item.code, label: item.name }) )
    : [];
    const handleStatus = (e) =>{
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }

    const handleSubmitData = async () =>{ 
        const apiUrl = SubFunctionsMasterAdd;
        const reqData = { 
            id: saveEdit === "Submit" ? 0 : id,
            subFunctionName: name,
            mainFunctionId : parseInt(mainFunctionId),
            mainFunctionName : mainFunctionName, 
            status  : status
         }
         const headers = await GetApiHeaders() 
         try {
            if (!status) {
                setStatusError("Select Status")
            }
            if (!mainFunctionName) {
                setMainFunctionError('Select  Function')
            }
            if (!name) {
                setError('Enter Sub Function')   
            }
            if (name !=='' && mainFunctionName !=='' && status !=='') {
                setLoader(true)
                const response = await axios.post(apiUrl,reqData, {headers} )
             if (response.data.statusCode == 200 || response.data.status == 500) {
                 setShowModal(false)
                 setMsgPopup(true)
                 setResponseMsg(response.data.message);
                 setLoader(false)
             }
            }
             
         } catch (error) {
            console.log(error )
         }
    }
    const handleOk = () =>{
        setMsgPopup(false)
        getData()
    }

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">  Master</h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text"> SubFunction Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add SubFunction Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader = {loader}
                                            FileName={'SubFunction'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'SubFunction Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1">
                                                            Function Name <span style={{ color: "red" }}>*</span> 
                                                            </label>
                                                            <ReactSelect
                                                                id="dropdown"
                                                                className="w-100"
                                                                options={MainFunction}
                                                                value={MainFunction.find((option) => option.value === mainFunctionId) || null} // Correctly bind the selected value
                                                                onChange={handleMainFunction}
                                                                placeholder="Select Function"
                                                                isClearable
                                                                isSearchable
                                                            />

                                                             <span style={{ color: "red" }}> {mainFunctionError}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12 '>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                SubFunction Name <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter SubFunction Name'value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
 
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                            >Status <span style={{ color: "red" }}>*</span> </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control p-2" value={status} onChange={handleStatus}>
                                                                <option value="" disabled>Select Status</option> 
                                                                <option value={true}>Active</option>
                                                                <option value={false}>In-Active</option>
                                                            </select>
                                                        </div>
                                                        <span style={{ color: "red" }}> {statusError}</span>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal 
                                         showModal={msgPopup}
                                        msgPopup ={msgPopup}
                                        handleOk= {handleOk}
                                        handleCancel_new={handleCancel_new}
                                        tittle={'SubFunction Master'}
                                        childrens={<div className="p-4 d-flex justify-content-center" style={{fontSize:"18px", color:"green", fontWeight:500}}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}